/* eslint-disable no-unused-vars */
import React from "react";
import { toast } from "react-toastify";
// import deleteIcon from "../../../assets/img/delete_black@2x.png";
// import GModal from "../../components/modal/GModal";
// import CheckoutPage from "./components/CheckoutPage";
import {
  getSubscriptionPlans,
  getUserSubscriptions,
  subscribePlan,
  cancelSubscriptionPlan,
  getPaymentToggle
} from "../../../redux/actions/subscriptions";
import "./subscriptions.scss";
import { connect } from "react-redux";
import moment from "moment";
import { getSubscribedCategories } from "../../../redux/actions/auth";
import { isEmpty } from "../../../utils/validations";
import Package from "./Package";
import { confirmAlert } from "react-confirm-alert";
import ConfirmPopup from "../../components/confirmPopup/ConfirmPopup";
import FAQ from "../faq/faq";
import HeraldToastify, {
  ICONS
} from "../../components/HeraldToastify/HeraldToastify";

class Subscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // activePlan: null
      // selectedPlan: null,
      // isPaymentOpen: false
    };
  }

  componentDidMount() {
    this.getSubscriptionPlans();
    // this.getSubscriptionHistory();
    const queryparams = this.props.location.search;
    if (queryparams) {
      const query = new URLSearchParams(queryparams);
      const id = query.get("hostedpage_id");
      this.props.history.push("/subscriptions");
      const data = { hostedpage_id: id };
      this.subscribePlan(data);
    }
  }

  subscribePlan = data => {
    this.props.subscribePlan({
      data,
      cb: () => {
        this.getSubscriptionPlans();
        this.props.getSubscribedCategories();
      }
    });
  };

  cancelSubscribedPlan = plan => {
    const onYes = () => {
      const data = {
        subscription_id: plan.id,
        cancellationReason: {
          reason: "User requested to cancel"
        }
      };
      this.props.cancelSubscriptionPlan({
        user_id: this.props.user.id,
        data,
        cb: this.getSubscriptionPlans
      });
    };
    const options = {
      title: "Are you sure?",
      message: "This action will cancel your subscription!",
      buttons: [
        {
          label: "Yes, Cancel it!",
          onClick: onYes
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmPopup onClose={onClose} {...options} />;
      }
    });
  };

  getSubscriptionPlans = () => {
    this.props.getSubscriptionPlans({ cb: this.setActivePlan });
  };

  getSubscriptionHistory = () => {
    this.props.getUserSubscriptions();
  };

  setActivePlan = plans => {
    // plans.forEach(plan => {
    //   if (plan.isactive) {
    //     this.setState({
    //       activePlan: plan
    //     });
    //   }
    // });
  };

  handlePayVia = (plan, via) => {
    if (via === "online" || via === "stop renewal") {
      this.checkPaymentRedirection(plan, via);
    } else if (via === "offline") {
      /**Do offline direct subscription */
      const data = { subscription_id: plan.id };
      if (plan.action === "renew") {
        toast.info(
          <HeraldToastify
            icon={ICONS.info}
            title="kindly contact administrator for renewal"
          />
        );
      } else {
        this.subscribePlan(data);
      }
    }
  };

  checkPaymentRedirection = (plan, via) => {
    const data = {
      subscription_id: plan.id,
      mode: via === "stop renewal" ? "offline" : "online"
    };
    this.props.getPaymentToggle(data, res => {
      if (res.status === "redirect") {
        plan.payment_url = res.payment_url;
        this.selectSubscription(plan);
      } else {
        toast.info(<HeraldToastify icon={ICONS.info} title={res.message} />);
        this.getSubscriptionPlans();
      }
    });
  };

  selectSubscription = plan => {
    // Initially used own checkout form, with stripe integration. later we removed it
    /*
    this.setState({
      selectedPlan: plan,
      isPaymentOpen: true
    });
    */
    // const { activePlan } = this.state;
    // if (!activePlan || (activePlan && activePlan.plan_name === "Free Trial")) {
    const {
      firstName: first_name,
      lastName: last_name,
      email,
      phone,
      address_line_1: billing_street,
      city: billing_city,
      state: billing_state,
      zip: billing_zip
    } = this.props.user;
    const data = {
      first_name,
      last_name,
      email,
      phone,
      billing_street,
      billing_city,
      billing_state,
      billing_zip
    };
    Object.keys(data).forEach(key => isEmpty(data[key]) && delete data[key]);
    const qs = new URLSearchParams(data).toString();
    const checkoutURL = `${plan.payment_url}?${qs}`;
    window.open(checkoutURL, "_self");
    // }
  };

  closeCheckout = () => {
    this.setState({
      selectedPlan: null,
      isPaymentOpen: false
    });
  };

  render() {
    const { plans, subscriptionHistory } = this.props;
    return (
      <div className="subscription_page px-xl-6 px-lg-5 px-md-5 px-sm-5 px-4">
        <h4 className="mb-4">Subscription Details</h4>
        {/* <button className="btn btn-orange">Add payment method</button>
        <div className="py-4 border-bottom">
          <table className="width c-table">
            <thead>
              <tr className="details_label border-0">
                <td>Card Number</td>
                <td>Expiry Date</td>
                <td>Card Code (CVC)</td>
                <td />
              </tr>
            </thead>
            <tbody>
              <tr className="details_value">
                <td>4123 6734 78** ****</td>
                <td>07/24</td>
                <td>***</td>
                <td className="pr-3">
                  <img
                    alt="icon"
                    src={deleteIcon}
                    className="action_icon cursor-pointer"
                  />
                </td>
              </tr>
              <tr className="spacer" />
            </tbody>
          </table>
        </div> */}
        <div className="pt-4 pb-3 border-bottom">
          <h5 className="mb-3">Packages</h5>
          <div className="plan--group">
            {plans.map(plan => (
              <Package
                key={plan.id}
                plan={plan}
                handlePayVia={this.handlePayVia}
                cancelSubscribedPlan={this.cancelSubscribedPlan}
              />
            ))}
          </div>
        </div>
        <div className="d-lg-block d-none d-sm-none mt-4">
          <FAQ />
        </div>
        {/* <div className="py-4 border-bottom">
          <h5 className="mb-3">Subscription History</h5>
          <table className="width c-table">
            <thead>
              <tr className="details_label">
                <td>Product</td>
                <td>Start Date</td>
                <td>End Date</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {subscriptionHistory.map(item => (
                <tr className="details_value" key={item.id}>
                  <td>{item.subscription.plan_name}</td>
                  <td>{moment(item.subscribed_at).format("MMM D, YYYY")}</td>
                  <td>{moment(item.renewal_on).format("MMM D, YYYY")}</td>
                  <td className={!item.isExpired ? "font-weight-bold" : ""}>
                    {item.isExpired ? "Expired" : "Active"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
        {/* <GModal
          isOpen={isPaymentOpen}
          onClose={this.closeCheckout}
          isClose={true}
          variant="md"
          backdrop={false}
        >
          {selectedPlan && (
            <CheckoutPage plan={selectedPlan} onClose={this.closeCheckout} />
          )}
        </GModal> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  plans: state.subscription.plans,
  subscriptionHistory: state.subscription.history
});

const mapDispatchToProps = {
  getSubscriptionPlans: getSubscriptionPlans, // get plans for user
  getUserSubscriptions: getUserSubscriptions, // get user history of subscriptions
  getPaymentToggle: getPaymentToggle,
  subscribePlan: subscribePlan,
  cancelSubscriptionPlan: cancelSubscriptionPlan,
  getSubscribedCategories: getSubscribedCategories
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscriptions);

/* <div>
    {!plan.isactive ? (
      <button
        className="btn btn-orange"
        disabled={
          activePlan && activePlan.plan_name !== "Free Trial"
        }
        onClick={e => {
          this.selectSubscription(plan);
        }}
      >
        Order Now
      </button>
    ) : (
      <button className="btn btn-active cursor-default">
        Active
      </button>
    )}
  </div> */
