import React from "react";
import "./animatedSidebar.scss";

const AnimatedSidebar = ({ isOpen, children, toggleSidebar }) => {
  return (
    <div className={`animated-sidebar ${isOpen ? "isOpen" : ""}`}>
      <div className="d-flex flex-row-reverse close-sidebar">
        <i className="fa fa-times" onClick={toggleSidebar} aria-hidden="true" />
      </div>
      {children}
    </div>
  );
};

export default AnimatedSidebar;
