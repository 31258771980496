import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../inputs/Input";
import { Label } from "../inputs/Label";
import { parseHexColor } from "../common";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const useHex = value => {
  const [color, setColor] = useState("");

  useEffect(() => {
    const values = parseHexColor(value);
    if (values) {
      setColor(values.color);
    }
  }, [value]);

  return { color };
};

export default ({ value, onChange }) => {
  const { color } = useHex(value);

  const updated = e => {
    onChange(e.target.value);
  };

  const updatedInput = e => {
    onChange(e);
  };

  return (
    <Wrapper>
      <Label>Color</Label>
      <Input value={value} onChange={updatedInput} />
      <input value={color} onChange={updated} type={"color"} />
    </Wrapper>
  );
};
