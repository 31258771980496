const appConstants = {
  fontFamilyList: [
    {
      label: "Abel",
      value: "Abel",
      className: "Abel"
    },
    {
      label: "Abril Fatface",
      value: "Abril Fatface",
      className: "Abril_Fatface"
    },
    {
      label: "Anton",
      value: "Anton",
      className: "Anton"
    },
    {
      label: "Archivo Black",
      value: "Archivo Black",
      className: "Archivo_Black"
    },
    {
      label: "Arial",
      value: "Arial",
      className: "Arial"
    },
    {
      label: "Bangers",
      value: "Bangers",
      className: "Bangers"
    },
    {
      label: "Bebas Neue",
      value: "Bebas Neue",
      className: "Bebas_Neue"
    },
    {
      label: "Black Han Sans",
      value: "Black Han Sans",
      className: "Black_Han_Sans"
    },
    {
      label: "Bree Serif",
      value: "Bree Serif",
      className: "Bree_Serif"
    },
    {
      label: "Caveat",
      value: "Caveat",
      className: "Caveat"
    },
    {
      label: "Cinzel",
      value: "Cinzel",
      className: "Cinzel"
    },
    {
      label: "Cinzel Decorative",
      value: "Cinzel Decorative",
      className: "Cinzel_Decorative"
    },
    {
      label: "Comfortaa",
      value: "Comfortaa",
      className: "Comfortaa"
    },
    {
      label: "Contrail One",
      value: "Contrail One",
      className: "Contrail_One"
    },
    {
      label: "Cormorant SC",
      value: "Cormorant SC",
      className: "Cormorant_SC"
    },
    {
      label: "Dancing Script",
      value: "Dancing Script",
      className: "Dancing_Script"
    },
    {
      label: "EB Garamond",
      value: "EB Garamond",
      className: "EB_Garamond"
    },
    {
      label: "Epilogue",
      value: "Epilogue",
      className: "Epilogue"
    },
    {
      label: "Exo",
      value: "Exo",
      className: "Exo"
    },
    {
      label: "Fjalla One",
      value: "Fjalla One",
      className: "Fjalla_One"
    },
    {
      label: "Graduate",
      value: "Graduate",
      className: "Graduate"
    },
    {
      label: "Hanuman",
      value: "Hanuman",
      className: "Hanuman"
    },
    {
      label: "Homemade apple",
      value: "Homemade apple",
      className: "Homemade_apple"
    },
    {
      label: "Josefin Slab",
      value: "Josefin Slab",
      className: "Josefin_Slab"
    },
    {
      label: "Julius Sans One",
      value: "Julius Sans One",
      className: "Julius_Sans_One"
    },
    {
      label: "kalam",
      value: "kalam",
      className: "kalam"
    },
    {
      label: "Kaushan Script",
      value: "Kaushan Script",
      className: "Kaushan_Script"
    },
    {
      label: "Khand",
      value: "Khand",
      className: "Khand"
    },
    {
      label: "Lobster",
      value: "Lobster",
      className: "Lobster"
    },
    {
      label: "Lora",
      value: "Lora",
      className: "Lora"
    },
    {
      label: "Loved by the King",
      value: "Loved by the King",
      className: "Loved_by_the_King"
    },
    {
      label: "Merienda",
      value: "Merienda",
      className: "Merienda"
    },
    {
      label: "Merriweather",
      value: "Merriweather",
      className: "Merriweather"
    },
    {
      label: "Monoton",
      value: "Monoton",
      className: "Monoton"
    },
    {
      label: "Montserrat",
      value: "Montserrat",
      className: "Montserrat"
    },
    {
      label: "Nothing you could do",
      value: "Nothing you could do",
      className: "Nothing_you_could_do"
    },
    {
      label: "Nunito",
      value: "Nunito",
      className: "Nunito"
    },
    {
      label: "Orbitron",
      value: "Orbitron",
      className: "Orbitron"
    },
    {
      label: "Oswald",
      value: "Oswald",
      className: "Oswald"
    },
    {
      label: "Oxanium",
      value: "Oxanium",
      className: "Oxanium"
    },
    {
      label: "Pathway Gothic One",
      value: "Pathway Gothic One",
      className: "Pathway_Gothic_One"
    },
    {
      label: "Paytone One",
      value: "Paytone One",
      className: "Paytone_One"
    },
    {
      label: "Permanent Marker",
      value: "Permanent Marker",
      className: "Permanent_Marker"
    },
    {
      label: "Poiret One",
      value: "Poiret One",
      className: "Poiret_One"
    },
    {
      label: "Poppins",
      value: "Pacifico",
      className: "Pacifico"
    },
    {
      label: "Poppins",
      value: "Poppins",
      className: "Poppins"
    },
    {
      label: "rennie beanie",
      value: "rennie beanie",
      className: "rennie_beanie"
    },
    {
      label: "Righteous",
      value: "Righteous",
      className: "Righteous"
    },
    {
      label: "Roboto",
      value: "Roboto",
      className: "Roboto"
    },
    {
      label: "Roboto Mono",
      value: "Roboto Mono",
      className: "Roboto_Mono"
    },
    {
      label: "Ropa Sans",
      value: "Ropa Sans",
      className: "Ropa_Sans"
    },
    {
      label: "Russo One",
      value: "Russo One",
      className: "Russo_One"
    },
    {
      label: "Shadows into light",
      value: "Shadows into light",
      className: "Shadows_into_light"
    },
    {
      label: "Spartan",
      value: "Spartan",
      className: "Spartan"
    },
    {
      label: "Spectral",
      value: "Spectral",
      className: "Spectral"
    },
    {
      label: "Staatliches",
      value: "Staatliches",
      className: "Staatliches"
    },
    {
      label: "Stoke",
      value: "Stoke",
      className: "Stoke"
    },
    {
      label: "Teko",
      value: "Teko",
      className: "Teko"
    },
    {
      label: "Thasadith",
      value: "Thasadith",
      className: "Thasadith"
    },
    {
      label: "Titillium Web",
      value: "Titillium Web",
      className: "Titillium_Web"
    },
    {
      label: "Tomorrow",
      value: "Tomorrow",
      className: "Tomorrow"
    },
    {
      label: "Trade Winds",
      value: "Trade Winds",
      className: "Trade_Winds"
    },
    {
      label: "Wire One",
      value: "Wire One",
      className: "Wire_One"
    }
  ],
  fontStyleList: [
    { label: "Regular", value: "" },
    { label: "Bold", value: "bold", className: "font-weight-bold" },
    { label: "Italic", value: "italic", className: "font-italic" },
    {
      label: "Bold &Italic",
      value: "bold italic",
      className: "font-italic font-weight-bold"
    }
  ],
  fontSizeList: [
    "10",
    "12",
    "14",
    "16",
    "20",
    "24",
    "36",
    "48",
    "52",
    "66",
    "72"
  ],
  canvasPresets: {
    presentation: {
      name: "Presentation",
      text: "1920 x 1080",
      resolutions: [1920, 1080],
      orientation: "landscape"
    },
    stories: {
      name: "Stories",
      text: "1080 x 1960",
      resolutions: [1080, 1960],
      orientation: "portrait"
    },
    facebook: {
      name: "Facebook",
      text: "1920 x 1080",
      resolutions: [1920, 1080],
      orientation: "landscape"
    },
    instagram: {
      name: "Instagram",
      text: "1080 x 1080",
      resolutions: [1080, 1080],
      orientation: "square"
    },
    template: {
      name: "Basic Template",
      text: "1080 x 566",
      resolutions: [1080, 566],
      orientation: "landscape"
    }
  },
  colorPresets: {
    winter: ["#68acff", "#bc1a1a", "#ffe4c4", "#464d56"],
    spring: ["#4fffe8", "#ffe4c4", "#ff9010", "#ff36a5"],
    halloween: ["#ff6e4f", "#ecff3c", "#ffe4c4", "#5236ff"],
    independence: ["#ffd94f", "#60e048", "#ffe4c4", "#368aff"],
    summer: ["#ff6e4f", "#ecff3c", "#ffe4c4", "#5236ff"],
    robust: ["#ffc903", "#48d0e0", " #ff5555", "#ffe4c4"],
    autumn: ["#ffc903", "#48d0e0", "#ff5555", "#ffe4c4"],
    sport: ["#4fffe8", "#ffe4c4", "#ff9010", "#ff36a5"],
    fall: ["#ff6e4f", "#ecff3c", "#ffe4c4", "#5236ff"],
    christmas: ["#0378ff", "#48e068", "#ff3a3a", "#ffe4c4"]
  },
  stateList: [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
  ],
  schoolLevels: ["Level 1", "Level 2", "Level 3"],
  classifications: ["1A", "2A", "3A", "4A"],
  landscape: [978, 550],
  portrait: [303, 550],
  square: [550, 550]
};

export default appConstants;
