/* eslint-disable prettier/prettier */
import Konva from "konva";
import {
  getCenter,
  getCenterAxis,
  getHeight,
  getWidth
} from "./shapes";

/* eslint-disable no-unused-vars */
const SAMPLE_ARRAY1 = [
  {
    type: "FLY IN FROM RIGHT",
    delayType: "withPrevious",
    duration: 2000,
    shapedId: "SHAPE 1",
    delay: 0
  },
  {
    type: "FLY OUT TO LEFT",
    delayType: "afterPrevious",
    duration: 1500,
    shapedId: "SHAPE 1",
    delay: 5000
  },
  {
    type: "FLY IN FROM LEFT",
    delayType: "afterPrevious",
    duration: 1500,
    shapedId: "SHAPE 1",
    delay: 5000
  }
];

const SAMPLE_ARRAY2 = [
  {
    type: "FADE IN",
    delayType: "withPrevious",
    duration: 2000,
    shapedId: "SHAPE 1",
    delay: 0
  },
  {
    type: "FLY IN FROM BOTTOM",
    delayType: "withPrevious",
    duration: 1500,
    shapedId: "SHAPE 1",
    delay: 5000
  },
  {
    type: "FADE IN",
    delayType: "afterPrevious",
    duration: 1500,
    shapedId: "SHAPE 1",
    delay: 5000
  }
];

const CONVERTED_FROM = { opacity: 0, x: 500 };
const CONVERTED_TO = [
  {
    config: {
      duration: 1800
    },
    delay: 0,
    from: {
      opacity: 0
    },
    opacity: 1
  },
  {
    config: {
      duration: 1800
    },
    delay: 0,
    from: {
      opacity: 1
    },
    opacity: 0
  },
  {
    config: {
      duration: 1800
    },
    delay: 0,
    from: {
      x: 0
    },
    x: 500
  }
];

export default class AnimationUtils {
  constructor(animations, shape, stageWidth, stageHeight) {
    this.animations = animations;
    this.shape = shape;
    this.initialPosition = {
      x: shape.x,
      y: shape.y,
      opacity: 1,
      scaleX: shape.scaleX || 1,
      scaleY: shape.scaleY || 1,
      rotation: shape.rotation || 0,
      offset: {
        x: 0,
        y: 0
      }
    };
    this.stageWidth = stageWidth;
    this.stageHeight = stageHeight;
  }

  get offsetCenter() {
    const type = (this.shape.Type || this.shape.type).toLowerCase();
    let offset, center;
    const { x, y, rotation } = this.shape;
    if(type === 'text') {
      const text = new Konva.Text(this.shape);
      let width = text.getTextWidth();
      let height = text.getTextHeight();
      offset = {
        x: width / 2,
        y: height / 2
      };
      center = getCenter({ x, y, rotation, width, height, scaleX: 1, scaleY: 1 });
    } else if(type === 'line') {
      const line = new Konva.Line(this.shape);
      let width = line.width();
      let height = line.height();
      offset = {
        x: width / 2,
        y: height / 2
      };
      center = getCenter({ x, y, rotation, width, height, scaleX: 1, scaleY: 1 });
    }  else {
      offset = getCenterAxis(this.shape);
      center = getCenter(this.shape);
    }
    return {
      offset,
      center
    }
  }

  get getShapeWH() {
    const type = (this.shape.Type || this.shape.type).toLowerCase();
    let newHeight, newWidth;
    if(type === 'text') {
      const text = new Konva.Text(this.shape);
      newWidth = text.getTextWidth();
      newHeight = text.getTextHeight();
    } else if(type === 'line') {
      const line = new Konva.Line(this.shape);
      console.log(line.getClientRect());
      let newShapePos = line.getClientRect();
      newWidth = line.width();
      newHeight = line.height();
    } else {
      newHeight = getHeight(this.shape);
      newWidth = getWidth(this.shape);
    }
    return {
      newWidth,
      newHeight
    }
  }

  get from() {
    let fromData = { ...this.initialPosition };
    const { newWidth, newHeight } = this.getShapeWH;
    const currentObj = this.animations[0];
    switch (currentObj.type) {
      case "FADE IN": // opacity = 0 -> 1
        fromData.opacity = 0;
        break;
      case "FADE OUT": // opacity = 1 -> 0
        fromData.opacity = 1;
        break;
      case "FLY IN FROM LEFT": // x = 0 -> shape.x
        fromData.x = 0 - (newWidth + 60);
        break;
      case "FLY IN FROM RIGHT": // x = stagewidth -> shape.x
        fromData.x = this.stageWidth;
        break;
      case "FLY IN FROM TOP": // y = 0 -> shape.y
        fromData.y = 0 - (newHeight + 60);
        break;
      case "FLY IN FROM BOTTOM": // y = stageheight -> shape.y
        fromData.y = this.stageHeight;
        break;
      case "FLY OUT TO LEFT": // x = shape.x -> 0
        fromData.x = this.shape.x;
        break;
      case "FLY OUT TO RIGHT": // x = shape.x  ->  stagewidth
        fromData.x = this.shape.x;
        break;
      case "FLY OUT TO TOP": // y = shape.y -> 0
        fromData.y = this.shape.y;
        break;
      case "FLY OUT TO BOTTOM": // y = stage.y -> stageheight
        fromData.y = this.shape.y;
        break;
      case "ZOOM IN": // scale =  0 -> scaleXY
        fromData.scaleX = 0;
        fromData.scaleY = 0;
        break;
      case "ZOOM OUT": // scale =  scaleXY -> 0
        fromData.scaleX = this.shape.scaleX || 1;
        fromData.scaleY = this.shape.scaleY || 1;
        break;
      case "SPIN": // y = stage.y -> stageheight
        fromData.rotation = (this.shape.rotation || 0) - 360;
        break;
      default:
        return null;
    }
    /**
     * Set origin to center for Rectangle and Image
     * set offset to w/2 and h /2
     * Add those offset to current x and y respectively
     */
    const type = (this.shape.Type || this.shape.type).toLowerCase();
    if (
      ["SPIN", "ZOOM IN", "ZOOM OUT"].includes(currentObj.type) &&
      ["rect", "image", "text"].includes(type)
    ) {
      const data = this.offsetCenter;
      fromData.offset = data.offset;
      fromData.x = data.center.x;
      fromData.y = data.center.y;
    }

    return fromData;
  }

  get to() {
    let toData = [];
    const { newWidth, newHeight } = this.getShapeWH;
    for (let i = 0; i < this.animations.length; i++) {
      const currentObj = this.animations[i];
      const previousObj = this.animations[i - 1];
      let index;
      /**
       * If delay current and prev object is same, then animation apply at same time, so we add prev index
       * else we need to fresh animation, also delay calculate on below
       *   currentObj.delay - (previousObj.duration + previousObj.delay)
       */
      if (previousObj && currentObj.delay === previousObj.delay) {
        index = toData.length - 1;
      } else {
        const delay = previousObj
          ? currentObj.delay - (previousObj.duration + previousObj.delay)
          : currentObj.delay;
        toData.push({
          config: {
            duration: currentObj.duration
          },
          delay
        });
        index = toData.length - 1;
      }
      switch (currentObj.type) {
        case "FADE IN": // opacity = 0 -> 1
          toData[index].from = { ...this.initialPosition, opacity: 0 };
          toData[index].opacity = 1;
          break;
        case "FADE OUT": // opacity = 1 -> 0
          toData[index].from = { ...this.initialPosition, opacity: 1 };
          toData[index].opacity = 0;
          break;
        case "FLY IN FROM LEFT": // x = 0 -> shape.x
            toData[index].from = {
            ...this.initialPosition,
            x: 0 - (newWidth + 60)
          };
          toData[index].x = this.shape.x;
          break;
        case "FLY IN FROM RIGHT": // x = stagewidth -> shape.x
          toData[index].from = {
            ...this.initialPosition,
            x: this.stageWidth
          };
          toData[index].x = this.shape.x;
          break;
        case "FLY IN FROM TOP": // y = 0 -> shape.y
          toData[index].from = {
            ...this.initialPosition,
            y: 0 - (newHeight + 60)
          };
          toData[index].y = this.shape.y;
          break;
        case "FLY IN FROM BOTTOM": // y = stageheight -> shape.y
          toData[index].from = { ...this.initialPosition, y: this.stageHeight };
          toData[index].y = this.shape.y;
          break;
        case "FLY OUT TO LEFT": // x = shape.x -> 0
          toData[index].from = { ...this.initialPosition, x: this.shape.x };
          toData[index].x = 0 - (newWidth + 60);
          break;
        case "FLY OUT TO RIGHT": // x = shape.x  ->  stagewidth
          toData[index].from = { ...this.initialPosition, x: this.shape.x };
          toData[index].x = this.stageWidth;
          break;
        case "FLY OUT TO TOP": // y = shape.y -> 0
          toData[index].from = { ...this.initialPosition, y: this.shape.y };
          toData[index].y = 0 - (newHeight + 60);
          break;
        case "FLY OUT TO BOTTOM": // y = stage.y -> stageheight
          toData[index].from = { ...this.initialPosition, y: this.shape.y };
          toData[index].y = this.stageHeight;
          break;
        case "ZOOM IN": // scale =  0 -> scaleXY
          toData[index].from = {
            ...this.initialPosition,
            scaleX: 0,
            scaleY: 0
          };
          toData[index].scaleX = this.shape.scaleX || 1;
          toData[index].scaleY = this.shape.scaleY || 1;
          break;
        case "ZOOM OUT": // scale =  scaleXY -> 0
          toData[index].from = {
            ...this.initialPosition,
            scaleX: this.shape.scaleX,
            scaleY: this.shape.scaleY
          };
          toData[index].scaleX = 0;
          toData[index].scaleY = 0;
          break;
        case "SPIN": // y = stage.y -> stageheight
          toData[index].from = {
            ...this.initialPosition,
            rotation: (this.shape.rotation || 0) - 360
          };
          toData[index].rotation = this.shape.rotation || 0;
          break;
        default:
          return null;
      }
      /**
       * Set origin to center for Rectangle and Image
       * set offset to w/2 and h /2
       * Add those offset to current x and y respectively
       */
      const type = (this.shape.Type || this.shape.type).toLowerCase();
      if (
        ["SPIN", "ZOOM IN", "ZOOM OUT"].includes(currentObj.type) &&
        ["rect", "image", "text"].includes(type)
      ) {
        const data = this.offsetCenter;
        toData[index].from.offset = data.offset;
        toData[index].from.x = data.center.x;
        toData[index].from.y = data.center.y;
      }
    }
    return toData;
  }
}