/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import "./loader.scss";

class AppLoader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { loading } = this.props;
    return (
      <React.Fragment>
        {loading && (
          <div className="loader_container">
            <Loader type="Bars" color="#ffffff" height={80} width={80} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loader.loading
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppLoader);
