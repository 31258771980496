/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from "react";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import undoIcon from "../../../../assets/img/undo@2x.png";
import redoIcon from "../../../../assets/img/redo@2x.png";
import textIcon from "../../../../assets/img/text.png";
import imageIcon from "../../../../assets/img/image.png";
import { text } from "../shapes";
// import handIcon from "../../../../assets/img/pan_tool@2x.png";
// import zoomOutIcon from "../../../../assets/img/zoom_out@2x.png";
import { FiRefreshCw } from "react-icons/fi";

const Handle = Slider.Handle;

export default function BottomMenubar(props) {
  const {
    isAdmin,
    undo,
    redo,
    reset,
    isPointerSelect,
    togglePointerSelect,
    canUndo,
    canRedo,
    initialZoom,
    zoom,
    handleZoom,
    canEditCanvas,
    onEditCanvas
  } = props;
  const handleChange = value => {
    if (value >= initialZoom - 0.5 && value <= initialZoom + 0.5) {
      handleZoom(value);
    }
  };

  const handle = prop => {
    const { value, dragging, index, ...restProps } = prop;
    const toolTip = `${100 + parseInt((zoom - initialZoom) * 100)}%`;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={toolTip}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={toolTip} {...restProps} />
      </Tooltip>
    );
  };
  return (
    <div className="bottom-menubar mt-auto">
      <button
        className="btn-transparent undo mr-lg-5 mr-4"
        onClick={undo}
        disabled={!canUndo}
      >
        <img src={undoIcon} alt="icon" width="18" height="10" />
        <div>Undo</div>
      </button>
      <button
        className="btn-transparent redo mr-lg-5 mr-4"
        onClick={redo}
        disabled={!canRedo}
      >
        <img src={redoIcon} alt="icon" width="18" height="10" />
        <div>Redo</div>
      </button>
      <button
        className="btn-transparent redo mr-lg-5 mr-4"
        onClick={reset}
        disabled={!canUndo && !canRedo}
      >
        <FiRefreshCw />
        <div>Reset</div>
      </button>

      <button
        className="btn-transparent redo mr-4 d-block d-lg-none"
        onClick={() => {
          props.addShape(text);
        }}
      >
        <img src={textIcon} alt="text_icon" width="auto" height="auto" />
        <div>Text</div>
      </button>

      <button
        className="btn-transparent redo mr-4 d-block d-lg-none"
        onClick={() => {
          props.toggleImport();
        }}
      >
        <img src={imageIcon} alt="text_icon" width="auto" height="auto" />
        <div>Image</div>
      </button>

      {isAdmin && (
        <Fragment>
          <button
            className="btn-transparent redo mr-lg-5 mr-4"
            onClick={togglePointerSelect}
          >
            <svg
              viewBox="0 0 16 18"
              width={18}
              height={15}
              fill={isPointerSelect ? "#f28225" : "white"}
            >
              <path d="M0,0l6,18l2-9h8L0,0z" />
            </svg>
            <div>{isPointerSelect ? "Deselect" : "Select"}</div>
          </button>
          <div className="zoom_slider">
            {/* <img src={zoomOutIcon} alt="icon" className="zoomOut_icon mr-4" /> */}
            <span
              className="increment d-align-middle cursor-pointer mr-3"
              onClick={() => {
                handleChange(zoom - 0.01);
              }}
            >
              -
            </span>
            <div className="mr-3" style={{ width: "300px" }}>
              <Slider
                min={initialZoom - 0.5}
                max={initialZoom + 0.5}
                step={0.01}
                value={zoom}
                onChange={handleChange}
                handle={handle}
              />
            </div>
            <span
              className="decrement d-align-middle cursor-pointer mr-3"
              onClick={() => {
                handleChange(zoom + 0.01);
              }}
            >
              +
            </span>
            <span className="zoom_label">
              {100 + parseInt((zoom - initialZoom) * 100)} %
            </span>
          </div>
        </Fragment>
      )}
      {canEditCanvas && (
        <div
          className="pl-5 redo cursor-pointer"
          style={{ textDecoration: "underline" }}
          onClick={onEditCanvas}
        >
          Edit Canvas
        </div>
      )}
    </div>
  );
}
