import base from "./base.service";

export const fetchPlans = () => {
  return () =>
    base.get({
      url: "/subscriptions"
    });
};

export const fetchRefreshPlans = () => {
  return () =>
    base.get({
      url: "/subscriptions/refresh"
    });
};

export const fetchPlanById = id => {
  return () =>
    base.get({
      url: `/subscriptions?id=${id}`
    });
};

export const updateSubscriptionPlan = data => {
  return () =>
    base.put({
      url: `/sports_subscriptions/addSport`,
      body: data
    });
};

export const fetchPaymentToggle = data => {
  return () =>
    base.post({
      url: "/user_subscriptions/toggle",
      body: data
    });
};

export const fetchUserSubscriptions = () => {
  return () =>
    base.get({
      url: "/user_subscriptions"
    });
};

export const subscribeUserPlan = data => {
  return () =>
    base.post({
      url: "/user_subscriptions/subscribe",
      body: data
    });
};

export const cancelUserSubscriptionPlan = (user_id, data) => {
  return () =>
    base.delete({
      url: `/user_subscriptions/cancel/${user_id}`,
      body: data
    });
};
