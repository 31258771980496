/* eslint-disable no-useless-constructor */

import React from "react";
import "./gModal.scss";
import $ from "jquery";

export default class GModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalId = `modal-${parseInt(Math.random() * 100000)}`;
  }

  componentDidMount() {
    this.setModalProps();
    this.setModalHideListner();
  }

  componentDidUpdate() {
    const { isOpen } = this.props;
    /**
     * manually open/close modal when isOpen is true, So that modal open with animation
     */
    $(`#${this.modalId}`).modal(isOpen ? "show" : "hide");
  }

  setModalProps = () => {
    const { isOpen, backdrop, keyboard } = this.props;
    $(`#${this.modalId}`).modal({
      backdrop: backdrop || false,
      keyboard: keyboard || false,
      show: isOpen
    });
  };

  setModalHideListner = () => {
    $(`#${this.modalId}`).on("hidden.bs.modal", () => {
      /**
       * when user click backdrop, then we need to manually toggle modal close by checking onClose and isOpen
       */
      const { onClose, isOpen } = this.props;
      if (onClose && isOpen) {
        onClose();
      }
    });
  };

  render() {
    const { isClose = true, variant = "", heading = "", children } = this.props;
    return (
      <div className="modal fade show" id={this.modalId}>
        <div className={`modal-dialog modal-dialog-centered modal-${variant}`}>
          <div className="modal-content">
            {(heading || isClose) && (
              <div
                className={`modal-header ${!heading &&
                  isClose &&
                  "align-right"}`}
              >
                <h5 className="modal-title h4">{heading}</h5>
                {isClose && (
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                )}
              </div>
            )}
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}
