/* eslint-disable no-console */
const Spaces = require("digitalocean-spaces");
global.fetch = require("node-fetch");
console.log(">>>>>>>>>>>>>>>>>", global);

const spaces = new Spaces({
  accessKey: process.env.accessKey || "P6OSFPPFSRGIZZQEE76W",
  secretKey:
    process.env.secretKey || "gri58cs09m2Q5vgEc7j0lvQF/cZyqGzxTFrU6Ywz/88",
  region: process.env.region || "sfo2"
});
export default class DigitalOceanSpaces {
  constructor(location, fileContent) {
    this.location = `/${location}`;
    this.fileContent = fileContent;
    this.bucket = "go-edit";
  }

  getFileFromSpace() {
    return new Promise(async (resolve, reject) => {
      try {
        let getResponse = await spaces.getObject({
          bucket: this.bucket,
          key: this.location
        });
        const status = getResponse.status;
        if (status === 200) {
          const response = await getResponse.json();
          console.log(">>>>>>>>>>Response from digitalocean>>>>>", response);
          return resolve(response);
        } else {
          return resolve(null);
        }
      } catch (ex) {
        return reject(ex);
      }
    });
  }

  getImageFileFromSpace() {
    return new Promise(async (resolve, reject) => {
      try {
        let getResponse = await spaces.getObject({
          bucket: this.bucket,
          key: this.location
        });
        const status = getResponse.status;
        if (status === 200) {
          const response = await getResponse.blob();
          var reader = new FileReader();
          reader.onloadend = function() {
            return resolve(reader.result);
          };
          reader.readAsDataURL(response);
        } else {
          return resolve(null);
        }
      } catch (ex) {
        return reject(ex);
      }
    });
  }

  uploadFileToSpace() {
    return new Promise(async (resolve, reject) => {
      try {
        let putResponse = await spaces.putObject({
          bucket: this.bucket,
          key: this.location,
          body: this.fileContent
        });

        const response = await putResponse.json();
        console.log(">>>>>>>>>>Response from digitalocean>>>>>", response);
        return resolve(response);
      } catch (ex) {
        console.log(ex);
        return reject(ex);
      }
    });
  }

  deleteFileFromSpace() {
    return new Promise(async (resolve, reject) => {
      try {
        let delResponse = await spaces.deleteObject({
          bucket: this.bucket,
          key: this.location
        });

        console.log(`del status: ${delResponse.status}`);
        console.log(`del response body: '${await delResponse.text()}'`);
        return resolve(delResponse.text());
      } catch (ex) {
        console.log(ex);
        return reject(ex);
      }
    });
  }
}
