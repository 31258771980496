/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { SketchPicker } from "react-color";
import Dropdown from "react-dropdown";

import "./teamInfo.scss";
import AppInput from "../../components/input/AppInput";
import { updateUserProfile } from "../../../redux/actions/auth";
import appConstants from "../../app.properties";

const baseColors = ["#f8e71c", "#2ECC71", "#FFC300", "#900C3F"];

class TeamInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPickerOpen: false,
      pickerColor: "",
      colorIndex: null,

      initialData: {},

      id: "",
      teamName: "",
      schoolName: "",
      mascotName: "",
      schoolLevel: "",
      classification: "",
      colors: ["", "", "", ""]
    };
    this.schoolLevels = appConstants.schoolLevels;
    this.classifications = appConstants.classifications;
  }

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      const data = {
        id: user.id,
        teamName: user.teamName,
        schoolName: user.schoolName,
        mascotName: user.mascotName,
        schoolLevel: user.schoolLevel,
        classification: user.classification,
        colors: user.colors.map((color, index) => color)
      };

      this.setState({
        ...data,
        initialData: data
      });
    }
  }

  handleChange = (e, key) => {
    const value = e.target.value;
    this.setState({
      [key]: value
    });
  };

  openColorPicker = (color, index) => {
    this.setState({
      isPickerOpen: true,
      pickerColor: color,
      colorIndex: index
    });
  };

  closeColorPicker = () => {
    const { colors, colorIndex, pickerColor } = this.state;
    const clonedColors = colors.slice();
    clonedColors.splice(colorIndex, 1, pickerColor);
    this.setState({
      isPickerOpen: false,
      pickerColor: "",
      colorIndex: null,
      colors: clonedColors
    });
  };

  setPickerColor = ({ hex, rgb }) => {
    /**
     * Append opacity to hex value
     */
    let a = rgb.a;
    if (a < 1) {
      a = ((a * 255) | (1 << 8)).toString(16).slice(1);
      hex = hex + a;
    }

    this.setState({
      pickerColor: hex
    });
  };

  handleSave = () => {
    const {
      id,
      teamName,
      schoolName,
      mascotName,
      schoolLevel,
      classification,
      colors
    } = this.state;
    const data = {
      id,
      teamName,
      schoolName,
      mascotName,
      schoolLevel,
      classification,
      colors: JSON.stringify(colors)
    };
    this.props.updateUserProfile(data);
  };

  handleCancel = () => {
    const { initialData } = this.state;
    this.setState({
      ...initialData
    });
  };

  render() {
    const {
      teamName,
      schoolName,
      mascotName,
      schoolLevel,
      classification,
      colors,
      isPickerOpen,
      pickerColor,
      colorIndex
    } = this.state;
    return (
      <div className="info_container">
        <h4 className="mb-0">Team info</h4>
        <section className="pt-3 pb-2 border-bottom">
          <div className="d-flex flex-wrap">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 mr-lg-4 pl-0 pb-3">
              <AppInput
                type="text"
                name="teamName"
                label="Team Name"
                value={teamName}
                onValueChange={this.handleChange}
              />
            </div>
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 mr-lg-4 pl-0 pb-3">
              <AppInput
                type="text"
                name="schoolName"
                label="School Name"
                value={schoolName}
                onValueChange={this.handleChange}
              />
            </div>
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 mr-lg-4 pl-0 pb-3">
              <AppInput
                type="text"
                name="mascotName"
                label="Mascot Name"
                value={mascotName}
                onValueChange={this.handleChange}
              />
            </div>
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 mr-lg-4 pl-0 pb-3">
              <Dropdown
                placeholder="Select School level"
                options={this.schoolLevels}
                value={schoolLevel}
                onChange={option => {
                  this.handleChange(
                    { target: { value: option.value } },
                    "schoolLevel"
                  );
                }}
                placeholderClassName="placeholder"
              />
            </div>
            <div
              className="col-xl-4 col-lg-5 col-md-6 col-sm-12 mr-lg-4 pl-0 pb-3"
              style={{ height: "4.75rem" }}
            >
              <Dropdown
                placeholder="Choose classification"
                options={this.classifications}
                value={classification}
                onChange={option => {
                  this.handleChange(
                    { target: { value: option.value } },
                    "classification"
                  );
                }}
                placeholderClassName="placeholder"
              />
            </div>
          </div>
        </section>
        <section className="pt-4 pb-2 border-bottom">
          <h4 className="mb-0">Team colors</h4>
          <div className="pt-3">
            {colors.map((color, index) => (
              <div className="d-flex mb-3 position-relative" key={index}>
                <span
                  className="color_card cursor-pointer"
                  style={{ background: color }}
                  onClick={() => {
                    this.openColorPicker(color, index);
                  }}
                />
                <div className="position-relative">
                  <input
                    type="text"
                    value={color ? color.split("#")[1] : "choose color"}
                    disabled={true}
                    className="color_field pl-4"
                  />
                  {color && <span className="hex">#</span>}
                </div>
                {/* <button
                  className="btn btn-orange"
                  onClick={() => {
                    this.openColorPicker(color, index);
                  }}
                >
                  Update
                </button> */}
                {isPickerOpen && colorIndex === index && (
                  <div className="mt-2 fill_picker">
                    <div className="cover" onClick={this.closeColorPicker} />
                    <SketchPicker
                      color={pickerColor || "black"}
                      onChangeComplete={this.setPickerColor}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>
        <div className="pt-4">
          <button
            className="btn btn-white mr-4 mb-3 col-sm-auto"
            onClick={this.handleCancel}
          >
            Cancel
          </button>
          <button
            className="btn btn-orange mb-3 col-sm-auto"
            onClick={this.handleSave}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  updateUserProfile: updateUserProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamInfo);

/*
const colors = typeof user.colors === "string" ? JSON.parse(user.colors) : user.colors;
  if (!id) {
    this.props.createTeam(data, res => {
      this.setState({
        id: res.id
      });
    });
  } else {
    data.id = id;
    this.props.updateTeam(data);
  }
*/
