import React from "react";
import { toast } from "react-toastify";
import { authConstants } from "../actions/auth";
import { setCookie, deleteCookie } from "../../utils/utils";
import HeraldToastify, {
  ICONS
} from "../../home/components/HeraldToastify/HeraldToastify";

const initialState = {
  loading: false,
  user: null,
  user_role: [],

  accessToken: null,
  categories: [],
  subscribedCategories: [],
  users: [],
  onBoardUsers: [],
  error: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.USER_LOGIN:
      return {
        ...state,
        loading: true
      };
    case authConstants.USER_LOGIN_SUCCESS:
      setCookie("Authorization", action.data.accessToken.id, 30);
      toast.success(
        <HeraldToastify icon={ICONS.success} title="Logged in succesfully" />
      );
      return {
        ...state,
        loading: false,
        user: action.data.UserWithRole,
        user_role: action.data.UserWithRole.user_role.map(
          role => role.role.name
        )
      };
    case authConstants.USER_LOGIN_FAIL:
      toast.dismiss();
      toast.error(
        <HeraldToastify icon={ICONS.error} title={action.error.message} />
      );
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.USER_LOGOUT:
      return {
        ...state,
        loading: true
      };

    case authConstants.USER_LOGOUT_SUCCESS:
      /**
       * Remove cookie and user object, while logout
       */
      deleteCookie("Authorization");
      toast.success(
        <HeraldToastify icon={ICONS.success} title="Logged out succesfully" />
      );
      return {
        ...state,
        loading: false,
        user: null,
        user_role: []
      };
    case authConstants.USER_LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.USER_REGISTER:
      return {
        ...state,
        loading: true
      };
    case authConstants.USER_REGISTER_SUCCESS:
      setCookie("Authorization", action.data.accessToken.id, 30);
      toast.success(
        <HeraldToastify icon={ICONS.success} title="User sign up succesfully" />
      );
      return {
        ...state,
        loading: false,
        user: action.data.UserWithRole,
        user_role: action.data.UserWithRole.user_role.map(
          role => role.role.name
        )
      };
    case authConstants.USER_REGISTER_FAIL:
      toast.dismiss();
      toast.error(
        <HeraldToastify icon={ICONS.error} title={action.error.message} />
      );
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.FORGOT_PASSWORD:
      return {
        ...state,
        loading: true
      };
    case authConstants.FORGOT_PASSWORD_SUCCESS:
      toast.success(
        <HeraldToastify
          icon={ICONS.success}
          title="Reset password sent succesfully"
        />
      );
      return {
        ...state,
        loading: false
      };
    case authConstants.FORGOT_PASSWORD_FAIL:
      toast.dismiss();
      toast.error(
        <HeraldToastify icon={ICONS.error} title={action.error.message} />
      );
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.CHANGE_PASSWORD:
      return {
        ...state,
        loading: true
      };

    case authConstants.CHANGE_PASSWORD_SUCCESS:
      toast.success("Password changed successfully");
      toast.success(
        <HeraldToastify icon={ICONS.success} title="User sign up succesfully" />
      );
      return {
        ...state,
        loading: false
      };
    case authConstants.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.FETCH_LOGGED_IN_USER:
      return {
        ...state,
        loading: true
      };
    case authConstants.FETCH_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data,
        user_role: action.data.user_role.map(role => role.role.name)
      };
    case authConstants.FETCH_LOGGED_IN_USER_FAIL:
      /**
       * Remove cookie and user object, while fetch fail due to token mismatch or any network issuue too
       */
      deleteCookie("Authorization");
      return {
        ...state,
        loading: false,
        user: null,
        user_role: [],
        error: action.error
      };

    case authConstants.UPDATE_USER_PROFILE:
      toast.success(
        <HeraldToastify
          icon={ICONS.success}
          title="User profile updated successfully"
        />
      );
      return {
        ...state,
        loading: true
      };
    case authConstants.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.data }
      };
    case authConstants.UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.GET_ALL_CATEGORIES:
      return {
        ...state,
        loading: true
      };
    case authConstants.GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.data.map(item => ({
          value: item.id,
          label: item.name
        }))
      };
    case authConstants.GET_ALL_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.GET_SUBSCRIBED_CATEGORIES:
      return {
        ...state,
        loading: true
      };
    case authConstants.GET_SUBSCRIBED_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        subscribedCategories: action.data.map(item => ({
          value: item.id,
          label: item.name
        }))
      };
    case authConstants.GET_SUBSCRIBED_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.GET_ALL_USERS:
      return {
        ...state,
        loading: true
      };
    case authConstants.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...action.data]
      };
    case authConstants.GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.PENDING_ONBOARD_USER_SUBSCRIPTION:
      return {
        ...state,
        loading: true
      };
    case authConstants.PENDING_ONBOARD_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        onBoardUsers: [...action.data]
      };
    case authConstants.PENDING_ONBOARD_USER_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.ONBOARD_USER_SUBSCRIPTION:
      return {
        ...state,
        loading: true
      };
    case authConstants.ONBOARD_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case authConstants.ONBOARD_USER_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    //****************************** */

    case authConstants.CREATE_TEAM:
      return {
        ...state,
        loading: true
      };
    case authConstants.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case authConstants.CREATE_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case authConstants.UPDATE_TEAM:
      return {
        ...state,
        loading: true
      };
    case authConstants.UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case authConstants.UPDATE_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default authReducer;
