import base from "./base.service";

export const saveOrUpdateSavedLibrary = template => {
  return () =>
    base.post({
      url: "/my_templates/save_template",
      body: template
    });
};

export const getListOfSavedLibraries = data => {
  return () =>
    base.post({
      url: "/my_templates",
      body: data
    });
};

export const getLatestLibraries = () => {
  return () =>
    base.post({
      url: "/my_templates?latest_updates=true"
    });
};

export const getSavedLibraryById = id => {
  return () =>
    base.post({
      url: "/my_templates",
      body: { id }
    });
};

export const deleteSavedLibraryById = id => {
  return () =>
    base.delete({
      url: `/my_templates/${id}`
    });
};

export const duplicateSavedLibrary = template => {
  return () =>
    base.post({
      url: "/my_templates/save_template",
      body: template
    });
};

export const shareToTwitter = id => {
  return () =>
    base.get({
      url: `/my_templates/share/${id}`
    });
};

export const getMyDesignsFolders = () => {
  return () =>
    base.get({
      url: "/folders"
    });
};

export const saveMyDesignsFolder = data => {
  if (!data.id) {
    delete data.id;
    return () =>
      base.post({
        url: "/folders/create",
        body: data
      });
  } else {
    return () =>
      base.put({
        url: "/folders",
        body: data
      });
  }
};

export const deleteMyDesignsFolderById = id => {
  return () =>
    base.delete({
      url: `/folders/${id}`
    });
};
