import React, { useEffect } from "react";
import Progress from "../../../components/ProgressLoader/Progress";

const DurationProgressBar = ({ loadDuration }) => {
  useEffect(() => {
    const abortController = new AbortController();
    return () => abortController.abort();
  }, []);

  return (
    <div className="progress-fixed">
      <div className="progress-container">
        <Progress duration={loadDuration} />
      </div>
    </div>
  );
};

export default DurationProgressBar;
