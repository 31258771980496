import React, { useEffect, useState } from "react";
import Draggable from "../../../../components/draggable/Draggable";
import AnimatedControls from "./AnimatedControls";

const DndAnimationList = ({
  animations,
  selectedShapeIndex,
  removeAnimation,
  handleAnimationChange,
  handleDropAnimations,
  allShapes,
  setSelectedShape
}) => {
  const [cards, setCards] = useState([]);
  const [dragIndex, setDragIndex] = useState(null);

  useEffect(() => {
    setCards(animations);
  }, [animations]);

  const handleSetDragIndex = index => {
    setDragIndex(index);
  };

  const resetDragIndex = () => {
    setDragIndex(null);
  };

  const dragCard = (dragIndex, hoverIndex) => {
    const clonedCards = cards.slice();
    clonedCards.move(dragIndex, hoverIndex);
    setCards([...clonedCards]);
    setDragIndex(hoverIndex);
  };

  const dropCard = () => {
    const newCards = [...cards];
    newCards[0].delay = 0;
    handleDropAnimations([...newCards]);
  };

  return (
    <>
      {cards.map((item, index) => {
        return (
          <Draggable
            key={index}
            index={index}
            dragIndex={dragIndex}
            setDragIndex={handleSetDragIndex}
            resetDragIndex={resetDragIndex}
            dragCard={dragCard}
            dropCard={dropCard}
          >
            <AnimatedControls
              index={index}
              {...item}
              selectedShapeIndex={selectedShapeIndex}
              removeAnimation={removeAnimation}
              handleAnimationChange={handleAnimationChange}
              allShapes={allShapes}
              setSelectedShape={setSelectedShape}
            />
          </Draggable>
        );
      })}
    </>
  );
};

export default DndAnimationList;
