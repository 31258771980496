import React from "react";

const AnimationPreview = ({ videoPreviewUrl }) => {
  return (
    <div className="download-container">
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          className="embed-responsive-item"
          src={videoPreviewUrl}
          type="video/mp4"
        />
      </div>
    </div>
  );
};

export default AnimationPreview;
