/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import React, { Fragment, memo, useEffect } from "react";
import { connect } from "react-redux";
import useImage from "use-image";
import Konva from "konva";
import {
  Stage,
  Layer,
  Rect,
  Circle,
  Text,
  Ellipse,
  Line,
  Ring,
  RegularPolygon,
  Transformer,
  Image,
  Star,
  Shape
} from "react-konva";
import { FiArrowLeft } from "react-icons/fi";

import view from "../../../../assets/img/eye@2x.png";
import replace from "../../../../assets/img/replace@2x.png";
import undoIcon from "../../../../assets/img/undo@2x.png";
import { roundOff } from "../../../../utils/utils";
import { isDataURL } from "../../../../utils/validations";
import { Link } from "react-router-dom";
import useGImage from "../../../components/customHooks/useGImage";
import { Spring } from "react-spring";
import { animated } from "@react-spring/konva";
import AnimationUtils from "../AnimationUtils";
import motionIcon from "../../../../assets/img/motion-icon.svg";

/*
class GStage {
  width = 978;
  height = 550;
  setWidth(width) {
    this.width = width;
  }
  setHeight(height) {
    this.height = height;
  }
}
export const gStage = new GStage();
*/

const GImage = memo(({ src, width, height, animatedProps, ...props }) => {
  const [image, status] = useGImage(src, "Anonymous");
  if (image && (!width || !height)) {
    width = image.width;
    height = image.height;
  }
  // console.log(props.id, `>>>>>>${status}>>>>>>`, props.name, width, height);
  if (animatedProps) {
    return (
      <animated.Image
        {...props}
        {...animatedProps}
        image={image}
        src={src}
        width={width}
        height={height}
      />
    );
  } else {
    return (
      <Image {...props} image={image} src={src} width={width} height={height} />
    );
  }
});

const TransformerComponent = React.forwardRef(({ selectedShapeIndex }, ref) => {
  useEffect(() => {
    const transformer = ref.current;
    if (selectedShapeIndex !== null) {
      const layers = transformer.getLayer();
      const listOfNodes = layers.getChildren();
      transformer.nodes([listOfNodes[selectedShapeIndex]]);
    } else {
      transformer.detach();
    }
    transformer.getLayer().batchDraw();
  }, [selectedShapeIndex]);

  const handleTransformBound = (oldBox, newBox) => {
    if (newBox.width < 1 || newBox.height < 1) {
      return oldBox;
    }
    return newBox;
  };

  return <Transformer ref={ref} boundBoxFunc={handleTransformBound} />;
});

class GCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: 1,
      mousePos: {}
    };
    this.mouseEvents = {
      onMouseOver: this.handleMouseEvents,
      onMouseOut: this.handleMouseEvents,
      onMouseUp: this.handleMouseEvents,
      onMouseDown: this.handleMouseEvents,
      onDragstart: this.handleMouseEvents,
      onDragMove: this.handleMouseEvents,
      onDragEnd: this.handleMouseEvents,
      // dragBoundFunc: this.handleDragBound,
      onTransformEnd: this.handleMouseEvents,
      onClick: this.selectNode,
      onTap: this.selectNode
    };
    this.dragElement = null;
    this.transformer = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyUp);
    document.addEventListener("keydown", this.handleKeyDown, false);
    window.addEventListener("resize", this.setStageDimensions);

    this.setStageDimensions();
    // document.addEventListener("click", this.handleOutsideClick);
  }

  componentDidUpdate(prevProps) {
    const { dimensions: oldDimensions } = prevProps;
    const { dimensions: newDimensions } = this.props;
    if (
      oldDimensions[0] !== newDimensions[0] ||
      oldDimensions[1] !== newDimensions[1]
    ) {
      this.setStageDimensions();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("keyup", this.handleKeyDown);
    window.removeEventListener("resize", this.setStageDimensions);
    // document.removeEventListener("click", this.handleOutsideClick);
  }

  convertDimensions = (arr, maxRange = [978, 550]) => {
    const [width, height] = arr;
    const [maxWidth, maxHeight] = maxRange;
    const scale = Math.min(maxWidth / width, maxHeight / height);
    const convertedDimensions = [
      Math.round(width * scale),
      Math.round(height * scale)
    ];
    return convertedDimensions;
  };

  getContainerDimensions = () => {
    let paddingX = 32;
    let paddingY = 32;
    const container = document.getElementById("canvas_area");
    const innerWidth = window.innerWidth;
    if (innerWidth < 992 && innerWidth >= 768) {
      paddingX = 10;
      paddingY = 10;
    } else if (innerWidth < 768) {
      paddingX = 0;
      paddingY = 10;
    }
    var containerWidth = container.offsetWidth - paddingX * 2;
    var containerHeight = container.offsetHeight - paddingY * 2;
    return [containerWidth, containerHeight > 400 ? containerHeight : 400];
  };

  setStageDimensions = () => {
    const { dimensions } = this.props;
    const containerDimensions = this.getContainerDimensions();
    if (dimensions) {
      const standardConversion = this.convertDimensions(dimensions);
      const browserConversion = this.convertDimensions(
        dimensions,
        containerDimensions
      );
      const scale = Math.min(
        browserConversion[0] / standardConversion[0],
        browserConversion[1] / standardConversion[1]
      );
      // console.log(scale, ">>>>>>>>>>>>", dimensions, browserConversion);
      this.props.setCanvasDimensions(browserConversion);
      this.props.setCanvasScaling(scale);
    }
  };

  handleKeyDown = event => {
    // space and arrow keys
    if ([37, 38, 39, 40].indexOf(event.keyCode) > -1) {
      if (this.props.selectedShapeIndex !== null) {
        event.preventDefault();
      }
    }
  };

  handleKeyUp = event => {
    event.preventDefault();
    const { keyCode } = event;
    if (keyCode === 27) {
      /**
       * detach select, when Esc key is pressed
       */
      this.detachTransform();
    } else if (keyCode === 13) {
      /**
       * Add custom shape, when enter key is pressed
       */
      const { isCustomShape, points } = this.props;
      if (isCustomShape && points.length > 2) {
        this.props.toggleCustomShape();
      }
    } else if (keyCode === 8) {
      /**
       * Remove points , when backspace key is pressed
       */
      const { isCustomShape, points } = this.props;
      const count = points.length;
      if (isCustomShape && count > 0) {
        this.props.removePoints();
      }
    } else if (keyCode === 46) {
      /**
       * remove shape, when delete key is pressed
       */
      const { selectedShapeIndex, isCustomShape } = this.props;
      if (selectedShapeIndex !== null && !isCustomShape) {
        this.props.removeShape(selectedShapeIndex);
      }
      if (isCustomShape) {
        this.props.resetPoints();
      }
    } else if (
      keyCode === 37 ||
      keyCode === 38 ||
      keyCode === 39 ||
      keyCode === 40
    ) {
      const { selectedShapeIndex } = this.props;
      if (selectedShapeIndex !== null) {
        this.moveLayer(keyCode);
      }
    }
  };

  handleOutsideClick = event => {
    if (!this.editorBox.contains(event.target)) {
      this.detachTransform();
    }
  };

  /*getNodeAttrs = node => {
    const {
      width: w,
      height: h,
      scaleX = 1,
      scaleY = 1,
      radius,
      outerRadius,
      radiusX,
      radiusY
    } = node.attrs;
    const { width: STAGE_WIDTH, height: STAGE_HEIGHT } = this.props;
    switch (true) {
      case node instanceof Konva.Rect:
        return {
          ...node.attrs,
          offsetX: [-w * scaleX * 0.75, STAGE_WIDTH - w * scaleX * 0.25],
          offsetY: [-h * scaleY * 0.75, STAGE_HEIGHT - h * scaleY * 0.25],
          W: w * scaleX,
          height: w * scaleY
        };

      case node instanceof Konva.Circle:
        return {
          ...node.attrs,
          offsetX: [
            -radius * scaleX * 0.5,
            STAGE_WIDTH + radius * scaleX * 0.5
          ],
          offsetY: [
            -radius * scaleY * 0.5,
            STAGE_HEIGHT + radius * scaleY * 0.5
          ],
          W: radius * scaleX,
          H: radius * scaleY
        };

      case node instanceof Konva.Ellipse:
        return {
          ...node.attrs,
          offsetX: [
            -radiusX * scaleX * 0.5,
            STAGE_WIDTH + radiusX * scaleX * 0.5
          ],
          offsetY: [
            -radiusY * scaleY * 0.5,
            STAGE_HEIGHT + radiusY * scaleY * 0.5
          ],
          W: radiusX * scaleX,
          H: radiusY * scaleY
        };

      case node instanceof Konva.RegularPolygon:
        return {
          ...node.attrs,
          offsetX: [
            -radius * scaleX * 0.1,
            STAGE_WIDTH + radius * scaleX * 0.1
          ],
          offsetY: [
            -radius * scaleY * 0.1,
            STAGE_HEIGHT + radius * scaleY * 0.1
          ],
          W: radius * scaleX,
          H: radius * scaleY
        };

      case node instanceof Konva.Star:
        return {
          ...node.attrs,
          offsetX: [
            -outerRadius * scaleX * 0.5,
            STAGE_WIDTH + outerRadius * scaleX * 0.5
          ],
          offsetY: [
            -outerRadius * scaleY * 0.5,
            STAGE_HEIGHT + outerRadius * scaleY * 0.5
          ],
          W: outerRadius * scaleX,
          H: outerRadius * scaleY
        };

      //   Text drag out restriction do later

      case node instanceof Konva.Image:
        return {
          ...node.attrs,
          offsetX: [-w * scaleX * 0.75, STAGE_WIDTH - w * scaleX * 0.25],
          offsetY: [-h * scaleY * 0.75, STAGE_HEIGHT - h * scaleY * 0.25],
          W: w * scaleX,
          H: w * scaleY
        };

      case node instanceof Konva.Ring:

        // not implemented now

        return {
          ...node.attrs,
          offsetX: [
            -outerRadius * scaleX * 0.5,
            STAGE_WIDTH + outerRadius * scaleX * 0.5
          ],
          offsetY: [
            -outerRadius * scaleY * 0.5,
            STAGE_HEIGHT + outerRadius * scaleY * 0.5
          ],
          W: outerRadius * scaleX,
          H: outerRadius * scaleY
        };

      default:
        return null;
    }
  };
  */

  handleMouseEvents = event => {
    const container = this.props.getStageRef().container();
    let node, updatedNode;
    switch (event.type) {
      case "mouseover":
        container.style.cursor = "grab";
        break;

      case "mouseout":
        container.style.cursor = "default";
        break;

      case "mousedown":
        container.style.cursor = "grabbing";
        break;

      case "mouseup":
        container.style.cursor = "grab";
        break;

      case "dragstart":
        // event.target.setAttrs({
        //   shadowColor: "lightgray",
        //   shadowOffset: {
        //     x: 5,
        //     y: 5
        //   }
        // });
        /* Initially handled to bound drag layer within canvas, later found issue when rotating
         * a shape can't able to bound properly.
         */
        // this.dragElement = this.getNodeAttrs(event.target);
        break;

      case "dragmove":
        const { width: STAGE_WIDTH, height: STAGE_HEIGHT } = this.props;
        const shape = event.target;

        const box = shape.getClientRect(); // gives {x,y,width, height} ===> x,y belongs top left corner
        const absPos = shape.getAbsolutePosition();

        const { x, y, width, height } = box;

        const [minX, maxX] = [-width * 0.8, STAGE_WIDTH + width * 0.8];
        const [minY, maxY] = [-height * 0.8, STAGE_HEIGHT + height * 0.8];

        const offsetX = box.x - absPos.x;
        const offsetY = box.y - absPos.y;

        const newAbsPos = { ...absPos };
        if (box.x < minX) {
          newAbsPos.x = minX - offsetX;
        }
        if (box.y < minY) {
          newAbsPos.y = minY - offsetY;
        }

        if (box.x + box.width > maxX) {
          newAbsPos.x = maxX - box.width - offsetX;
        }
        if (box.y + box.height > maxY) {
          newAbsPos.y = maxY - box.height - offsetY;
        }
        shape.setAbsolutePosition(newAbsPos);
        break;

      case "dragend":
        event.target.to({
          // shadowOffsetX: 0,
          // shadowOffsetY: 0,
          duration: 0.5,
          easing: Konva.Easings.ElasticEaseOut
        });
        /**
         * update dragged layer
         */
        node = event.target;
        updatedNode = {
          ...node.attrs
          // shadowOffsetX: 0,
          // shadowOffsetY: 0
        };
        /**
         * while drag end, image node has additional attribute, so that need to be removed
         */
        if (node.attrs.image) {
          delete updatedNode.image;
        }
        this.props.updateShapeAttrs(updatedNode, node.index);
        break;

      case "transformend":
        /**
         * update transformed layer
         */
        node = event.target;
        updatedNode = { ...node.attrs };
        if (node.attrs.image) {
          updatedNode.src = node.attrs.image.src;
          delete updatedNode.image;
        }
        this.props.updateShapeAttrs(updatedNode, node.index);
        break;

      default:
        break;
    }
  };
  /*
  handleDragBound = ({ x, y }) => {
    if (this.dragElement) {
      const { offsetX, offsetY } = this.dragElement;
      const [minX, maxX] = offsetX;
      const [minY, maxY] = offsetY;
      const newX = Math.max(minX, Math.min(maxX, x));
      const newY = Math.max(minY, Math.min(maxY, y));

      const pos = {
        x: newX,
        y: newY
      };

      return pos;
    }
    return { x, y };
  };
  */

  onTransform = type => e => {
    if (type === "text") {
      /**
       * On transform usually scaleX, scaleY will update based on that target will expand its size,
       * In same way text will leads to expand its size along it leads to text shrink.width.So we take action
       * not to increase its scale expands its width
       */
      const target = e.target;
      target.setAttrs({
        width: target.width() * target.scaleX(),
        height: target.height() * target.scaleY(),
        scaleX: 1,
        scaleY: 1
      });
    }
  };

  selectNode = event => {
    event.cancelBubble = true;
    const node = event.target;
    const {
      selectedShapeIndex,
      isPointerSelect,
      isCustomShape,
      points
    } = this.props;

    if (selectedShapeIndex !== node.index && !isCustomShape) {
      this.props.setSelectedShape(node.index);
    }
    if (isPointerSelect) {
      const { offsetX, offsetY } = event.evt;
      this.props.setPointerPosition({ x: offsetX, y: offsetY });
    }
    if (isCustomShape) {
      this.props.setPoints();
      if (points.length === 0) {
        /**
         * reset mouse pos when set 1st point
         */
        this.setState({
          mousePos: {}
        });
      }
    }
  };

  deSelectNode = event => {
    const { isPointerSelect, setPointerPosition } = this.props;
    if (event.target === event.target.getStage()) {
      this.detachTransform();
    }
    if (isPointerSelect) {
      const { offsetX, offsetY } = event.evt;
      setPointerPosition({ x: offsetX, y: offsetY });
    }
  };

  detachTransform = () => {
    const { selectedShapeIndex } = this.props;
    if (selectedShapeIndex != null) {
      this.props.setSelectedShape(null);
    }
  };

  handleView = () => {
    this.props.moveUp(this.props.selectedShapeIndex);
  };

  getShapeComponent = type => {
    switch (type) {
      case "rect":
        return Rect;
      case "circle":
        return Circle;
      case "ellipse":
        return Ellipse;
      case "triangle":
        return RegularPolygon;
      case "pentagon":
        return RegularPolygon;
      case "hexagon":
        return RegularPolygon;
      case "regularpolygon":
        return RegularPolygon;
      case "star":
        return Star;
      case "text":
        return Text;
      case "image":
        return GImage;
      case "line":
        return Line;
      case "ring":
        return Ring;
      default:
        return null;
    }
  };

  getAnimatedShapeComponent = type => {
    switch (type) {
      case "rect":
        return animated.Rect;
      case "circle":
        return animated.Circle;
      case "ellipse":
        return animated.Ellipse;
      case "triangle":
        return animated.RegularPolygon;
      case "pentagon":
        return animated.RegularPolygon;
      case "hexagon":
        return animated.RegularPolygon;
      case "regularpolygon":
        return animated.RegularPolygon;
      case "star":
        return animated.Star;
      case "text":
        return animated.Text;
      case "image":
        return animated.GImage;
      case "line":
        return animated.Line;
      case "ring":
        return animated.Ring;
      default:
        return null;
    }
  };

  renderShape = shapeProps => {
    const type = (shapeProps.Type || shapeProps.type).toLowerCase();
    const {
      animatedByShapes,
      width: STAGE_WIDTH,
      height: STAGE_HEIGHT,
      isPlay
    } = this.props;
    const animations = animatedByShapes[shapeProps.id] || [];
    /**
     * If you want apply animations while add isPlay to below if condition
     */
    if (isPlay && animations && animations.length > 0) {
      const animation = new AnimationUtils(
        animations,
        shapeProps,
        STAGE_WIDTH,
        STAGE_HEIGHT
      );
      const Component = this.getAnimatedShapeComponent(type);
      console.log(">>>FROM TO>>>>>", animation.from, animation.to);
      if (type === "image") {
        return (
          <Spring from={animation.from} to={animation.to} key={shapeProps.id}>
            {animatedProps => (
              <GImage
                {...shapeProps}
                animatedProps={animatedProps}
                {...this.mouseEvents}
                onTransform={this.onTransform(type)}
              />
            )}
          </Spring>
        );
      } else {
        return (
          <Spring from={animation.from} to={animation.to} key={shapeProps.id}>
            {animatedProps => (
              <Component
                {...shapeProps}
                {...animatedProps}
                {...this.mouseEvents}
                onTransform={this.onTransform(type)}
              />
            )}
          </Spring>
        );
      }
    } else {
      const Component = this.getShapeComponent(type);
      return (
        <Component
          key={shapeProps.id}
          {...shapeProps}
          {...this.mouseEvents}
          onTransform={this.onTransform(type)}
        />
      );
    }
  };

  moveLayer = direction => {
    const { shapes, selectedShapeIndex } = this.props;
    const selectedShape = shapes[selectedShapeIndex];
    const updatedNode = {
      ...selectedShape
    };
    if (direction === 38) {
      updatedNode.y = updatedNode.y - 2;
    } else if (direction === 40) {
      updatedNode.y = updatedNode.y + 2;
    } else if (direction === 37) {
      updatedNode.x = updatedNode.x - 2;
    } else if (direction === 39) {
      updatedNode.x = updatedNode.x + 2;
    }
    this.props.updateShapeAttrs(updatedNode, selectedShapeIndex);
  };

  onStageClick = event => {
    const { isCustomShape, isPointerSelect, points } = this.props;
    if (event.target === event.target.getStage()) {
      this.detachTransform();
    }
    if (isPointerSelect) {
      const { offsetX, offsetY } = event.evt;
      this.props.setPointerPosition({ x: offsetX, y: offsetY });
    }
    if (isCustomShape) {
      this.props.setPoints();
      if (points.length === 0) {
        this.setState({
          mousePos: {}
        });
      }
    }
  };

  handleMouseMove = e => {
    const { isCustomShape, points, initialScale } = this.props;
    if (!isCustomShape || points.length === 0) return;
    const { layerX, layerY } = e.evt;
    const pos = {
      x: roundOff(layerX / initialScale, 0),
      y: roundOff(layerY / initialScale, 0)
    };
    this.setState({ mousePos: pos });
  };

  render() {
    const {
      shapes,
      selectedShapeIndex,
      setStageRef,
      scale,
      width: STAGE_WIDTH,
      height: STAGE_HEIGHT,
      stagePos,
      isAdmin,
      resetToOriginal,
      isCustomShape,
      history,
      points,
      openAnimationSidebar,
      isPlay
    } = this.props;
    const { mousePos } = this.state;
    let type;
    if (selectedShapeIndex !== null) {
      const selectedShape = shapes[selectedShapeIndex];
      type = (selectedShape.Type || selectedShape.type).toLowerCase();
    }
    // console.log(">>>>>>>>>editor canvas>>>>>>>>>>.>", points);
    return (
      <div
        id="canvas_area"
        className="canvas_container flex-grow-1"
        onClick={this.handleOutsideClick}
      >
        <div className="back_container d-none d-lg-block">
          <button onClick={history.goBack} className="back_btn" title="Go back">
            <FiArrowLeft size={18} color="white" />
          </button>
        </div>
        <section
          className="position-relative py-2 py-lg-0"
          ref={node => {
            this.editorBox = node;
          }}
        >
          <Stage
            width={STAGE_WIDTH}
            height={STAGE_HEIGHT}
            scaleX={scale}
            scaleY={scale}
            position={stagePos}
            onClick={this.onStageClick}
            onWheel={this.props.handleMouseWheel}
            onContentMouseMove={this.handleMouseMove}
            ref={setStageRef}
          >
            <Layer>
              <Rect
                width={STAGE_WIDTH / scale}
                height={STAGE_HEIGHT / scale}
                fill="white"
                x={0}
                y={0}
                scale={1}
                draggable={false}
                listening={false}
              />
            </Layer>
            <Layer
              ref={node => {
                this.layer = node;
              }}
            >
              {isPlay && (
                <Rect
                  width={STAGE_WIDTH / scale}
                  height={STAGE_HEIGHT / scale}
                  fill="white"
                  x={0}
                  y={0}
                  scale={1}
                  draggable={false}
                  listening={false}
                />
              )}
              {shapes.map(shape => this.renderShape(shape))}
              {isCustomShape && (
                <Fragment>
                  <Shape
                    sceneFunc={(context, shape) => {
                      if (points.length) {
                        context.beginPath();
                        context.moveTo(points[0].x, points[0].y); // move pointer to initial
                        points.map(({ x, y }, index) => {
                          if (index > 0) {
                            context.lineTo(x, y);
                            context.moveTo(x, y);
                          }
                          return null;
                        });
                        context.lineTo(mousePos.x, mousePos.y);
                        context.moveTo(mousePos.x, mousePos.y);
                        context.closePath();
                        context.fillStrokeShape(shape);
                      }
                    }}
                    stroke="#ff8501"
                    strokeWidth={2}
                  />
                  <Line
                    sceneFunc={(context, shape) => {
                      if (points.length > 2) {
                        context.beginPath();
                        const initialPoint = points[0];
                        const lastPoint = points[points.length - 1];
                        context.moveTo(lastPoint.x, lastPoint.y);

                        context.lineTo(initialPoint.x, initialPoint.y);

                        context.closePath();
                        context.fillStrokeShape(shape);
                      }
                    }}
                    dash={[10, 10]}
                    stroke="#ff8501"
                    strokeWidth="2"
                  />
                </Fragment>
              )}

              <TransformerComponent
                ref={this.transformer}
                selectedShapeIndex={selectedShapeIndex}
              />
            </Layer>
          </Stage>
          <div className="d-flex canvas-actions animate-action">
            <span
              className="action-item open-animate cursor-pointer"
              onClick={openAnimationSidebar}
            >
              <img src={motionIcon} alt="motion-icon" />
            </span>
          </div>
          {type === "image" && this.props.previousIndex === null && (
            <div className="d-flex canvas-actions">
              <span
                className="action-item cursor-pointer"
                onClick={this.handleView}
              >
                <img src={view} alt="action_icon" />
              </span>
              <span
                className="action-item cursor-pointer mx-2"
                onClick={() => {
                  this.props.toggleImport(true);
                }}
              >
                <img src={replace} alt="action_icon" />
              </span>
            </div>
          )}
          {this.props.previousIndex !== null && (
            <div className="d-flex canvas-actions">
              <span
                className="action-item cursor-pointer mx-2"
                onClick={resetToOriginal}
              >
                <img src={undoIcon} alt="action_icon" />
              </span>
            </div>
          )}
          {isPlay && <div className="apply-overlay" />}
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.auth.user_role.includes("SUPER_USER")
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GCanvas);
