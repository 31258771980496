/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */

import React, { Fragment } from "react";
import "./appInput.scss";

export default class AppInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      type = "text",
      label,
      name,
      required,
      disabled,
      value = "",
      onValueChange,
      inlineStyle,
      errors
    } = this.props;
    return (
      <Fragment>
        <div className="position-relative">
          <input
            type={type}
            name={name}
            value={value !== null && value !== undefined ? value : ""}
            autoComplete="off"
            className="app_input"
            placeholder=" "
            style={inlineStyle}
            disabled={disabled}
            onChange={e => {
              onValueChange(e, name);
            }}
          />
          <div className="floating-label label">
            {label}
            {required && <span className="required"> *</span>}
          </div>
        </div>
        {errors && errors[name] && (
          <div className="error-message invalid pt-1">{errors[name]}</div>
        )}
      </Fragment>
    );
  }
}
