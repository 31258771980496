import React from "react";
import rectangleIcon from "../../../../../assets/img/rectangle.png";
import circleIcon from "../../../../../assets/img/circle.png";
import ellipseIcon from "../../../../../assets/img/oval.png";
import triangleIcon from "../../../../../assets/img/triangle.png";
import pentagonIcon from "../../../../../assets/img/pentagon.png";
import hexagonIcon from "../../../../../assets/img/hexagon.png";
import starIcon from "../../../../../assets/img/star.png";
import textIcon from "../../../../../assets/img/text.png";
import imageIcon from "../../../../../assets/img/image.png";
import Dropdown from "react-dropdown";
import Slider, { createSliderWithTooltip } from "rc-slider";
import draggerIcon from "../../../../../assets/img/dragger@2x.png";
import trashIcon from "../../../../../assets/img/delete-icon-df.png";
import trashIconHover from "../../../../../assets/img/delete-icon.png";

export const ANIMATED_OPTIONS = [
  { value: "FADE IN", label: "Fade in" },
  { value: "FADE OUT", label: "Fade out" },
  { value: "FLY IN FROM LEFT", label: "Fly in from left" },
  { value: "FLY IN FROM RIGHT", label: "Fly in from right" },
  { value: "FLY IN FROM TOP", label: "Fly in from top" },
  { value: "FLY IN FROM BOTTOM", label: "Fly in from bottom" },

  { value: "FLY OUT TO LEFT", label: "Fly out to left" },
  { value: "FLY OUT TO RIGHT", label: "Fly out to right" },
  { value: "FLY OUT TO TOP", label: "Fly out to top" },
  { value: "FLY OUT TO BOTTOM", label: "Fly out to bottom" },
  { value: "ZOOM IN", label: "Zoom in" },
  { value: "ZOOM OUT", label: "Zoom out" },
  { value: "SPIN", label: "Spin" }
];

const delayTypeOptions = [
  { value: "afterPrevious", label: "After previous" },
  { value: "withPrevious", label: "With previous" }
];

const AnimatedControls = ({
  index,
  selectedShapeIndex,
  removeAnimation,
  handleAnimationChange,
  allShapes,
  setSelectedShape,
  ...animatedProps
}) => {
  const handleChange = (key, value) => {
    const data = { ...animatedProps };
    data[key] = value;
    handleAnimationChange(data, index);
  };

  const highlightShapeSelectedOne = () => {
    const highlightIndex = allShapes.findIndex(
      data => data.id === animatedProps.shapedId
    );
    setSelectedShape(highlightIndex ? highlightIndex : 0);
  };

  const SliderWithTooltip = createSliderWithTooltip(Slider);

  return (
    <div className="controlAccordian" id="ani-accordion">
      <div
        className={`card-sidebar-tile-header ${
          selectedShapeIndex == animatedProps.shapedId ? "highlight" : ""
        }`}
      >
        <h3
          className="b-0 collapsed"
          data-toggle="collapse"
          data-target={`#collapse${index}`}
          aria-expanded={`${index == 0 ? "true" : "false"}`}
          aria-controls={`collapse${index}`}
          onClick={highlightShapeSelectedOne}
        >
          <ShapeIcon shape={animatedProps.shape} />
          {animatedProps ? animatedProps.type : "fade"} (
          {animatedProps ? animatedProps.delayType : "withPrevious"})
          <button className="btn-delete" onClick={() => removeAnimation(index)}>
            <img
              src={trashIcon}
              onMouseOver={e => (e.currentTarget.src = trashIconHover)}
              onMouseOut={e => (e.currentTarget.src = trashIcon)}
              alt="trash-icon"
              title="remove"
              className="trash-item"
            />
          </button>
          <div className="dragger_area">
            <img
              src={draggerIcon}
              alt="drag_icon"
              title="drag"
              className="icon"
            />
          </div>
        </h3>
      </div>
      <div
        id={`collapse${index}`}
        className={`collapse ac-animation-list ${index == 0 ? "show" : ""}`}
        aria-labelledby={`heading${index}`}
        data-parent="#ani-accordion"
      >
        <div className="select-animation-type">
          <Dropdown
            placeholder="Select Animation Type"
            options={ANIMATED_OPTIONS}
            value={animatedProps.type}
            onChange={option => handleChange("type", option.value)}
            placeholderClassName="placeholder"
          />
        </div>
        <div className="select-animation-type">
          <Dropdown
            placeholder="Select Animation Type"
            options={delayTypeOptions}
            value={animatedProps.delayType}
            onChange={option => handleChange("delayType", option.value)}
            placeholderClassName="placeholder"
          />
        </div>
        {/* <div className="dropdown-selected-wrap">
          {animatedProps && animatedProps.type === "SPIN" && (
            <div className="select-degree">
              <label>
                <span>Rotate</span>
                <SliderWithTooltip
                  min={0}
                  max={360}
                  step={45}
                  value={animatedProps.spinAngle}
                  onChange={value => {
                    handleChange("spinAngle", value);
                  }}
                />
              </label>
            </div>
          )}
        </div> */}
        <div className="duration-box">
          <h5 className="anim-duration">Duration</h5>
          <SliderWithTooltip
            min={600}
            max={3000}
            step={200}
            value={animatedProps.duration}
            onChange={value => {
              handleChange("duration", value);
            }}
          />
          <div className="slider-button-container">
            <div
              className="toggle-slider-max"
              onClick={() => handleChange("duration", 600)}
            >
              Fast
            </div>
            <div
              className="toggle-slider-med"
              onClick={() => handleChange("duration", 1800)}
            >
              Medium
            </div>
            <div
              className="toggle-slider-min"
              onClick={() => handleChange("duration", 3000)}
            >
              Slow
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ShapeIcon = ({ shape }) => {
  if (shape && shape.type !== undefined) {
    const type = (shape.Type || shape.type).toLowerCase();
    switch (type) {
      case "rect":
        return (
          <img src={rectangleIcon} alt="icon" className="shape_icon mr-2" />
        );
      case "circle":
        return <img src={circleIcon} alt="icon" className="shape_icon mr-2" />;
      case "ellipse":
        return (
          <img
            src={ellipseIcon}
            alt="icon"
            className="shape_icon mr-2"
            style={{ width: "13px", height: "17px" }}
          />
        );
      case "triangle":
        return (
          <img src={triangleIcon} alt="icon" className="shape_icon mr-2" />
        );
      case "pentagon":
        return (
          <img src={pentagonIcon} alt="icon" className="shape_icon mr-2" />
        );
      case "hexagon":
        return <img src={hexagonIcon} alt="icon" className="shape_icon mr-2" />;
      case "star":
        return <img src={starIcon} alt="icon" className="shape_icon mr-2" />;
      case "text":
        return <img src={textIcon} alt="icon" className="shape_icon mr-2" />;
      case "image":
        return <img src={imageIcon} alt="icon" className="shape_icon mr-2" />;
      case "regularpolygon":
        return (
          <img src={triangleIcon} alt="icon" className="shape_icon mr-2" />
        );
      default:
        return null;
    }
  }
};

export default AnimatedControls;
