/* eslint-disable no-unused-vars */
import React from "react";
import AppInput from "../../components/input/AppInput";
import loginlogo from "../../../assets/img/GoEdit-Logo.png";
import facebookIcon from "../../../assets/img/facebook@2x.png";
import instagramIcon from "../../../assets/img/instagram@2x.png";
import twitterIcon from "../../../assets/img/twitter@2x.png";
import youtubeIcon from "../../../assets/img/youtube@2x.png";
import "./login.scss";
import { Link } from "react-router-dom";
import { userLogin } from "../../../redux/actions/auth";
import { connect } from "react-redux";
import { required, isValidEmail, isEmpty } from "../../../utils/validations";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.username || "",
      password: localStorage.password || "",
      rememberMe: localStorage.rememberMe
        ? JSON.parse(localStorage.rememberMe)
        : false,
      errors: {}
    };
    this.validations = {
      email: [required, isValidEmail],
      password: [required]
    };
  }

  handleChange = (e, key) => {
    const value = e.target.value;
    this.setState({
      [key]: value
    });

    const errors = { ...this.state.errors };

    const list = this.validations[key];
    if (!isEmpty(list)) {
      const length = list.length;
      for (let i = 0; i < length; i++) {
        const message = list[i](value);
        if (message) {
          errors[key] = message;
          break;
        }
        errors[key] = null;
      }
      this.setState({
        errors
      });
    }
  };

  handleCheck = event => {
    const { checked } = event.target;
    this.setState({
      rememberMe: checked
    });
  };

  handleValidation = () => {
    let isValid = true;
    const errors = { ...this.state.errors };
    const keys = Object.keys(this.validations);
    keys.forEach(key => {
      const list = this.validations[key];
      if (!isEmpty(list)) {
        const length = list.length;
        for (let i = 0; i < length; i++) {
          const message = list[i](this.state[key]);
          if (message) {
            errors[key] = message;
            isValid = false;
            break;
          }
          errors[key] = null;
        }
      }
    });
    this.setState({
      errors
    });
    return isValid;
  };

  handleSubmit = event => {
    event.preventDefault();
    const { email, password, rememberMe } = this.state;
    /**
     * Handle submit
     */
    if (rememberMe) {
      localStorage.username = email;
      localStorage.password = password;
      localStorage.rememberMe = true;
    }

    let isValid = this.handleValidation();
    if (isValid) {
      const data = {
        email,
        password
      };
      this.props.userLogin(data, res => {
        const role = res.UserWithRole.user_role[0].role.name;
        if (role) {
          if (role.includes("SUPER_USER")) {
            this.props.history.push("/template/folders");
          } else if (role.includes("USER")) {
            const hasSubscribed = res.UserWithRole.user_subscription.length > 0;
            if (hasSubscribed) {
              this.props.history.push("/dashboard");
            } else {
              this.props.history.push("/subscriptions");
            }
          }
        }
      });
    }
  };

  render() {
    const { email, password, rememberMe, errors } = this.state;
    return (
      <div className="row m-0 login_page">
        <div className="login_bg_head">
          <img src={loginlogo} alt="login-bg" className="login_bg_head_image" />
        </div>
        <div className="flex-grow-1 flex-basis-0 d-flex align-items-center flex-column justify-content-center">
          <section className="pt-5 col-xl-5 col-lg-7 col-md-9 col-sm-12">
            <div className="sub_header pb-5">
              New to GOEDIT?
              <Link className="try_it pl-2" to="/signup">
                {/* Try our 14 day FREE TRIAL! */}
                Let's Sign up
              </Link>
            </div>
            <div className="main_header pb-4">Sign in to GoEdit</div>
            <form className="pb-4" onSubmit={this.handleSubmit}>
              <div className="pb-3 col-12 pl-0">
                <AppInput
                  type="text"
                  name="email"
                  label="Email Address"
                  required={true}
                  value={email}
                  onValueChange={this.handleChange}
                  errors={errors}
                />
              </div>
              <div className="pb-3 col-12 pl-0">
                <AppInput
                  type="password"
                  name="password"
                  label="Password"
                  required={true}
                  value={password}
                  onValueChange={this.handleChange}
                  errors={errors}
                />
              </div>
              <div className="w-100">
                <label className="checbox_container">
                  Remember me
                  <input
                    type="checkbox"
                    name="size"
                    value="sm"
                    checked={rememberMe}
                    onChange={this.handleCheck}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <button
                className="btn btn-orange col-sm-auto"
                onClick={this.handleSubmit}
              >
                Sign in
              </button>
            </form>
            <Link className="forgot_password" to="/forgot-password">
              Forgot Password?
            </Link>
          </section>
          <section className="contact_ways py-3">
            <div className="contact pb-3">
              Contact &nbsp;
              <a
                href="mailto:sales@goeditgraphics.com"
                target="_blank"
                className="mail-to"
                rel="noopener noreferrer"
              >
                sales@goeditgraphics.com
              </a>
            </div>
            <div className="text-center">
              <a
                href="https://www.facebook.com/GoEditGraphics"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={facebookIcon} alt="icon" className="via_icon" />
              </a>
              <a
                href="https://www.instagram.com/goeditgraphics/"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={instagramIcon} alt="icon" className="via_icon" />
              </a>
              <a
                href="https://twitter.com/GoEditGraphics"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={twitterIcon} alt="icon" className="via_icon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCuGUuUXlX7SiYhCYXjcPM5A"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={youtubeIcon} alt="icon" className="via_icon" />
              </a>
            </div>
          </section>
        </div>
        <div className="ml-0 login_bg_side">
          <Link to={{ pathname: "https://goeditgraphics.com" }} target="_blank">
            <img
              src={loginlogo}
              alt="login-bg"
              className="login_bg_side_image"
            />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  userLogin: userLogin
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
