import React from "react";
import { toast } from "react-toastify";
import { templateConstants } from "../actions/templates.js";
import { groupBy } from "../../utils/utils";
import HeraldToastify, {
  ICONS
} from "../../home/components/HeraldToastify/HeraldToastify.jsx";
const initialState = {
  templates: [],
  folders: [],
  groupedTemplates: {},
  groupByFolder: {},
  template: null,
  loading: false,
  layers: [],
  canvasPresets: [],
  error: null
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case templateConstants.CREATE_UPDATE_TEMPLATE:
      return {
        ...state,
        loading: true
      };
    case templateConstants.CREATE_UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case templateConstants.CREATE_UPDATE_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case templateConstants.GET_ALL_TEMPLATES:
      return {
        ...state,
        loading: true
      };
    case templateConstants.GET_ALL_TEMPLATES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const groupByFolder = groupBy(action.data.data, "folder");
      Object.keys(groupByFolder).forEach(key => {
        groupByFolder[key] = groupBy(groupByFolder[key], "sports");
      });
      return {
        ...state,
        loading: false,
        templates: [...action.data.data],
        groupedTemplates: groupBy(action.data.data, "sports"),
        groupByFolder: { ...groupByFolder }
      };
    case templateConstants.GET_ALL_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case templateConstants.GET_TEMPLATE:
      return {
        ...state,
        loading: true
      };
    case templateConstants.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        template: action.data.data[0]
      };
    case templateConstants.GET_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case templateConstants.DUPLICATE_TEMPLATE:
      return {
        ...state,
        loading: true
      };
    case templateConstants.DUPLICATE_TEMPLATE_SUCCESS:
      toast.success(
        <HeraldToastify
          icon={ICONS.success}
          title="Template duplicated successfully"
        />
      );
      return {
        ...state,
        loading: false
      };
    case templateConstants.DUPLICATE_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case templateConstants.DELETE_TEMPLATE:
      return {
        ...state,
        loading: true
      };
    case templateConstants.DELETE_TEMPLATE_SUCCESS:
      toast.success(
        <HeraldToastify
          icon={ICONS.success}
          title="Template deleted successfully"
        />
      );
      return {
        ...state,
        loading: false
      };
    case templateConstants.DELETE_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case templateConstants.GET_CANVAS_PRESETS:
      return {
        ...state,
        loading: true
      };
    case templateConstants.GET_CANVAS_PRESETS_SUCCESS:
      return {
        ...state,
        loading: false,
        canvasPresets: action.data.map(preset => {
          const [width, height] = preset.dimension;
          const orientation =
            width === height
              ? "square"
              : width > height
              ? "landscape"
              : "portrait";
          const text = `${width} X ${height}`;
          return { ...preset, orientation, text };
        })
      };
    case templateConstants.GET_CANVAS_PRESETS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case templateConstants.CREATE_UPDATE_TEMPLATE_FOLDER:
      return {
        ...state,
        loading: true
      };
    case templateConstants.CREATE_UPDATE_TEMPLATE_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case templateConstants.CREATE_UPDATE_TEMPLATE_FOLDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case templateConstants.GET_TEMPLATE_FOLDERS:
      return {
        ...state,
        loading: true
      };
    case templateConstants.GET_TEMPLATE_FOLDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        folders: [{ id: null, name: "default" }, ...action.data.FolderInfo].map(
          item => ({
            ...item,
            value: item.id,
            label: item.name
          })
        )
      };
    case templateConstants.GET_TEMPLATE_FOLDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case templateConstants.DELETE_TEMPLATE_FOLDER:
      return {
        ...state,
        loading: true
      };
    case templateConstants.DELETE_TEMPLATE_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case templateConstants.DELETE_TEMPLATE_FOLDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default templateReducer;
