/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import Dropdown from "react-dropdown";
import "./templates.scss";
import searchImg from "../../../assets/img/search@2x.png";
import { Link } from "react-router-dom";
import {
  getTemplates,
  getCanvasPresets,
  getTemplateFolderList
} from "../../../redux/actions/templates";
import { getMyDesignsFolderList } from "../../../redux/actions/savedLibraries";
import TemplateGroup from "../../components/templateGroup/TemplateGroup";

class Templates extends React.Component {
  timeoutId = 0;

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      group: "",
      currentFolder: null
    };
  }

  componentDidMount() {
    const { canvasPresets, folders } = this.props;
    this.getListOfTemplates();
    if (canvasPresets.length === 0) {
      this.props.getCanvasPresets();
    }
    if (folders.length === 0) {
      this.getFolderList();
      this.getDesignFolderList();
    } else {
      this.setFolder();
    }
  }

  getDesignFolderList = () => {
    this.props.getMyDesignsFolderList(this.setFolder);
  };

  getFolderList = () => {
    this.props.getTemplateFolderList(this.setFolder);
  };

  setFolder = () => {
    const { match, folders } = this.props;
    const folderId = match.params.id || null;
    const folder = folders.filter(item => item.id === folderId);
    this.setState({
      currentFolder: folder[0]
    });
  };

  getListOfTemplates = () => {
    const { name, group } = this.state;
    const data = {
      name: name,
      sport_name: group && group !== "All Categories" ? [group] : [],
      folder: this.props.match.params.id || ""
    };
    this.props.getTemplates(data);
  };

  handleSelect = selectedOption => {
    this.setState({ group: selectedOption.label }, () => {
      this.getListOfTemplates();
    });
  };

  handleSearch = e => {
    const value = e.target.value;
    this.setState({ name: value }, () => {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.getListOfTemplates();
      }, 750);
    });
  };

  getOptions = () => {
    const { isAdmin, categories, subscribedCategories } = this.props;
    if (isAdmin) {
      return [{ value: "", label: "All Categories" }, ...categories];
    } else {
      return [{ value: "", label: "All Categories" }, ...subscribedCategories];
    }
  };

  render() {
    const { name, group, currentFolder } = this.state;
    const { isAdmin, loading, groupByFolder, match } = this.props;
    const groupedTemplates = groupByFolder[match.params.id || null] || {};
    const groups = Object.keys(groupedTemplates);

    // eslint-disable-next-line no-console
    console.log("groupedTemplates >>>>>>>>>>>", groupedTemplates);

    return (
      <div className="template-container px-xl-6 px-lg-5 px-md-5 px-sm-5 px-4">
        <h4 className="main-heading m-0">
          Templates
          {currentFolder && (
            <span className="text-capitalize ml-2">
              {currentFolder ? `(${currentFolder.name})` : ""}
            </span>
          )}
        </h4>
        <section className="row align-items-center py-4 m-0 filter">
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 pl-0 pr-lg-0 pr-sm-3 pr-0 mr-lg-5 dropdown-container">
            <Dropdown
              placeholder="All Categories"
              options={this.getOptions()}
              value={group}
              onChange={this.handleSelect}
              placeholderClassName="placeholder"
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 px-0 mt-xs-3 position-relative search-container">
            <input
              type="text"
              className="search-box"
              value={name}
              placeholder="Search by name"
              onChange={this.handleSearch}
            />
            <img alt="search_icon" src={searchImg} className="search-icon" />
          </div>
          {isAdmin && (
            <Link
              to="/editor"
              className="btn btn-orange ml-lg-auto mt-lg-0 mt-sm-3 mr-sm-3 mt-xs-3 mr-md-0 col-sm-auto"
            >
              Create template
            </Link>
          )}
        </section>
        {groups.length === 0 && !loading ? (
          <div className="empty_container mt-4">
            {isAdmin ? (
              <h5>No templates found</h5>
            ) : (
              <Fragment>
                <h4>Your subscriptions might be expired or not activated.</h4>
                <h5>
                  Please check your{" "}
                  <Link to="/subscriptions">subscriptions</Link>
                </h5>
              </Fragment>
            )}
          </div>
        ) : (
          <Fragment>
            {groups.map(group => (
              <section key={group} className="pt-4">
                <TemplateGroup
                  name={group}
                  count={groupedTemplates[group].length}
                  templates={groupedTemplates[group]}
                  type="templates"
                  folderId={this.props.match.params.id}
                  getList={this.getListOfTemplates}
                />
              </section>
            ))}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.auth.user_role.includes("SUPER_USER"),
  loading: state.template.loading,
  folders: state.template.folders,
  categories: state.auth.categories,
  subscribedCategories: state.auth.subscribedCategories,
  groupByFolder: state.template.groupByFolder || {},
  canvasPresets: state.template.canvasPresets
});

const mapDispatchToProps = {
  getTemplateFolderList: getTemplateFolderList,
  getTemplates: getTemplates,
  getCanvasPresets: getCanvasPresets,
  getMyDesignsFolderList: getMyDesignsFolderList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Templates);
