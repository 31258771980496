/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */

import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./layouts/header/Header";
import { authenticatedRoutes, unAuthenticatedRoutes } from "./routes";
import {
  fetchLoggedInUser,
  getAllCategories,
  getSubscribedCategories
} from "../redux/actions/auth";
import { getCookie } from "../utils/utils";

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (getCookie("Authorization")) {
      this.props.fetchLoggedInUser();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user === null && this.props.user) {
      if (this.props.isAdmin) {
        this.props.getAllCategories();
      } else {
        this.props.getSubscribedCategories();
      }
    } else if (prevProps.user && this.props.user === null) {
      this.props.history.push("/login");
    }
  }

  isLoggedIn = () => {
    return this.props.user && this.props.user.id;
  };

  getRoutes = route => {
    return (
      <Route
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <Header />
        {this.isLoggedIn() ? (
          <Switch>
            {authenticatedRoutes.map((route, index) => {
              if (route.to) {
                return (
                  <Redirect
                    from={route.path}
                    to={route.to}
                    exact={route.exact}
                    key={`${index}-route`}
                  />
                );
              } else {
                return (
                  <Route
                    key={`${index}-route`}
                    index={index}
                    path={route.path}
                    render={props => <route.component {...props} />}
                    exact={route.exact}
                  />
                );
              }
            })}
            <Redirect
              to={this.props.isAdmin ? "/template/folders" : "/dashboard"}
            />
          </Switch>
        ) : (
          <Switch>
            {unAuthenticatedRoutes.map((route, index) => {
              if (route.to) {
                return (
                  <Redirect
                    from={route.path}
                    to={route.to}
                    exact={route.exact}
                    key={`${index}-route`}
                  />
                );
              } else {
                return (
                  <Route
                    key={`${index}-route`}
                    index={index}
                    path={route.path}
                    render={props => <route.component {...props} />}
                    exact={route.exact}
                  />
                );
              }
            })}
          </Switch>
        )}
        <ToastContainer
          position={toast.POSITION.TOP_RIGHT}
          autoClose={3000}
          closeOnClick={false}
          hideProgressBar={true}
          newestOnTop={true}
          draggable={false}
          limit={3}
          transition={Slide}
          closeButton={({ closeToast }) => (
            <span
              onClick={closeToast}
              className="material-icons toastClose--btn"
            >
              clear
            </span>
          )}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  isAdmin: state.auth.user_role.includes("SUPER_USER")
});

const mapDispatchToProps = {
  fetchLoggedInUser: fetchLoggedInUser,
  getAllCategories: getAllCategories,
  getSubscribedCategories: getSubscribedCategories
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
