export function undoRedo(initialValue, historyCount = 20) {
  return {
    past: [],
    present: initialValue,
    future: [],
    initialValue: initialValue,
    set: handleSet,
    undo: handleUndo,
    redo: handleRedo,
    reset: handleReset,
    setInit: handleSetInit,
    historyCount
  };
}

function handleSet(newPresent) {
  const { past, present, historyCount } = this;
  const newPast = [...past, present];
  if (newPast.length > historyCount) {
    newPast.shift();
  }
  this.past = newPast;
  this.present = newPresent;
  return {
    ...this,
    past: newPast,
    present: newPresent
  };
}

function handleUndo() {
  const { past, present, future } = this;
  if (past.length > 0) {
    const clonedPast = past.slice();
    const newPresent = clonedPast.pop();
    const newFuture = [present, ...future];

    this.past = clonedPast;
    this.present = newPresent;
    this.future = newFuture;

    return {
      ...this,
      past: clonedPast,
      present: newPresent,
      future: newFuture
    };
  }
  return { ...this };
}

function handleRedo() {
  const { past, present, future } = this;
  if (future.length > 0) {
    const clonedFuture = future.slice();
    const newPresent = clonedFuture.shift();
    const newPast = [...past, present];

    this.past = newPast;
    this.present = newPresent;
    this.future = clonedFuture;

    return {
      ...this,
      past: newPast,
      present: newPresent,
      future: clonedFuture
    };
  } else {
    return { ...this };
  }
}

function handleReset() {
  this.past = [];
  this.present = this.initialValue;
  this.future = [];
  return { ...this };
}

function handleSetInit() {
  this.past = [];
  this.initialValue = this.present;
  this.future = [];
  return { ...this };
}
