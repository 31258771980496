import React, { Fragment, useCallback, useEffect, useState } from "react";
import Slider from "rc-slider";
import Dropdown from "react-dropdown";
import SelectSearch, { fuzzySearch } from "react-select-search";
import boldIcon from "../../../../assets/img/bold@2x.png";
import italicIcon from "../../../../assets/img/italic@2x.png";
import underlineIcon from "../../../../assets/img/underlined@2x.png";
import alignleftIcon from "../../../../assets/img/align_left@2x.png";
import aligncenterIcon from "../../../../assets/img/align_center@2x.png";
import alignrightIcon from "../../../../assets/img/align_right@2x.png";
import appConstants from "../../../app.properties";
import {
  renderFontOption,
  renderFontValue
} from "../../../components/select-search";
import { fontFamilyList } from "../../../../assets/fonts/fonts";

const RenderShortcutMenu = props => {
  let { src, pKey, pValue, selectedShape, handleChange } = props;
  const oldStyle = selectedShape[pKey] || "";
  const isCurrent = oldStyle.includes(pValue);
  return (
    <div className={`menu mr-3 ${isCurrent && "active"}`}>
      <img
        src={src}
        alt="icon"
        className="shortcut_icon"
        onClick={() => {
          let value = isCurrent ? oldStyle.replace(pValue, "") : pValue;
          if (pKey === "fontStyle" && !isCurrent) {
            /**
             * If it is current value remove that style(bold, italic) in oldStyle,
             * else append the style to old
             */
            value = `${value} ${oldStyle}`;
          }
          handleChange(pKey, value);
        }}
      />
    </div>
  );
};

let timeoutId = 0;

const RenderText = props => {
  const { selectedShape, selectedShapeIndex, updateShapeAttrs } = props;
  const [fontSize, setFontSize] = useState(selectedShape.fontSize || 0);

  useEffect(() => {
    setFontSize(selectedShape.fontSize);
  }, [selectedShapeIndex]);

  const handleChange = (key, value) => {
    const updatedNode = {
      ...selectedShape,
      [key]: value
    };
    updateShapeAttrs(updatedNode, selectedShapeIndex);
  };

  const handleFontSizeChange = event => {
    const value = event.target.value;
    setFontSize(value);

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      handleChange("fontSize", parseInt(value));
    }, 500);
  };

  const auto_grow = event => {
    const element = event.target;
    element.style.height = "5px";
    element.style.height = element.scrollHeight + "px";
  };

  const handleTextAreaChange = key => event => {
    const value = (event.target.value || "").replace(/\n/gi, "");
    handleChange(key, value);
  };

  /**
   * Manuallly trigger textarea focus, so that it can auto grow text box
   */
  const autoFocus = useCallback(input => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 10);
    }
  }, []);

  return (
    <Fragment>
      <div className="shape_prop mx-n3">
        <div>Letter spacing</div>
        <div className="mt-2">
          <Slider
            min={0}
            max={25}
            step={0.5}
            value={selectedShape.letterSpacing || 0}
            onChange={value => {
              handleChange("letterSpacing", value);
            }}
          />
        </div>
      </div>
      <div className="text pt-3">
        <div className="field">
          <textarea
            ref={autoFocus}
            rows={1}
            value={selectedShape.text}
            onFocus={auto_grow}
            onKeyUp={auto_grow}
            onChange={handleTextAreaChange("text")}
          />
        </div>
        <div className="field font_family">
          <SelectSearch
            placeholder="Choose a font"
            emptyMessage="Not found"
            options={fontFamilyList}
            value={selectedShape.fontFamily || "Arial"}
            search={true}
            filterOptions={fuzzySearch}
            renderValue={renderFontValue}
            renderOption={renderFontOption}
            onChange={value => {
              handleChange("fontFamily", value);
            }}
          />
        </div>
        <div className="d-flex justify-content-between">
          <div className="field flex-grow-1 pr-3">
            <Dropdown
              placeholder="Font_style"
              options={appConstants.fontStyleList}
              value={selectedShape.fontStyle || "Regular"}
              onChange={option => {
                handleChange("fontStyle", option.value);
              }}
              menuClassName="dropdown-up"
              placeholderClassName="placeholder"
            />
          </div>
          <div className="field">
            <input
              type="number"
              name="Font_Size"
              value={fontSize}
              min={8}
              max={200}
              className="font_size"
              onChange={handleFontSizeChange}
            />
            {/* <Dropdown
                placeholder="Font_size"
                options={fontSizeList}
                value={String(selectedShape.fontSize)}
                onChange={option => {
                  option.value = Number(option.value);handleChange("fontSize", option.value);}}
                controlClassName="w-50"
                menuClassName="dropdown-up"
                placeholderClassName="placeholder"
          /> */}
          </div>
        </div>
        <div className="d-flex">
          <RenderShortcutMenu
            src={boldIcon}
            pKey="fontStyle"
            pValue="bold"
            selectedShape={selectedShape}
            handleChange={handleChange}
          />
          <RenderShortcutMenu
            src={italicIcon}
            pKey="fontStyle"
            pValue="italic"
            selectedShape={selectedShape}
            handleChange={handleChange}
          />
          <RenderShortcutMenu
            src={underlineIcon}
            pKey="textDecoration"
            pValue="underline"
            selectedShape={selectedShape}
            handleChange={handleChange}
          />
          <RenderShortcutMenu
            src={alignleftIcon}
            pKey="align"
            pValue="left"
            selectedShape={selectedShape}
            handleChange={handleChange}
          />
          <RenderShortcutMenu
            src={aligncenterIcon}
            pKey="align"
            pValue="center"
            selectedShape={selectedShape}
            handleChange={handleChange}
          />
          <RenderShortcutMenu
            src={alignrightIcon}
            pKey="align"
            pValue="right"
            selectedShape={selectedShape}
            handleChange={handleChange}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default RenderText;
