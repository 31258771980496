import React from "react";

class Draggable extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDragStart = () => {
    this.props.setDragIndex(this.props.index);
  };

  handleDragOver = event => {
    event.preventDefault();
    const { index, dragIndex } = this.props;
    const hoverIndex = index;
    /**
     * Hover element bounding properties
     */
    const { top, bottom } = event.target.getBoundingClientRect();
    /**
     * Find hover element center position &
     * Find mouse position
     */
    const hoverMiddleY = top + (bottom - top) / 2;
    const hoverMouseY = event.clientY;

    if (dragIndex === hoverIndex) {
      return;
    }

    if (dragIndex < hoverIndex && hoverMouseY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverMouseY > hoverMiddleY) {
      return;
    }

    this.props.dragCard(dragIndex, hoverIndex);
  };

  handleDrop = event => {
    event.preventDefault();
    this.props.dropCard();
    this.props.resetDragIndex();
  };

  render() {
    const { children, dragIndex, index } = this.props;

    return (
      <div
        style={dragIndex === index ? { opacity: 0.1 } : { opacity: 1 }}
        draggable="true"
        onDragStart={this.handleDragStart}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      >
        {children}
      </div>
    );
  }
}

export default Draggable;
