/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useState } from "react";
import RenderShapes from "./ShapeProperties";
import RenderText from "./TextProperties";

var timeoutId = 0;

const TextCard = memo(
  ({
    card,
    index,
    selectedShapeIndex,
    setSelectedShape,
    selectedType,
    selectedShape,
    presetColors,
    updateShapeAttrs
  }) => {
    const type = (card.type || card.Type).toLowerCase();
    if (selectedType === "text" && selectedShapeIndex !== index) {
      /**
       * If we select any text, then show that only text properties
       * So that its easy for user to find out the right item
       */
      return null;
    } else if (selectedType === "text" && selectedShapeIndex === index) {
      /**
       * It list out text properties of selected text
       */
      return (
        <div className="card_body">
          <RenderShapes
            selectedShape={selectedShape}
            selectedShapeIndex={selectedShapeIndex}
            updateShapeAttrs={updateShapeAttrs}
            presetColors={presetColors}
          />
          <div className="mt-4">
            <RenderText
              selectedShape={selectedShape}
              selectedShapeIndex={selectedShapeIndex}
              updateShapeAttrs={updateShapeAttrs}
            />
          </div>
        </div>
      );
    }

    /**
     * It list out all text with name and font size input fields
     */
    const [text, setText] = useState(card.text);
    const [fontSize, setFontSize] = useState(card.fontSize);

    /*
    const handleTextChange = event => {
      const value = event.target.value;
      setText(value);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const updatedNode = {
          ...card,
          text: value
        };
        updateShapeAttrs(updatedNode, index);
      }, 500);
    };
    */
    const handleFontSizeChange = event => {
      const value = event.target.value;
      setFontSize(value);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const updatedNode = {
          ...card,
          fontSize: parseInt(value)
        };
        updateShapeAttrs(updatedNode, index);
      }, 500);
    };

    const handleChange = (key, value) => {
      const updatedNode = {
        ...card,
        [key]: value
      };
      updateShapeAttrs(updatedNode, index);
    };

    const auto_grow = event => {
      const element = event.target;
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
    };

    const handleTextAreaChange = key => event => {
      const value = (event.target.value || "").replace(/\n/gi, "");
      setText(value);
      handleChange(key, value);
    };

    /**
     * Manuallly trigger textarea focus, so that it can auto grow text box
     */
    const autoFocus = useCallback(input => {
      if (input) {
        setTimeout(() => {
          input.focus();
        }, 10);
      }
    }, []);

    const handleExpand = () => {
      setSelectedShape(index);
    };

    // console.log("Text>>>>>>>>>>>>>>>>", text);
    return (
      <div className="d-flex justify-content-lg-between pb-3">
        <textarea
          ref={autoFocus}
          rows={1}
          value={text}
          onFocus={auto_grow}
          onKeyUp={auto_grow}
          onChange={handleTextAreaChange("text")}
        />
        <input
          type="number"
          name={card.name}
          value={fontSize}
          min={8}
          max={200}
          className="field font_size ml-2 h-48px"
          onChange={handleFontSizeChange}
        />
        <i
          className="fa fa-expand ml-2 align-self-center cursor-pointer text-white"
          onClick={handleExpand}
        />
      </div>
    );
  }
);

export default TextCard;
