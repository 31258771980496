/* eslint-disable no-unused-vars */
import React from "react";
import Cropper from "react-easy-crop";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import { getCroppedImg, roundOff } from "../../../utils/utils.js";
import "./crop.scss";

const Handle = Slider.Handle;

const handle = prop => {
  const { value, dragging, index, ...restProps } = prop;
  const toolTip = `${parseInt(value * 100)}%`;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={toolTip}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={toolTip} {...restProps} />
    </Tooltip>
  );
};

export default class Crop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 4 / 4,
      croppedArea: 0,
      croppedAreaPixels: 0
    };
  }

  componentDidMount() {
    this.setState({ imageUrl: this.props.imageUrl });
  }

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedArea,
      croppedAreaPixels
    });
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  handleGetCroppedImage = async () => {
    const { imageUrl, croppedAreaPixels } = this.state;
    const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);
    this.props.onSuccessCrop(croppedImage);
  };

  render() {
    const { imageUrl, crop, zoom, aspect } = this.state;
    return (
      <div className="cropper">
        <div className="crop-container">
          <Cropper
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
            cropShape="round"
          />
        </div>
        <div className="row controls area">
          <div className="col-xs-12 col-sm-6 ml-3 mr-3 mr-4 cropper-slider">
            <Slider
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={this.onZoomChange}
              handle={handle}
            />
          </div>
          <button
            className="col-xs-3 ml-3 mr-3 crop-btn"
            type="button"
            onClick={this.handleGetCroppedImage}
          >
            Crop
          </button>
        </div>
      </div>
    );
  }
}
