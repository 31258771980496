import React from 'react';
import { toast } from "react-toastify";
import HeraldToastify, { ICONS } from "../../home/components/HeraldToastify/HeraldToastify";
import { mediaConstants } from "../actions/mediaLibrary";
const initialState = {
  mediaLibraries: [],
  mediaLibrary: null,
  userTags: [],
  loading: false,
  error: null
};

const mediaLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case mediaConstants.GET_ALL_MEDIA_LIBRARIES:
      return {
        ...state,
        loading: true
      };
    case mediaConstants.GET_ALL_MEDIA_LIBRARIES_SUCCESS:
      return {
        ...state,
        loading: false,
        mediaLibraries: [...action.data]
      };
    case mediaConstants.GET_ALL_MEDIA_LIBRARIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case mediaConstants.ADD_MEDIA_LIBRARY:
      return {
        ...state,
        loading: true
      };
    case mediaConstants.ADD_MEDIA_LIBRARY_SUCCESS:
      toast.success(<HeraldToastify icon={ICONS.success} title="Media added successfully"/>);

      return {
        ...state,
        loading: false
      };
    case mediaConstants.ADD_MEDIA_LIBRARY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case mediaConstants.UPDATE_MEDIA_LIBRARY:
      return {
        ...state,
        loading: true
      };
    case mediaConstants.UPDATE_MEDIA_LIBRARY_SUCCESS:
      toast.success(<HeraldToastify icon={ICONS.success} title="Media tags updated successfully"/>);
      return {
        ...state,
        loading: false
      };
    case mediaConstants.UPDATE_MEDIA_LIBRARY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case mediaConstants.DELETE_MEDIA_LIBRARY:
      return {
        ...state,
        loading: true
      };
    case mediaConstants.DELETE_MEDIA_LIBRARY_SUCCESS:
      toast.success(<HeraldToastify icon={ICONS.success} title="Media deleted successfully"/>);
      return {
        ...state,
        loading: false
      };
    case mediaConstants.DELETE_MEDIA_LIBRARY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case mediaConstants.GET_USER_TAGS:
      return {
        ...state,
        loading: true
      };
    case mediaConstants.GET_USER_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        userTags: [...action.data]
      };
    case mediaConstants.GET_USER_TAGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default mediaLibraryReducer;
