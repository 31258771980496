import base from "./base.service";

export const saveOrUpdateTemplate = template => {
  return () =>
    base.post({
      url: "/templates/save_template",
      body: template
    });
};

export const publishAndUnpublishTemplate = template => {
  return () =>
    base.post({
      url: "/templates/publish",
      body: template
    });
};

export const getListOfTemplates = data => {
  return () =>
    base.post({
      url: "/templates",
      body: data
    });
};

export const getLatestTemplatesList = () => {
  return () =>
    base.post({
      url: "/templates?latest_updates=true"
    });
};

export const getTemplateById = id => {
  return () =>
    base.post({
      url: "/templates",
      body: { id }
    });
};

export const deleteTemplateById = id => {
  return () =>
    base.delete({
      url: `/templates/${id}`
    });
};

export const duplicateTemplate = template => {
  return () =>
    base.post({
      url: "/templates/save_template",
      body: template
    });
};

export const getCanvases = () => {
  return () =>
    base.get({
      url: "/canvases"
    });
};

export const getTemplateFolders = () => {
  return () =>
    base.get({
      url: "/folders"
    });
};

export const saveTemplateFolder = data => {
  if (!data.id) {
    delete data.id;
    return () =>
      base.post({
        url: "/folders/create",
        body: data
      });
  } else {
    return () =>
      base.put({
        url: "/folders",
        body: data
      });
  }
};

export const deleteTemplateFolderById = id => {
  return () =>
    base.delete({
      url: `/folders/${id}`
    });
};
