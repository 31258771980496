import {
  getListOfMedia,
  insertMedia,
  deleteMedia,
  getUserMediaTags
} from "../services/media_axios";

export const mediaConstants = {
  GET_ALL_MEDIA_LIBRARIES: "GET_ALL_MEDIA_LIBRARIES",
  GET_ALL_MEDIA_LIBRARIES_SUCCESS: "GET_ALL_MEDIA_LIBRARIES_SUCCESS",
  GET_ALL_MEDIA_LIBRARIES_FAIL: "GET_ALL_MEDIA_LIBRARIES_FAIL",

  ADD_MEDIA_LIBRARY: "ADD_MEDIA_LIBRARY",
  ADD_MEDIA_LIBRARY_SUCCESS: "ADD_MEDIA_LIBRARY_SUCCESS",
  ADD_MEDIA_LIBRARY_FAIL: "ADD_MEDIA_LIBRARY_FAIL",

  DELETE_MEDIA_LIBRARY: "DELETE_MEDIA_LIBRARY",
  DELETE_MEDIA_LIBRARY_SUCCESS: "DELETE_MEDIA_LIBRARY_SUCCESS",
  DELETE_MEDIA_LIBRARY_FAIL: "DELETE_MEDIA_LIBRARY_FAIL",

  GET_USER_TAGS: "GET_USER_TAGS",
  GET_USER_TAGS_SUCCESS: "GET_USER_TAGS_SUCCESS",
  GET_USER_TAGS_FAIL: "GET_USER_TAGS_FAIL"
};

export const getAllMediaLibrary = (data, cb) => {
  return {
    types: [
      mediaConstants.GET_ALL_MEDIA_LIBRARIES,
      mediaConstants.GET_ALL_MEDIA_LIBRARIES_SUCCESS,
      mediaConstants.GET_ALL_MEDIA_LIBRARIES_FAIL
    ],
    service: getListOfMedia(data),
    onSuccess: cb
  };
};

export const addMediaLibrary = (data, cb) => {
  return {
    types: [
      mediaConstants.ADD_MEDIA_LIBRARY,
      mediaConstants.ADD_MEDIA_LIBRARY_SUCCESS,
      mediaConstants.ADD_MEDIA_LIBRARY_FAIL
    ],
    service: insertMedia(data),
    onSuccess: cb
  };
};

export const deleteMediaLibrary = (id, cb) => {
  return {
    types: [
      mediaConstants.DELETE_MEDIA_LIBRARY,
      mediaConstants.DELETE_MEDIA_LIBRARY_SUCCESS,
      mediaConstants.DELETE_MEDIA_LIBRARY_FAIL
    ],
    service: deleteMedia(id),
    onSuccess: cb
  };
};

export const getUserTags = () => {
  return {
    types: [
      mediaConstants.GET_USER_TAGS,
      mediaConstants.GET_USER_TAGS_SUCCESS,
      mediaConstants.GET_USER_TAGS_FAIL
    ],
    service: getUserMediaTags()
  };
};
