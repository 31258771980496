import { useEffect, useState } from "react";

export const unitRegex = /(-?\d+)((r?em)|(px)|%)$/;
export const parseShadowString = value => {
  const parts = value.split(" ");
  // let position = "outside";
  // if (parts[0] === "inset") {
  //   parts.shift();
  //   position = "inside";
  // }
  if (parts.length === 3) {
    const [x, y, color] = parts;
    return { offset: { x: x, y: y }, color };
  }
  if (parts.length === 4) {
    const [x, y, blur, color] = parts;
    return { offset: { x: x, y: y }, color, blur };
  }
  // if (parts.length === 5) {
  //   const [x, y, blur, spread, color] = parts;
  //   return { offset: { x: x, y: y }, color, spread, blur };
  // }
  return null;
};
export const buildShadowString = params => {
  var _a, _b;
  const values = [
    ((_a = params.offset) === null || _a === void 0 ? void 0 : _a.x) || "0",
    ((_b = params.offset) === null || _b === void 0 ? void 0 : _b.y) || "0",
    params.blur || "0",
    params.color || "#000000"
  ].filter(p => !!p);
  return values.join(" ");
};
export const parseHexColor = value => {
  var _a;
  const match = value.match(/#(?<hex>[0-9A-F]{6,8})/i);
  const hex =
    (_a = match === null || match === void 0 ? void 0 : match.groups) ===
      null || _a === void 0
      ? void 0
      : _a.hex;
  if (hex) {
    let alpha = 255;
    if (hex.length === 8) {
      alpha = parseInt(hex.substr(6, 2), 16);
    }
    return { alpha, color: "#" + hex.substr(0, 6) };
  }
  return null;
};
export const useUnitValue = value => {
  const [amount, setAmount] = useState(0);
  const [unit, setUnit] = useState("px");
  useEffect(() => {
    const matches = value.match(unitRegex);
    if (
      (matches === null || matches === void 0 ? void 0 : matches.length) === 5
    ) {
      setAmount(parseInt(matches[1]));
      setUnit(matches[2]);
    }
  }, [value]);
  return { unit, amount, setUnit, setAmount };
};
