export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const RESET_LOADER = "RESET_LOADER";

export const initialState = {
  count: 0,
  loading: false
};

const loadReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        count: state.count + 1,
        loading: state.count + 1 > 0
      };
    case HIDE_LOADER:
      return {
        count: state.count - 1,
        loading: state.count - 1 > 0
      };
    case RESET_LOADER:
      return {
        count: 0,
        loading: false
      };

    default:
      return state;
  }
};

export const showLoader = () => ({ type: SHOW_LOADER });
export const hideLoader = () => ({ type: HIDE_LOADER });
export const resetLoader = () => ({ type: RESET_LOADER });

export default loadReducer;
