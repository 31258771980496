export const image = {
  type: "image",
  x: 50,
  y: 50,
  draggable: true,
  locked: false,
  crossOrigin: "anonymous",
  listening: true,
  visible: true
};

export const rect = {
  type: "rect",
  x: 100,
  y: 150,
  width: 150,
  height: 150,
  fill: "#8a2be2",
  draggable: true,
  locked: false,
  listening: true,
  visible: true
};

export const circle = {
  type: "circle",
  x: 300,
  y: 150,
  radius: 80,
  fill: "#ff7f50",
  draggable: true,
  locked: false,
  listening: true,
  visible: true
};

export const ellipse = {
  type: "ellipse",
  x: 300,
  y: 350,
  radiusX: 80,
  radiusY: 100,
  fill: "#ff7f50",
  draggable: true,
  locked: false,
  listening: true,
  visible: true
};

export const triangle = {
  type: "triangle",
  x: 450,
  y: 150,
  sides: 3,
  radius: 80,
  fill: "#7fffd4",
  draggable: true,
  locked: false,
  listening: true,
  visible: true
};

export const pentagon = {
  type: "pentagon",
  x: 600,
  y: 150,
  sides: 5,
  radius: 80,
  fill: "#ffb6c1",
  draggable: true,
  locked: false,
  listening: true,
  visible: true
};

export const hexagon = {
  type: "hexagon",
  x: 750,
  y: 150,
  sides: 6,
  radius: 80,
  fill: "#8fbc8f",
  draggable: true,
  locked: false,
  listening: true,
  visible: true
};

export const star = {
  type: "star",
  x: 600,
  y: 300,
  numPoints: 5,
  innerRadius: 40,
  outerRadius: 80,
  fill: "#008b8b",
  draggable: true,
  locked: false,
  listening: true,
  visible: true
};

export const text = {
  type: "text",
  text: "Text here",
  x: 200,
  y: 200,
  letterSpacing: 0,
  fontSize: 24,
  padding: 10,
  fill: "#000000",
  draggable: true,
  locked: false,
  listening: true,
  visible: true
};

export const poly = {
  type: "Line",
  points: [],
  fill: "#00D2FF",
  // stroke: "#000000",
  closed: true,
  draggable: true,
  locked: false,
  listening: true,
  visible: true
};

export const curvedLine = {
  type: "Line",
  x: 20,
  y: 20,
  points: [0, 0, 100, 0, 100, 100],
  tension: 0.5,
  closed: true,
  fillLinearGradientStartPoint: { x: -50, y: -50 },
  fillLinearGradientEndPoint: { x: 50, y: 50 },
  fillLinearGradientColorStops: [0, "#ff0000", 1, "#ffff00"],
  draggable: true,
  locked: false,
  listening: true,
  visible: true
};

export const customPolygon = {
  type: "Line",
  points: [],
  fill: "#00D2FF",
  // stroke: "#000000",
  closed: true,
  draggable: true,
  locked: false,
  listening: true
};

export const degToRad = angle => (angle / 180) * Math.PI;

export const getCenter = shape => {
  const angleRad = degToRad(shape.rotation || 0);
  return {
    x:
      shape.x +
      (shape.width / 2) * (shape.scaleX || 1) * Math.cos(angleRad) +
      (shape.height / 2) * (shape.scaleY || 1) * Math.sin(-angleRad),
    y:
      shape.y +
      (shape.height / 2) * (shape.scaleY || 1) * Math.cos(angleRad) +
      (shape.width / 2) * (shape.scaleX || 1) * Math.sin(angleRad)
  };
};

export const getCenterAxis = shape => {
  const offsetX = shape.width / 2;
  const offsetY = shape.height / 2;
  return {
    x: offsetX,
    y: offsetY
  };
};

export const getWidth = shape => {
  const type = (shape.Type || shape.type).toLowerCase();
  switch (type) {
    case "rect":
    case "image":
      return shape.width * (shape.scaleX || 1);
    case "circle":
    case "triangle":
    case "pentagon":
    case "hexagon":
      return shape.radius * (shape.scaleX || 1);
    case "ellipse":
      return shape.radiusX * (shape.scaleX || 1);
    case "star":
      return shape.outerRadius * (shape.scaleX || 1);
    default:
      return 0;
  }
};

export const getHeight = shape => {
  const type = (shape.Type || shape.type).toLowerCase();
  switch (type) {
    case "rect":
    case "image":
      return shape.height * (shape.scaleY || 1);
    case "circle":
    case "triangle":
    case "pentagon":
    case "hexagon":
      return shape.radius * (shape.scaleY || 1);
    case "ellipse":
      return shape.radiusY * (shape.scaleY || 1);
    case "star":
      return shape.outerRadius * (shape.scaleY || 1);
    default:
      return 0;
  }
}; 

export const getTextHeight = (shape, height) => { 
  return height * (shape.scaleY || 1);
}

export const getRotatedShapeBounds = shape => {
  //Convert deg to radians
  var rot = shape.rotation / 180 * Math.PI;
  var hyp = Math.sqrt(shape.width * shape.width + shape.height * shape.height);
  return {
    newX: shape.x + shape.width / 2 - hyp * Math.abs(Math.cos(rot)) / 2,
    newY: shape.y + shape.height / 2 - hyp * Math.abs(Math.sin(rot)) / 2,
    newWidth: hyp * Math.abs(Math.cos(rot)),
    newHeight: hyp * Math.abs(Math.sin(rot))
  }
};

export const rotateCoords = (shape) => {
  const rad = (shape.rotation * Math.PI) / 180;
  return {
      y: shape.y * Math.cos(rad) - shape.x * Math.sin(rad),
      x: shape.x * Math.cos(rad) + shape.y * Math.sin(rad),
  }
}

export const pythagorasTheorem = (shape) => {
  var w = shape.width;
  var h = shape.height;
  var d = Math.sqrt(w*w + h*h);
  return Math.ceil(d);
};
