/* eslint-disable no-console */
const AWS = require("aws-sdk");
const s3 = new AWS.S3({
  endpoint: "https://sfo2.digitaloceanspaces.com",
  accessKeyId: "P6OSFPPFSRGIZZQEE76W",
  secretAccessKey: "gri58cs09m2Q5vgEc7j0lvQF/cZyqGzxTFrU6Ywz/88",
  params: {
    Bucket: "go-edit",
    ACL: "public-read"
  }
});

const callbackFunction = (err, data) => {
  if (err) console.log(err, err.stack);
  else console.log(data);
};

export const uploadFileToSpace = (
  { location, data },
  cb = callbackFunction
) => {
  const params = {
    Body: data,
    Key: location
  };
  s3.putObject(params, cb);
};
