import React from "react";
import { toast } from "react-toastify";
import { SHOW_LOADER, HIDE_LOADER } from "../reducers/loader";
import HeraldToastify, {
  ICONS
} from "../../home/components/HeraldToastify/HeraldToastify";

/* eslint-disable no-unused-vars */
const IMiddleware = client => store => next => async action => {
  /**
   * Action object has some predefined keys namely
   * types / type, service, data, rest(all the other keys if required)
   */
  const { types, service, onSuccess, onFail, disableLoading, ...rest } = action;

  /**
   * If no service call present, pass the action to reducer
   * else call service get response from server stored in a reducer
   */
  if (!service) {
    return next(action);
  }
  const [REQUEST, SUCCESS, FAIL] = types;

  next({ type: REQUEST, ...rest });
  try {
    !disableLoading && next({ type: SHOW_LOADER });
    const response = await service({ client });
    next({ type: SUCCESS, data: response.data, ...rest });
    !disableLoading && next({ type: HIDE_LOADER });
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (err) {
    const error = err.response ? err.response.data.error : null;
    if (error) {
      toast.error(
        <HeraldToastify
          icon={ICONS.error}
          title={error.message || "Something went wrong.Try again later"}
        />
      );
      next({ type: FAIL, error, ...rest });
    }
    !disableLoading && next({ type: HIDE_LOADER });
    if (onFail && error) {
      onFail(error);
    }
  }
};

export default IMiddleware;
