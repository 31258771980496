/* eslint-disable no-unused-vars */
import React from "react";
import Draggable from "../../../components/draggable/Draggable";
import duplicateIcon from "../../../../assets/img/duplicate_black@2x.png";
import deleteIcon from "../../../../assets/img/delete_black@2x.png";
import LayerCard from "./LayerCard";
import { shallowEqual } from "react-redux";

class Layers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      dragIndex: null
      // selectedList: new Set()
    };
  }

  componentDidMount() {
    this.setState({
      cards: this.props.cards
    });
  }

  componentDidUpdate(prevProps) {
    if (!shallowEqual(prevProps.cards, this.props.cards)) {
      this.setState({
        cards: this.props.cards
      });
      // console.log(">>>>>>>>.cards>>>>>>>>>>", ...this.props.cards);
    }
  }

  setDragIndex = index => {
    this.setState({
      dragIndex: index
    });

    /**
     * while dragStart reset selected shape index, so that
     * it relieves diffrent items to get selected
     */
    if (this.props.selectedShapeIndex !== null) {
      this.props.setSelectedShape(null);
    }
  };

  resetDragIndex = () => {
    // console.log(">>>>>>>>>reset drag index");
    this.setState({
      dragIndex: null
    });
  };

  dragCard = (dragIndex, hoverIndex) => {
    const clonedShapes = this.state.cards.slice();
    clonedShapes.move(dragIndex, hoverIndex);
    this.setState({
      cards: [...clonedShapes],
      dragIndex: hoverIndex
    });
  };

  dropCard = () => {
    this.props.updateLayer([...this.state.cards]);
  };

  handleDuplicate = () => {
    const { cards } = this.state;
    const { selectedShapeIndex } = this.props;
    if (selectedShapeIndex !== null) {
      const newNode = {
        ...cards[selectedShapeIndex]
      };
      const type = (newNode.type || newNode.Type).toLowerCase();
      /**
       * duplicate layer on same area
       */
      this.props.addShape(newNode, false);
    }
  };

  handleDelete = () => {
    const { selectedShapeIndex } = this.props;
    if (selectedShapeIndex !== null) {
      this.props.removeShape(selectedShapeIndex);
    }
  };

  /*handleCheck = event => {
    const { value, checked } = event.target;
    const { selectedList } = this.state;
    if (checked) {
      selectedList.clear();
      selectedList.add(value);
    } else {
      selectedList.delete(value);
    }
    this.setState({
      selectedList: selectedList
    });
  };

  handleDuplicate = () => {
    const { cards, selectedList } = this.state;
    const selectedShapeName = [...selectedList][0];

    const index = cards.findIndex(card => card.name === selectedShapeName);
    const newNode = {
      ...cards[index],
      x: cards[index].x + 50,
      y: cards[index].y + 50
    };
    this.props.addShape(newNode);
    this.setState({
      selectedList: new Set()
    });
  };

  handleDelete = () => {
    const { cards, selectedList } = this.state;
    const selectedShapeName = [...selectedList][0];

    const index = cards.findIndex(card => card.name === selectedShapeName);
    this.props.removeShape(index);
    this.setState({
      selectedList: new Set()
    });
  };*/

  render() {
    const { cards, dragIndex } = this.state;
    const {
      updateShapeAttrs,
      selectedShapeIndex,
      setSelectedShape
    } = this.props;
    // console.log(">>>>>>>>>>layers>>>>>>>>>>", dragIndex);

    return (
      <div className="layers">
        <div className="layers_list">
          {cards.map((card, index) => (
            <Draggable
              key={card.id}
              index={index}
              dragIndex={dragIndex}
              setDragIndex={this.setDragIndex}
              resetDragIndex={this.resetDragIndex}
              dragCard={this.dragCard}
              dropCard={this.dropCard}
            >
              <LayerCard
                index={index}
                shape={card}
                updateShapeAttrs={updateShapeAttrs}
                selectedShapeIndex={selectedShapeIndex}
                setSelectedShape={setSelectedShape}
                // selectedList={selectedList}
                // handleCheck={this.handleCheck}
              />
            </Draggable>
          ))}
        </div>
        <div className="layer_actions d-flex justify-content-end py-2 mt-2">
          <div className="text-center px-3">
            <img
              alt="icon"
              src={duplicateIcon}
              className={`action_icon cursor-pointer ${
                selectedShapeIndex === null ? "cursor-not-allowed" : ""
              }`}
              onClick={this.handleDuplicate}
            />
            <div className="action_name">Duplicate</div>
          </div>
          <div className="text-center px-3">
            <img
              alt="icon"
              src={deleteIcon}
              className={`action_icon cursor-pointer ${
                selectedShapeIndex === null ? "cursor-not-allowed" : ""
              }`}
              onClick={this.handleDelete}
            />
            <div className="action_name">Delete</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layers;
