import React, { useEffect, useState } from "react";
import DigitalOceanSpaces from "../../../redux/services/digitalOceanSpaces";

var defaultState = { image: undefined, status: "loading" };

const useGImage = (src, crossOrigin) => {
  var res = useState(defaultState);
  var image = res[0].image;
  var status = res[0].status;

  var setState = res[1];

  useEffect(() => {
    const img = document.createElement("img");
    const onload = () => {
      setState({ image: img, status: "loaded" });
    };

    const onerror = () => {
      setState({ image: undefined, status: "failed" });
    };

    const cb = function(base64) {
      img.addEventListener("load", onload);
      img.addEventListener("error", onerror);
      crossOrigin && (img.crossOrigin = crossOrigin);
      img.src = base64;
    };
    if (!src) {
      return;
    } else {
      if (src.includes(".com/")) {
        const convertedSrc = src.split(".com/")[1];
        const digitalOcean = new DigitalOceanSpaces(convertedSrc);
        digitalOcean.getImageFileFromSpace().then(response => {
          cb(response);
        });
      } else {
        cb(src);
      }
    }
    return function cleanup() {
      img.removeEventListener("load", onload);
      img.removeEventListener("error", onerror);
      setState(defaultState);
    };
  }, [src, crossOrigin]);

  // return array because it it better to use in case of several useImage hooks
  // const [background, backgroundStatus] = useImage(url1);
  // const [patter] = useImage(url2);
  return [image, status];
};

export default useGImage;
