/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { getRefreshPlans } from "../../../redux/actions/subscriptions";
import "./plans.scss";

class Plans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: []
    };

    this.filterParams = {
      buttons: ["reset"],
      debounceMs: 500,
      suppressAndOrCondition: true
    };

    this.columnDefs = [
      {
        headerName: "Plan name",
        field: "plan_name"
      },
      {
        headerName: "Plan Cost",
        field: "plan_cost"
      },
      {
        headerName: "Plan duration",
        field: "recurring_type",
        valueFormatter: this.getPlanDuration
      },
      {
        headerName: "Categories",
        field: "sports_type",
        wrapText: true,
        valueFormatter: this.getCategories
      },
      {
        headerName: "Action",
        cellStyle: { "justify-content": "center" },
        cellRenderer: params => {
          const plan = params.data;
          var eDiv = document.createElement("div");
          eDiv.innerHTML = `<button class="btn btn-orange mr-2 p-2">Edit</button>`;
          var editBtn = eDiv.querySelectorAll(".btn-orange")[0];

          editBtn.addEventListener("click", () => {
            this.handleEdit(plan);
          });

          return eDiv;
        }
      }
    ];

    this.defaultColumnDef = {
      sortable: true,
      resizable: true,
      suppressMenu: true,
      cellClass: "grid-cell-centered",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      filterParams: { ...this.filterParams }
    };
  }

  componentDidMount() {
    this.getSubscriptionPlans();
  }

  getSubscriptionPlans = () => {
    this.props.getSubscriptionPlans({
      cb: data => {
        this.setState({
          rowData: [...data]
        });
      }
    });
  };

  getPlanDuration = ({ value }) => {
    switch (value) {
      case "weeks":
        return "weekly";
      case "months":
        return "monthly";
      case "years":
        return "yearly";
      default:
        return "";
    }
  };

  getCategories = ({ value: categories }) => {
    const list = categories.map(item => item.name);
    return list.join(", ");
  };

  handleEdit = plan => {
    this.props.history.push(`/plan/${plan.id}`);
  };

  render() {
    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: "calc(100vh - 75px)",
          overflowX: "hidden"
        }}
      >
        <AgGridReact
          columnDefs={this.columnDefs}
          defaultColDef={this.defaultColumnDef}
          rowData={this.state.rowData}
          suppressCellSelection={true}
          rowHeight={60}
          onGridReady={params => {
            this.gridApi = params.api;
            this.gridApi.sizeColumnsToFit();
          }}
          animateRows={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  getSubscriptionPlans: getRefreshPlans
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plans);
