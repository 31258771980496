/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import RenderText from "./TextProperties";
import RenderShapes from "./ShapeProperties";
import downArrow from "../../../../assets/img/down-arrow.png";

class Properties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCards: new Set()
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedShapeIndex !== this.props.selectedShapeIndex) {
      if (this.props.selectedShape) {
        this.setShapeProps();
      } else {
        this.setState({
          activeCards: new Set()
        });
      }
    }
  }

  setShapeProps = () => {
    const { selectedShape } = this.props;
    let active = new Set();
    if (selectedShape) {
      const type = (selectedShape.Type || selectedShape.type).toLowerCase();
      if (type === "text") {
        active.add("text");
      } else if (type === "image") {
        active.add("images");
      } else {
        active.add("shapes");
      }
      this.setState({
        activeCards: active
      });
    }
  };

  Card = ({ title, eventKey, children }) => {
    const { activeCards } = this.state;
    return (
      <div className="card">
        <div
          className={`card-header ${
            activeCards.has(eventKey) ? "" : "collapsed"
          }`}
          data-toggle="collapse"
          data-target={`#${eventKey}`}
          onClick={() => {
            this.setActiveCard(eventKey);
          }}
        >
          {title}
          <img src={downArrow} alt="arrow" className="rotateArrow" />
        </div>
        <div
          id={eventKey}
          className={`collapse ${activeCards.has(eventKey) ? "show" : ""}`}
        >
          <div className="card-body">{children}</div>
        </div>
      </div>
    );
  };

  RenderImages = () => {
    return (
      <div className="text-center">
        <button
          className="btn btn-black"
          onClick={() => {
            this.props.toggleImport();
          }}
        >
          Import Images
        </button>
      </div>
    );
  };

  setActiveCard = eventKey => {
    const newSet = new Set(this.state.activeCards);
    if (newSet.has(eventKey)) {
      newSet.delete(eventKey);
    } else {
      newSet.add(eventKey);
    }
    this.setState({
      activeCards: newSet
    });
  };

  render() {
    const {
      selectedShape,
      selectedShapeIndex,
      updateShapeAttrs,
      presetColors
    } = this.props;
    let type = "";
    if (selectedShape) {
      type = (selectedShape.Type || selectedShape.type).toLowerCase();
    }
    return (
      <div className="card-list">
        <this.Card title="Images" eventKey="images">
          {selectedShape && type === "image" ? (
            <Fragment>
              <RenderShapes
                selectedShape={selectedShape}
                selectedShapeIndex={selectedShapeIndex}
                updateShapeAttrs={updateShapeAttrs}
              />
              <div className="mt-3 pt-3">
                <this.RenderImages />
              </div>
            </Fragment>
          ) : (
            <this.RenderImages />
          )}
        </this.Card>
        <this.Card title="Shapes" eventKey="shapes">
          {selectedShape && type !== "image" && type !== "text" ? (
            <RenderShapes
              selectedShape={selectedShape}
              selectedShapeIndex={selectedShapeIndex}
              updateShapeAttrs={updateShapeAttrs}
              presetColors={presetColors}
            />
          ) : null}
        </this.Card>
        <this.Card title="Text" eventKey="text">
          {selectedShape && type === "text" ? (
            <Fragment>
              <RenderShapes
                selectedShape={selectedShape}
                selectedShapeIndex={selectedShapeIndex}
                updateShapeAttrs={updateShapeAttrs}
                presetColors={presetColors}
              />
              <div className="mt-4">
                <RenderText
                  selectedShape={selectedShape}
                  selectedShapeIndex={selectedShapeIndex}
                  updateShapeAttrs={updateShapeAttrs}
                />
              </div>
            </Fragment>
          ) : null}
        </this.Card>
      </div>
    );
  }
}

export default Properties;
