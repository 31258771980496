/* eslint-disable prettier/prettier */
import React, { useState, useEffect, Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-dropdown";
import "./templateList.scss";
import searchImg from "../../../assets/img/search@2x.png";
import { Link } from "react-router-dom";
import {
  getTemplates,
  getCanvasPresets,
  getTemplateFolderList
} from "../../../redux/actions/templates";
import {
  getMyDesignsFolderList
} from "../../../redux/actions/savedLibraries";
import { sortBy } from "../../../utils/utils";
import TemplateCard from "../../components/templateCard/TemplateCard";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import GModal from "../../components/modal/GModal";
import ExportImage from "../../components/exportImage/ExportImage";

const sortTypes = [
  {
    label: "Updated time (desc)",
    value: ["updated_at", "desc"]
  },
  {
    label: "Updated time (asc)",
    value: ["updated_at", "asc"]
  },
  {
    label: "Name (desc)",
    value: ["name", "desc"]
  },
  {
    label: "Name (asc)",
    value: ["name", "asc"]
  }
];

function CategoryTemplateList(props) {
  const { match } = props;
  let timeoutId = useRef(0);

  const dispatch = useDispatch();

  const [templates, setTemplates] = useState([]);

  const [selectedOption, setSelectedOption] = useState({
    label: "Updated time (desc)",
    value: ["update_at", "desc"]
  });

  const [totalItem, setTotalItem] = useState(0);
  const [visibleItem, setVisibleItem] = useState(8);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [exportTemplate, setExportTemplate] = useState(null);
  const [categoryName, setCategoryName] = useState(
    decodeURIComponent(match.params.name)
  );
  const [customRoutes, setCustomRoutes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const { isAdmin, loading, folderTemplates, folders } = useSelector(
    state => ({
      isAdmin: state.auth.user_role.includes("SUPER_USER"),
      loading: state.template.loading,
      folderTemplates: state.template.groupByFolder[match.params.folderId || null] ,
      folders: state.template.folders
    })
  );

  useEffect(() => {
    const name = decodeURIComponent(match.params.name);
    if (categoryName !== name) {
      setCategoryName(name);
    }
  }, [match.params.name]);

  useEffect(() => {
    if(match.params.folderId) {
      const folderId = match.params.folderId;
      const folder = folders.find((item) => item.id === folderId);
      if(folder) {
        setCustomRoutes([
          {path : "/template/folders", name: "Template Folders"},
          { path: `/templates/${folder.id}`, name: folder.name },
          { path: "/templates/category/:name", name: categoryName }
        ])
      }
    } else {
      setCustomRoutes([
        {path : "/template/folders", name: "Template Folders"},
        { path: '/templates', name: 'Default' },
        { path: "/templates/category/:name", name: categoryName }
      ])
    }
  }, [folders, match.params.folderId]);

  useEffect(() => {
    /**
     * API hit will happen when there is no category templates found
     * 
     **/
    getListOfTemplates();
    dispatch(getCanvasPresets());
    dispatch(getTemplateFolderList());
    dispatch(getMyDesignsFolderList());
  }, []);

  useEffect(() => {
    if(folderTemplates) {
      const name = decodeURIComponent(match.params.name);
      const templates = folderTemplates[name] || [];
      setTemplates(templates);
      setTotalItem(templates.length)
    }
  }, [folderTemplates]);

  const handleSearch = event => {
    event.preventDefault();
    setSearchTerm(event.target.value);
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      getListOfTemplates();
    }, 750);
  };

  const getListOfTemplates = () => {
    const data = {
      name: searchTerm,
      sport_name: [categoryName],
      folder: match.params.folderId || ""
    };
    dispatch(getTemplates(data));
  };

  const handleSort = selectedOption => {
    const [key, type] = selectedOption.value;
    const clonedTemplates = folderTemplates[categoryName].slice();
    clonedTemplates.sort(sortBy(key, type));
    setSelectedOption(selectedOption);
    setTemplates(clonedTemplates);
  };

  const loadMoreItems = () => {
    setLoader(true);
    setTimeout(() => {
      setVisibleItem(prevVal => prevVal + 8);
      setLoader(false);
    }, 500);
  };

  const handleExport = template => {
    setIsOpen(true);
    setExportTemplate(template);
  };

  const closeExportModal = () => {
    setIsOpen(false);
    setExportTemplate(null);
  };

  return (
    <div className="template-container px-xl-6 px-lg-5 px-md-5 px-sm-5 px-4">
      <Breadcrumbs>
        {customRoutes.map(({ name, path }, index) =>
          index + 1 === customRoutes.length ? (
            <span key={index} className="bold current-page">
              {name}
            </span>
          ) : isAdmin || !isAdmin && index > 0 ? (
            <Link key={index} className="previous-page" to={path}>
              {name}
            </Link>
          ) : null
        )}
      </Breadcrumbs>
      <div className="clearfix breadcrumbs-separator" />
      <section className="row filter-head w-100 pt-5 filter align-items-center">
        <div className="col-md-3 px-0 mt-xs-3 pr-4  mb-xs-3 position-relative search-container">
          <input
            type="text"
            value={searchTerm}
            className="search-box"
            placeholder="Search by name"
            onChange={handleSearch}
          />
          <img alt="search_icon" src={searchImg} className="search-icon" />
        </div>
        <div className="col-md-6 pt-3 pt-sm-0 px-0">
          <label className="pr-3 sort_label">Sort By:</label>
          <div className="d-inline-block sort_container">
            <Dropdown
              placeholder="Sort by"
              options={sortTypes}
              value={selectedOption}
              onChange={handleSort}
              menuClassName="menuClassName"
              placeholderClassName="placeholder"
            />
          </div>
        </div>
        <div className="col-md-3 d-flex">
          {isAdmin && (
            <Link
              to="/editor"
              className="btn btn-orange ml-lg-auto mt-lg-0 mt-sm-3 mt-xs-3 mr-md-0 col-sm-auto"
            >
              Create template
            </Link>
          )}
        </div>
      </section>
      <div className="clearfix breadcrumbs-separator mb-4" />
      {templates.length === 0 && !loading ? (
        <div className="empty_container mt-4">
          {isAdmin ? (
            <h5>No templates found</h5>
          ) : (
            <Fragment>
              <h4>Your subscriptions might be expired or not activated.</h4>
              <h5>
                Please check your <Link to="/subscriptions">subscriptions</Link>
              </h5>
            </Fragment>
          )}
        </div>
      ) : (
        <Fragment>
          <div className="clearfix template-group-separator" />
          <div className="template-group-list">
            {templates.slice(0, visibleItem).map(template => {
              return (
                <div key={template.id}>
                  <TemplateCard
                    template={template}
                    count={template.length}
                    type="templates"
                    getList={() => dispatch(getListOfTemplates)}
                    handleExport={() => handleExport(template)}
                  />
                </div>
              );
            })}
          </div>
          <div className="load-more-wrap d-flex">
            {visibleItem < totalItem && (
              <button className="load-button" onClick={loadMoreItems}>
                {!loader && <span>Load More</span>}
                {loader && <span>Loading...</span>}
              </button>
            )}
          </div>
        </Fragment>
      )}
      <GModal
        isOpen={isOpen}
        variant="md"
        backdrop={true}
        onClose={closeExportModal}
      >
        {isOpen && (
          <ExportImage
            isOpen={isOpen}
            onClose={closeExportModal}
            dimensions={exportTemplate.dimensions}
            sizeList={{
              sm: exportTemplate.download_1x_url,
              md: exportTemplate.download_2x_url,
              lg: exportTemplate.download_3x_url,
              xl: exportTemplate.download_4x_url,
              mp4: exportTemplate.download_video_url
            }}
          />
        )}
      </GModal>
    </div>
  );
}

export default CategoryTemplateList;
