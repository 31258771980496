import React from "react";

export function renderFontValue(valueProps, snapshot, className) {
  const { option } = snapshot;
  const style = {
    fontFamily:
      !snapshot.focus && option && "stack" in option ? option.stack : null
  };

  const inputVal = snapshot.focus ? snapshot.search : snapshot.displayValue;

  return (
    <input
      {...valueProps}
      className={className}
      style={style}
      value={inputVal}
    />
  );
}

export function renderFontOption(props, { stack, name }, snapshot, className) {
  return (
    <button {...props} className={className} type="button">
      <span style={{ fontFamily: stack, fontSize: "18px" }}>{name}</span>
    </button>
  );
}
