import React from "react";
import "./breadcrumbs.scss";

const Breadcrumbs = props => {
  return (
    <div className="mb-4 bg-gray-300">
      <div className="breadcrumbs-container">{props.children}</div>
    </div>
  );
};

export default Breadcrumbs;
