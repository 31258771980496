// import {
//   getTemplateLayersById,
//   getLatestTemplatesList
// } from "../services/templates.js";
import {
  getListOfTemplates,
  getTemplateById,
  deleteTemplateById,
  saveOrUpdateTemplate,
  duplicateTemplate,
  getLatestTemplatesList,
  publishAndUnpublishTemplate,
  getTemplateFolders,
  saveTemplateFolder,
  deleteTemplateFolderById
} from "../services/template_axios.js";
import { getCanvases } from "../services/template_axios.js";

export const templateConstants = {
  CREATE_UPDATE_TEMPLATE: "CREATE_UPDATE_TEMPLATE",
  CREATE_UPDATE_TEMPLATE_SUCCESS: "CREATE_UPDATE_TEMPLATE_SUCCESS",
  CREATE_UPDATE_TEMPLATE_FAIL: "CREATE_UPDATE_TEMPLATE_FAIL",

  GET_TEMPLATE: "GET_TEMPLATE",
  GET_TEMPLATE_SUCCESS: "GET_TEMPLATE_SUCCESS",
  GET_TEMPLATE_FAIL: "GET_TEMPLATE_FAIL",

  DELETE_TEMPLATE: "DELETE_TEMPLATE",
  DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",
  DELETE_TEMPLATE_FAIL: "DELETE_TEMPLATE_FAIL",

  GET_ALL_TEMPLATES: "GET_ALL_TEMPLATES",
  GET_ALL_TEMPLATES_SUCCESS: "GET_ALL_TEMPLATES_SUCCESS",
  GET_ALL_TEMPLATES_FAIL: "GET_ALL_TEMPLATES_FAIL",

  DUPLICATE_TEMPLATE: "DUPLICATE_TEMPLATE",
  DUPLICATE_TEMPLATE_SUCCESS: "DUPLICATE_TEMPLATE_SUCCESS",
  DUPLICATE_TEMPLATE_FAIL: "DUPLICATE_TEMPLATE_FAIL",

  GET_CANVAS_PRESETS: "GET_CANVAS_PRESETS",
  GET_CANVAS_PRESETS_SUCCESS: "GET_CANVAS_PRESETS_SUCCESS",
  GET_CANVAS_PRESETS_FAIL: "GET_CANVAS_PRESETS_FAIL",

  GET_TEMPLATE_FOLDERS: "GET_TEMPLATE_FOLDERS",
  GET_TEMPLATE_FOLDERS_SUCCESS: "GET_TEMPLATE_FOLDERS_SUCCESS",
  GET_TEMPLATE_FOLDERS_FAIL: "GET_TEMPLATE_FOLDERS_FAIL",

  CREATE_UPDATE_TEMPLATE_FOLDER: "CREATE_UPDATE_TEMPLATE_FOLDER",
  CREATE_UPDATE_TEMPLATE_FOLDER_SUCCESS:
    "CREATE_UPDATE_TEMPLATE_FOLDER_SUCCESS",
  CREATE_UPDATE_TEMPLATE_FOLDER_FAIL: "CREATE_UPDATE_TEMPLATE_FOLDER_FAIL",

  DELETE_TEMPLATE_FOLDER: "DELETE_TEMPLATE_FOLDER",
  DELETE_TEMPLATE_FOLDER_SUCCESS: "DELETE_TEMPLATE_FOLDER_SUCCESS",
  DELETE_TEMPLATE_FOLDER_FAIL: "DELETE_TEMPLATE_FOLDER_FAIL"
};

export const createOrUpdateTemplate = ({ template, cb }) => {
  return {
    types: [
      templateConstants.CREATE_UPDATE_TEMPLATE,
      templateConstants.CREATE_UPDATE_TEMPLATE_SUCCESS,
      templateConstants.CREATE_UPDATE_TEMPLATE_FAIL
    ],
    service: saveOrUpdateTemplate(template),
    onSuccess: cb
  };
};

export const changePublishStatus = ({ template, cb }) => {
  return {
    types: [
      templateConstants.CREATE_UPDATE_TEMPLATE,
      templateConstants.CREATE_UPDATE_TEMPLATE_SUCCESS,
      templateConstants.CREATE_UPDATE_TEMPLATE_FAIL
    ],
    service: publishAndUnpublishTemplate(template), //
    onSuccess: cb
  };
};

export const getTemplates = data => {
  return {
    types: [
      templateConstants.GET_ALL_TEMPLATES,
      templateConstants.GET_ALL_TEMPLATES_SUCCESS,
      templateConstants.GET_ALL_TEMPLATES_FAIL
    ],
    service: getListOfTemplates(data)
  };
};

export const getLatestTemplates = () => {
  return {
    types: [
      templateConstants.GET_ALL_TEMPLATES,
      templateConstants.GET_ALL_TEMPLATES_SUCCESS,
      templateConstants.GET_ALL_TEMPLATES_FAIL
    ],
    service: getLatestTemplatesList()
  };
};

export const getTemplate = (id, cb) => {
  return {
    types: [
      templateConstants.GET_TEMPLATE,
      templateConstants.GET_TEMPLATE_SUCCESS,
      templateConstants.GET_TEMPLATE_FAIL
    ],
    service: getTemplateById(id),
    onSuccess: cb
  };
};

export const copyTemplate = ({ data, cb }) => {
  return {
    types: [
      templateConstants.DUPLICATE_TEMPLATE,
      templateConstants.DUPLICATE_TEMPLATE_SUCCESS,
      templateConstants.DUPLICATE_TEMPLATE_FAIL
    ],
    service: duplicateTemplate(data),
    onSuccess: cb
  };
};

export const deleteTemplate = ({ id, cb }) => {
  return {
    types: [
      templateConstants.DELETE_TEMPLATE,
      templateConstants.DELETE_TEMPLATE_SUCCESS,
      templateConstants.DELETE_TEMPLATE_FAIL
    ],
    service: deleteTemplateById(id),
    onSuccess: cb
  };
};

export const getCanvasPresets = () => {
  return {
    types: [
      templateConstants.GET_CANVAS_PRESETS,
      templateConstants.GET_CANVAS_PRESETS_SUCCESS,
      templateConstants.GET_CANVAS_PRESETS_FAIL
    ],
    disableLoading: true,
    service: getCanvases()
  };
};

export const getTemplateFolderList = (onSuccess = null, onFail = null) => {
  return {
    types: [
      templateConstants.GET_TEMPLATE_FOLDERS,
      templateConstants.GET_TEMPLATE_FOLDERS_SUCCESS,
      templateConstants.GET_TEMPLATE_FOLDERS_FAIL
    ],
    service: getTemplateFolders(),
    onSuccess: onSuccess,
    onFail: onFail
  };
};

export const saveAndUpdateTemplateFolder = (data, onSuccess, onFail) => {
  return {
    types: [
      templateConstants.CREATE_UPDATE_TEMPLATE_FOLDER,
      templateConstants.CREATE_UPDATE_TEMPLATE_FOLDER_SUCCESS,
      templateConstants.CREATE_UPDATE_TEMPLATE_FOLDER_FAIL
    ],
    service: saveTemplateFolder(data),
    onSuccess: onSuccess,
    onFail: onFail
  };
};

export const deleteTemplateFolder = (id, onSuccess, onFail) => {
  return {
    types: [
      templateConstants.DELETE_TEMPLATE_FOLDER,
      templateConstants.DELETE_TEMPLATE_FOLDER_SUCCESS,
      templateConstants.DELETE_TEMPLATE_FOLDER_FAIL
    ],
    service: deleteTemplateFolderById(id),
    onSuccess: onSuccess,
    onFail: onFail
  };
};
