import React, { useState } from "react";
import { SketchPicker } from "react-color";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import { ShadowPicker } from "../../../components/ShadowPicker";

const RenderShapes = props => {
  // eslint-disable-next-line prettier/prettier
  const { selectedShape, selectedShapeIndex, updateShapeAttrs, presetColors } = props;
  const [pickerColor, setPickerColor] = useState(selectedShape.fill || 0);
  const [isPickerOpen, setPickerOpen] = useState(false);

  const [shadow, setShadow] = useState("");
  const [isShadowPickerOpen, setShadowPickerOpen] = useState(false);

  const handleChange = (key, value) => {
    const updatedNode = {
      ...selectedShape,
      [key]: value
    };
    if (key === "fill") {
      const colorGroup = getColorGroup(value, presetColors);
      updatedNode.colorGroup = colorGroup;
    }
    updateShapeAttrs(updatedNode, selectedShapeIndex);
  };

  const toggleColorPicker = () => {
    setPickerOpen(!isPickerOpen);
    setPickerColor(selectedShape.fill);
    if (isPickerOpen) {
      handleChange("fill", pickerColor);
    }
  };

  const constructShadow = () => {
    const { shadowOffset, shadowBlur, shadowColor } = selectedShape;
    return `${shadowOffset ? shadowOffset.x : "0"} ${
      shadowOffset ? shadowOffset.y : "0"
    } ${shadowBlur || "0px"} ${
      shadowColor === "lightgray" ? "#d3d3d3" : shadowColor || "#000000" // By default shadowcolor is set as lightgray. so convert to hex
    }`;
  };

  const toggleShadowPicker = () => {
    setShadowPickerOpen(!isShadowPickerOpen);
    if (!isShadowPickerOpen) {
      setShadow(constructShadow());
    }
  };

  const handleChooseShadow = value => {
    setShadow(value);
    const parts = value.split(" ").map(part => part.replace("px", ""));
    const updatedNode = {
      ...selectedShape,
      shadowOffset: { x: parts[0], y: parts[1] },
      shadowOffsetX: parts[0],
      shadowOffsetY: parts[1],
      shadowBlur: parts[2],
      shadowColor: parts[3]
    };
    updateShapeAttrs(updatedNode, selectedShapeIndex);
  };

  const handleChoose = ({ hex, rgb }) => {
    /**
     * Append opacity to hex value
     */
    let a = rgb.a;
    if (a < 1) {
      a = ((a * 255) | (1 << 8)).toString(16).slice(1);
      hex = hex + a;
    }
    setPickerColor(hex);
    handleChange("fill", hex);
  };

  const type = selectedShape
    ? (selectedShape.Type || selectedShape.type).toLowerCase()
    : "";
  return (
    <div className="shapes">
      {type !== "image" && (
        <div className="shape_prop">
          <div
            className="position-relative cursor-pointer"
            onClick={toggleColorPicker}
          >
            <span>Fill</span>
            <span
              className="color_card"
              style={{ backgroundColor: selectedShape.fill || "black" }}
            />
          </div>
          {isPickerOpen && (
            <div className="mt-2 fill_picker">
              <div className="cover" onClick={toggleColorPicker} />
              <SketchPicker
                color={pickerColor || "black"}
                presetColors={presetColors}
                onChangeComplete={handleChoose}
              />
            </div>
          )}
        </div>
      )}
      <div className="shape_prop">
        <div className="position-relative cursor-pointer">
          <span>Shadow</span>
          <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={constructShadow()}
            placement="top"
          >
            <span
              className="material-icons float-right"
              onClick={toggleShadowPicker}
            >
              content_copy
            </span>
          </Tooltip>
        </div>
        {isShadowPickerOpen && (
          <div className="mt-2">
            <div className="cover" onClick={toggleShadowPicker} />
            <ShadowPicker value={shadow} onChange={handleChooseShadow} />
          </div>
        )}
      </div>

      <div className="shape_prop">
        <div>Shadow Opacity</div>
        <div className="mt-2">
          <Slider
            min={0.1}
            max={1}
            step={0.1}
            value={selectedShape.shadowOpacity || 1}
            onChange={value => {
              handleChange("shadowOpacity", value);
            }}
          />
        </div>
      </div>
      <div className="shape_prop">
        <div>Opacity</div>
        <div className="mt-2">
          <Slider
            min={0.1}
            max={1}
            step={0.1}
            value={selectedShape.opacity || 1}
            onChange={value => {
              handleChange("opacity", value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const getColorGroup = (fillColor, colors) => {
  let index = -1;
  colors.some((color, i) => {
    const status = color.startsWith(fillColor);
    if (status) {
      index = i;
      return true;
    }
  });
  return index;
};

export default RenderShapes;
