import React from "react";
import { toast } from "react-toastify";
import HeraldToastify, {
  ICONS
} from "../../home/components/HeraldToastify/HeraldToastify";
import { subscriptionConstants } from "../actions/subscriptions";

const initialState = {
  loading: false,
  plans: [],
  history: [],
  plan: {},
  error: null
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case subscriptionConstants.GET_SUBSCRIPTION_PLANS:
      return {
        ...state,
        loading: true
      };

    case subscriptionConstants.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        plans: [...action.data]
      };

    case subscriptionConstants.GET_SUBSCRIPTION_PLANS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case subscriptionConstants.GET_SUBSCRIPTION_PLAN_BY_ID:
      return {
        ...state,
        loading: true
      };

    case subscriptionConstants.GET_SUBSCRIPTION_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        plan: action.data
      };

    case subscriptionConstants.GET_SUBSCRIPTION_PLAN_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case subscriptionConstants.GET_USER_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true
      };

    case subscriptionConstants.GET_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        history: [...action.data]
      };

    case subscriptionConstants.GET_USER_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case subscriptionConstants.UPDATE_SUBSCRIPTION_PLAN:
      return {
        ...state,
        loading: true
      };

    case subscriptionConstants.UPDATE_SUBSCRIPTION_PLAN_SUCCESS:
      toast.success(
        <HeraldToastify icon={ICONS.success} title={action.data.message} />
      );
      return {
        ...state,
        loading: false
      };

    case subscriptionConstants.UPDATE_SUBSCRIPTION_PLAN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case subscriptionConstants.SUBSCRIBE_PLAN:
      return {
        ...state,
        loading: true
      };

    case subscriptionConstants.SUBSCRIBE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        plan: { ...action.data }
      };

    case subscriptionConstants.SUBSCRIBE_PLAN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default subscriptionReducer;
