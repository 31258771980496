import React from "react";
import Slider from "react-slick";
import "./appCarousel.scss";

function AppCarousel(props) {
  return (
    <div>
      <Slider {...props}>{props.children}</Slider>
    </div>
  );
}

export default AppCarousel;
