/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AppInput from "../../components/input/AppInput";
import loginlogo from "../../../assets/img/GoEdit-Logo.png";
import loginbgcrop from "../../../assets/img/login_background3x_crop.png";
import facebookIcon from "../../../assets/img/facebook@2x.png";
import instagramIcon from "../../../assets/img/instagram@2x.png";
import twitterIcon from "../../../assets/img/twitter@2x.png";
import youtubeIcon from "../../../assets/img/youtube@2x.png";
import { userRegister } from "../../../redux/actions/auth";
import {
  required,
  minLength,
  isMobile,
  isNumber,
  isValidEmail,
  maxLength,
  isEmpty
} from "../../../utils/validations";
import "../login/login.scss";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: "",
      password: "",
      errors: {}
    };
    this.validations = {
      firstName: [required, minLength(3)],
      lastName: [required, minLength(1)],
      zip: [isNumber],
      phone: [required, isMobile],
      email: [required, isValidEmail],
      username: [required],
      password: [required, minLength(8), maxLength(15)]
    };
  }

  handleChange = (e, key) => {
    const value = e.target.value;
    this.setState({
      [key]: value
    });

    const errors = { ...this.state.errors };

    const list = this.validations[key];
    if (!isEmpty(list)) {
      const length = list.length;
      for (let i = 0; i < length; i++) {
        const message = list[i](value);
        if (message) {
          errors[key] = message;
          break;
        }
        errors[key] = null;
      }
      this.setState({
        errors
      });
    }
  };

  handleValidation = () => {
    let isValid = true;
    const errors = { ...this.state.errors };
    const keys = Object.keys(this.validations);
    keys.forEach(key => {
      const list = this.validations[key];
      if (!isEmpty(list)) {
        const length = list.length;
        for (let i = 0; i < length; i++) {
          const message = list[i](this.state[key]);
          if (message) {
            errors[key] = message;
            isValid = false;
            break;
          }
          errors[key] = null;
        }
      }
    });
    this.setState({
      errors
    });
    return isValid;
  };

  handleSubmit = event => {
    event.preventDefault();
    let isValid = this.handleValidation();
    if (isValid) {
      const {
        firstName,
        lastName,
        address_line_1,
        address_line_2,
        city,
        state,
        zip,
        phone,
        username,
        email,
        password
      } = this.state;
      const data = {
        firstName: firstName,
        lastName: lastName,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        username: username,
        email: email,
        password: password
      };

      this.props.userRegister(data, res => {
        const role = res.UserWithRole.user_role[0].role.name;
        if (role) {
          if (role.includes("SUPER_USER")) {
            this.props.history.push("/template/folders");
          } else if (role.includes("USER")) {
            const hasSubscribed = res.UserWithRole.user_subscription.length > 0;
            if (hasSubscribed) {
              this.props.history.push("/dashboard");
            } else {
              this.props.history.push("/subscriptions");
            }
          }
        }
      });
    }
  };

  render() {
    const {
      firstName,
      lastName,
      address_line_1,
      address_line_2,
      city,
      state,
      zip,
      phone,
      username,
      email,
      password,
      errors
    } = this.state;
    return (
      <div className="row m-0 login_page">
        <div className="login_bg_head">
          <img src={loginlogo} alt="login-bg" className="login_bg_head_image" />
        </div>
        <div className="flex-grow-1 flex-basis-0 d-flex align-items-center flex-column justify-content-center">
          {/* <div className="flex-grow-0 flex-basis-0">
            <img
              src={loginbgcrop}
              alt="login-bg"
              className="signup_bg_head d-md-block d-lg-none"
            />
          </div> */}
          <section className="px-xl-6 px-lg-5 px-md-5 px-sm-5 px-4 pt-4">
            <div className="main_header pb-4">Sign up to GoEdit</div>
            <form className="pb-4" onSubmit={this.handleSubmit}>
              <div className="row m-0 justify-content-between pt-2">
                <div className="col-sm-12 col-md-6 pl-0 pb-3">
                  <AppInput
                    type="text"
                    name="firstName"
                    label="First Name"
                    required={true}
                    value={firstName}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pb-3">
                  <AppInput
                    type="text"
                    name="lastName"
                    label="Last Name"
                    required={true}
                    value={lastName}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-lg-12 pl-0 pb-3">
                  <AppInput
                    type="text"
                    name="address_line_1"
                    label="Address line 1"
                    value={address_line_1}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-lg-12 pl-0 pb-3">
                  <AppInput
                    type="text"
                    name="address_line_2"
                    label="Address line 2"
                    value={address_line_2}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pb-3">
                  <AppInput
                    type="text"
                    name="city"
                    label="City"
                    value={city}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pb-3">
                  <AppInput
                    type="text"
                    name="state"
                    label="State"
                    value={state}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pb-3">
                  <AppInput
                    type="text"
                    name="zip"
                    label="Zip"
                    value={zip}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-sm-6 pl-0 pb-3 d-none d-sm-none d-md-block" />
                <div className="col-sm-12 col-md-6 pl-0 pb-3">
                  <AppInput
                    type="text"
                    name="phone"
                    label="Phone"
                    required={true}
                    value={phone}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pb-3">
                  <AppInput
                    type="text"
                    name="username"
                    label="Username"
                    required={true}
                    value={username}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pb-3">
                  <AppInput
                    type="text"
                    name="email"
                    label="Email Address"
                    required={true}
                    value={email}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
                <div className="col-sm-12 col-md-6 pl-0 pb-3">
                  <AppInput
                    type="password"
                    name="password"
                    label="Password"
                    required={true}
                    value={password}
                    onValueChange={this.handleChange}
                    errors={errors}
                  />
                </div>
              </div>
              <button
                className="col-sm-12 col-md-6 btn btn-orange"
                onClick={this.handleSubmit}
              >
                Sign up
              </button>
            </form>
            <Link className="forgot_password" to="/login">
              Back to login?
            </Link>
          </section>
          <section className="contact_ways py-3">
            <div className="contact pb-3">
              Contact &nbsp;
              <a
                href="mailto:sales@goeditgraphics.com"
                target="_blank"
                className="mail-to"
                rel="noopener noreferrer"
              >
                sales@goeditgraphics.com
              </a>
            </div>
            <div className="text-center">
              <a
                href="https://www.facebook.com/GoEditGraphics"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={facebookIcon} alt="icon" className="via_icon" />
              </a>
              <a
                href="https://www.instagram.com/goeditgraphics/"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={instagramIcon} alt="icon" className="via_icon" />
              </a>
              <a
                href="https://twitter.com/GoEditGraphics"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={twitterIcon} alt="icon" className="via_icon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCuGUuUXlX7SiYhCYXjcPM5A"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={youtubeIcon} alt="icon" className="via_icon" />
              </a>
            </div>
          </section>
        </div>
        <div className="ml-0 login_bg_side">
          <Link to={{ pathname: "https://goeditgraphics.com" }} target="_blank">
            <img
              src={loginlogo}
              alt="login-bg"
              className="login_bg_side_image"
            />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  userRegister: userRegister
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
