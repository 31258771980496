/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import draggerIcon from "../../../../assets/img/dragger@2x.png";
import lockOpenIcon from "../../../../assets/img/lock_open@2x.png";
import lockClosedIcon from "../../../../assets/img/lock_closed@2x.png";
import showEyeIcon from "../../../../assets/img/show_eye.png";
import hideEyeIcon from "../../../../assets/img/hide_eye.png";
import rectangleIcon from "../../../../assets/img/rectangle.png";
import circleIcon from "../../../../assets/img/circle.png";
import ellipseIcon from "../../../../assets/img/oval.png";
import triangleIcon from "../../../../assets/img/triangle.png";
import pentagonIcon from "../../../../assets/img/pentagon.png";
import hexagonIcon from "../../../../assets/img/hexagon.png";
import starIcon from "../../../../assets/img/star.png";
import textIcon from "../../../../assets/img/text.png";
import lineIcon from "../../../../assets/img/line.png";
import imageIcon from "../../../../assets/img/image.png";

const LayerCard = ({
  index,
  shape,
  updateShapeAttrs,
  setSelectedShape,
  selectedShapeIndex
  // selectedList,
  // handleCheck
}) => {
  const [isEdit, toggleEdit] = useState(false);
  const [name, setName] = useState("");

  const toggleSelect = () => {
    // if (shape.listening) {
    //   setSelectedShape(selectedShapeIndex !== index ? index : null);
    // }
    setSelectedShape(selectedShapeIndex !== index ? index : null);
  };

  const stopPropagation = event => {
    event.stopPropagation();
  };

  const toggleCheck = event => {
    event.stopPropagation();
    // handleCheck(event);
    setSelectedShape(selectedShapeIndex !== index ? index : null);
  };

  const toggleLock = event => {
    event.stopPropagation();

    const updatedNode = {
      ...shape,
      draggable: !shape.draggable,
      listening: shape.listening !== undefined ? !shape.listening : false,
      locked: shape.locked !== undefined ? !shape.locked : true
    };
    updateShapeAttrs(updatedNode, index);
  };

  const toggleHide = event => {
    event.stopPropagation();

    const updatedNode = {
      ...shape,
      visible: shape.visible !== undefined ? !shape.visible : false
    };
    updateShapeAttrs(updatedNode, index);
  };

  const setLayerName = event => {
    event.stopPropagation();
    const updatedNode = {
      ...shape,
      layerName: name
    };
    updateShapeAttrs(updatedNode, index);
    resetLayerName();
  };

  const resetLayerName = event => {
    if (event) event.stopPropagation();

    setName("");
    toggleEdit(!isEdit);
  };

  return (
    <div
      className={`layer_card d-flex ${
        selectedShapeIndex === index ? "active" : ""
      }`}
      onClick={toggleSelect}
    >
      <div className="dragger_area">
        <img src={draggerIcon} alt="drag_icon" className="icon" />
      </div>
      <div className="dragger_content flex-grow-1">
        {!isEdit && (
          <Fragment>
            <label className="checbox_container">
              <input
                type="checkbox"
                name="size"
                value={shape.name}
                // checked={selectedList.has(shape.name)}
                checked={selectedShapeIndex === index}
                onChange={toggleCheck}
                onClick={stopPropagation}
              />
              <span className="checkmark" onClick={stopPropagation} />
            </label>
            <ShapeIcon shape={shape} />
            <span
              className="label ellipsis flex-grow-1"
              onDoubleClick={() => {
                toggleEdit(!isEdit);
                setName(shape.layerName || shape.name);
              }}
            >
              {shape.layerName || shape.name}
            </span>
            <img
              src={shape.listening ? lockOpenIcon : lockClosedIcon}
              alt="icon"
              title={shape.listening ? "Unlocked" : "Locked"}
              className="lock_icon cursor-pointer mr-2"
              onClick={toggleLock}
            />
            <img
              src={
                shape.visible || shape.visible === undefined
                  ? showEyeIcon
                  : hideEyeIcon
              }
              title={shape.visible ? "show" : "hide"}
              alt="icon"
              className="eye_icon cursor-pointer"
              onClick={toggleHide}
            />
          </Fragment>
        )}
        {isEdit && (
          <Fragment>
            <input
              type="text"
              value={name}
              className="layer_edit flex-grow-1"
              onChange={e => {
                setName(e.target.value);
              }}
            />
            <div className="layer_action_icon ml-2" onClick={setLayerName}>
              <span role="img">&#x2714;</span>
            </div>
            <div className="layer_action_icon ml-2" onClick={resetLayerName}>
              <span role="img">&#10060;</span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

const ShapeIcon = ({ shape }) => {
  const type = (shape.Type || shape.type).toLowerCase();
  switch (type) {
    case "rect":
      return <img src={rectangleIcon} alt="icon" className="shape_icon mr-2" />;

    case "circle":
      return <img src={circleIcon} alt="icon" className="shape_icon mr-2" />;

    case "ellipse":
      return <img src={ellipseIcon} alt="icon" className="shape_icon mr-2" />;

    case "triangle":
      return <img src={triangleIcon} alt="icon" className="shape_icon mr-2" />;

    case "pentagon":
      return <img src={pentagonIcon} alt="icon" className="shape_icon mr-2" />;

    case "hexagon":
      return <img src={hexagonIcon} alt="icon" className="shape_icon mr-2" />;

    case "star":
      return <img src={starIcon} alt="icon" className="shape_icon mr-2" />;

    case "text":
      return <img src={textIcon} alt="icon" className="shape_icon mr-2" />;

    case "image":
      return <img src={imageIcon} alt="icon" className="shape_icon mr-2" />;

    case "regularpolygon":
      /**
       * Already created templates have this type name for trangle/pentagon/hexagon
       */
      return <img src={triangleIcon} alt="icon" className="shape_icon mr-2" />;

    default:
      return null;
  }
};
export default LayerCard;
