/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect } from "react";
import Dropdown from "react-dropdown";
import "./subscriptions.scss";
import $ from "jquery";

function Package({ plan, handlePayVia, cancelSubscribedPlan }) {
  const [payVia, setPayVia] = useState(null);
  useEffect(() => {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip("hide");
      $('[data-toggle="tooltip"]').tooltip();
    });
  });

  const payOptions = [
    { value: "", label: "Select pay via" },
    { value: "online", label: "Online payment" },
    { value: "offline", label: "Offline payment" }
  ];

  const getPlanDescription = categories => {
    const list = categories.map(item => item.name);
    return list.join(" & ");
  };

  const getPlanCost = amount => {
    let cost = amount.replace(/[,$]/g, "");
    cost = parseInt(cost);
    return cost ? `$ ${amount}` : "Free trial";
  };

  const getPlanDuration = type => {
    switch (type) {
      case "weeks":
        return "week";
      case "months":
        return "month";
      case "years":
        return "year";
      default:
        break;
    }
  };

  const getPriceTag = plan => {
    return `${getPlanCost(plan.plan_cost)} / ${getPlanDuration(
      plan.recurring_type
    )}`;
  };

  const offlineMessage =
    "For offline payment, kindly contact administrator for approval";
  const upgradeMessage =
    "If you upgrade a plan, current subscription will be overwritten";

  return (
    <div className="subscription_card" key={plan.id}>
      <div
        style={payVia && payVia.value ? { transform: "rotateX(180deg)" } : {}}
        className={`flip-card-inner flip`}
      >
        {/*
        ${["order now", "upgrade", "renew", "pay online"].includes(plan.action)? "flip": ""}
        ${[("active", "expired")].includes(plan.status) ? "active" : ""}
        */}
        <div className="flip-card-front">
          <div className="title pb-3">{plan.plan_name}</div>
          <div className="description pb-3">
            {getPlanDescription(plan.sports_type)}
          </div>
          {plan.status ? <div className="status_tag">{plan.status}</div> : null}
          <div className="tag price_tag">{getPriceTag(plan)}</div>
        </div>
        <div className="flip-card-back">
          {plan.action &&
            (plan.action.includes("order now") ||
              plan.action.includes("upgrade") ||
              plan.action.includes("renew") ||
              plan.action.includes("re-activate")) && (
              <Fragment>
                <h6>
                  Pay via:
                  <i
                    className="fa fa-info-circle pl-2"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={offlineMessage}
                  />
                </h6>

                <div className="mb-4">
                  <Dropdown
                    placeholder="Select Payment way"
                    options={payOptions}
                    value={payVia}
                    onChange={setPayVia}
                    placeholderClassName="placeholder"
                  />
                </div>
                <button
                  data-toggle="tooltip"
                  data-placement="right"
                  title={plan.action.includes("upgrade") ? upgradeMessage : ""}
                  className="btn btn-orange"
                  disabled={!payVia || (payVia && !payVia.value)}
                  onClick={() => {
                    const via = payVia.value;
                    setPayVia(null);
                    handlePayVia(plan, via);
                  }}
                >
                  {plan.action[0]}
                </button>
                {payVia && payVia.value === "offline" && (
                  <label className="pt-2 offline_message">
                    {offlineMessage}
                  </label>
                )}
              </Fragment>
            )}
          {plan.action.includes("pay online") && (
            <button
              className="btn btn-orange mb-4"
              onClick={() => {
                handlePayVia(plan, "online");
              }}
            >
              Pay online
            </button>
          )}
          {plan.action.includes("stop renewal") && (
            <Fragment>
              <button
                data-toggle="tooltip"
                data-placement="right"
                title="If you stop renewal, auto debit will turned off"
                className="btn btn-orange mb-4"
                onClick={() => {
                  // stop renewal api
                  handlePayVia(plan, "stop renewal");
                }}
              >
                stop renewal
              </button>
            </Fragment>
          )}
          {plan.action.includes("cancel") && (
            <Fragment>
              <button
                data-toggle="tooltip"
                data-placement="right"
                title="If you cancel a plan, your subscribed templates will not be availbale to you"
                className="btn btn-orange"
                onClick={() => {
                  cancelSubscribedPlan(plan);
                }}
              >
                Cancel
              </button>
            </Fragment>
          )}
          {plan.action.includes("activate free") && (
            <button
              className="btn btn-orange"
              onClick={() => {
                handlePayVia(plan, "offline");
              }}
            >
              Order Now
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Package;
