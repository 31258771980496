import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getInstaSocialProfiles,
  getInstaSuccessCB,
  removeInstaSocialAccount
} from "../../../redux/actions/auth";
import "./socialConnect.scss";
import GModal from "../../components/modal/GModal";

const SocialConnect = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [agree, setAgree] = useState(false);
  const dispatch = useDispatch();
  const Profiles = useSelector(state => state.socialAuth.profiles);

  const redirectToInstagram = () => {
    const url = `${window.location.origin}/my-profile`;
    return (window.location.href = `https://www.facebook.com/v3.2/dialog/oauth?response_type=code&redirect_uri=${encodeURIComponent(url)}&scope=public_profile%2Cinstagram_content_publish%2Cinstagram_manage_comments%2Cinstagram_basic%2Cpages_show_list&client_id=679472346634770`);
  };

  const openAuthorize = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    dispatch(getInstaSocialProfiles());
    const queryparams = history.location.search;
    if (queryparams) {
      const query = new URLSearchParams(queryparams);
      const code = query.get("code");
      try {
        dispatch(
          getInstaSuccessCB(code, response => {
            if (response && response.status) {
              dispatch(getInstaSocialProfiles());
              const previousPath = localStorage.getItem("previous-page");
              if (queryparams) {
                history.replace({
                  search: ""
                });
              }
              if (previousPath) {
                history.push(previousPath);
                localStorage.removeItem("previous-page");
              }
            }
          })
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Error =>", error);
      }
    }
  }, []);

  const logOutOfInsta = () => {
    const profile_id =
      Profiles && Profiles.Profiles[0] ? Profiles.Profiles[0].profile_id : "";
    if (profile_id) {
      const data = {
        profile_id: profile_id
      };
      try {
        dispatch(
          removeInstaSocialAccount(data, response => {
            if (response && response.status) {
              dispatch(getInstaSocialProfiles());
            }
          })
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Error =>", error);
      }
    }
  };

  return (
    <>
      <div className="social-wrapper">
        <div className="ig social-card">
          <i className="fa fa-instagram" aria-hidden="true" />
          {Profiles && Profiles.status && Profiles.Profiles ? (
            <h3>{Profiles.Profiles[0].account_name}</h3>
          ) : (
            <h5 className="add-social-account" onClick={openAuthorize}>
              <span>Add account</span>
            </h5>
          )}
          {Profiles && Profiles.status && Profiles.Profiles && (
            <h5 className="remove-social-account" onClick={logOutOfInsta}>
              <span>Remove account</span>
            </h5>
          )}
        </div>
      </div>
      <GModal isOpen={isOpen} variant={"md"} isClose={false} backdrop={true}>
        <div className="inform-container">
          <h3>Connect to Instagram</h3>
          <p>
            First, you'll need to connect your Instagram account to Facebook
            page and ensure it's set as a Business account.
          </p>
          <ul>
            <li>
              Open Facebook and navigate to your organization's Facebook page
            </li>
            <li>Select Settings from the left menu</li>
            <li>Select Instagram</li>
            <li>
              To add an Instagram account to your Page, select 'Connect Account,
              Make sure the "Allow access to, Instagram messages in Inbox' is
              toggled on and then click Continue. Enter your Instagram account's
              Username and Password, and select Log in.
            </li>
            <li>
              Once you connect the account you may get an option to "Switch to a
              Business Profile". This is required to allow apps like Box Out
              Sports to post to your account, and also includes insights,
              promotions and add more administrative features to your account.
            </li>
            <li>
              Once your Instagram account is connected to a Facebook Page and
              setup asa business account, you'll need to authorize your
              instagram account for Box Out Sports to use it using the button
              below.
            </li>
          </ul>
          <p>
            <b>Important:</b> When going through the authorization process, you
            need to give access to both the Instagram account and the connected
            page
          </p>
          <div className="enable-agreement">
            <label>
              <input
                type="checkbox"
                name="agree"
                id="agree"
                onClick={e => setAgree(e.target.checked)}
              />
              <span>Agree</span>
            </label>
          </div>
          <button
            type="button"
            disabled={!agree}
            onClick={redirectToInstagram}
            className="authorization-button"
          >
            Authorize Instagram Account
          </button>
        </div>
      </GModal>
    </>
  );
};

export default withRouter(SocialConnect);
