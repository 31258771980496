/* eslint-disable no-unused-vars */
import React from "react";
import rectangleIcon from "../../../../assets/img/rectangle.png";
import circleIcon from "../../../../assets/img/circle.png";
import ellipseIcon from "../../../../assets/img/oval.png";
import triangleIcon from "../../../../assets/img/triangle.png";
import pentagonIcon from "../../../../assets/img/pentagon.png";
import hexagonIcon from "../../../../assets/img/hexagon.png";
import starIcon from "../../../../assets/img/star.png";
import textIcon from "../../../../assets/img/text.png";
import lineIcon from "../../../../assets/img/line.png";
import imageIcon from "../../../../assets/img/image.png";
import { FaDrawPolygon } from "react-icons/fa";

import {
  rect,
  circle,
  ellipse,
  triangle,
  pentagon,
  hexagon,
  star,
  text
} from "../shapes";

export default function SideMenubar(props) {
  // console.log(">>>>>>>>>editor Menubar>>>>>>>>>");
  return (
    <div className="shortcut-menu d-none d-lg-block">
      {props.isAdmin && (
        <>
          <div className="pb-3">
            <img
              src={rectangleIcon}
              alt="rect"
              className="cursor-pointer"
              onClick={() => {
                props.addShape(rect);
              }}
            />
          </div>
          <div className="pb-3">
            <img
              src={circleIcon}
              alt="cir"
              className="cursor-pointer"
              onClick={() => {
                props.addShape(circle);
              }}
            />
          </div>
          <div className="pb-3">
            <img
              style={{ width: "15px" }}
              src={ellipseIcon}
              alt="cir"
              className="cursor-pointer"
              onClick={() => {
                props.addShape(ellipse);
              }}
            />
          </div>
          <div className="pb-3">
            <img
              src={triangleIcon}
              alt="triangle"
              className="cursor-pointer"
              onClick={() => {
                props.addShape(triangle);
              }}
            />
          </div>
          <div className="pb-3">
            <img
              src={pentagonIcon}
              alt="trianpentagongle"
              className="cursor-pointer"
              onClick={() => {
                props.addShape(pentagon);
              }}
            />
          </div>
          <div className="pb-3">
            <img
              src={hexagonIcon}
              alt="triangle"
              className="cursor-pointer"
              onClick={() => {
                props.addShape(hexagon);
              }}
            />
          </div>
          <div className="pb-3">
            <img
              src={starIcon}
              alt="triangle"
              className="cursor-pointer"
              onClick={() => {
                props.addShape(star);
              }}
            />
          </div>
          {/* <div className="pb-3">
        <img
          src={lineIcon}
          alt="line_icon"
          className="cursor-pointer"
          onClick={() => {
            props.addShape(line);
          }}
        />
      </div> */}
          <div className="pb-3">
            <FaDrawPolygon
              size={20}
              className="cursor-pointer"
              color={props.isCustomShape ? "#ff8501" : "#757678"}
              onClick={props.toggleCustomShape}
            />
          </div>
        </>
      )}
      <div className="pb-3">
        <img
          src={textIcon}
          alt="text_icon"
          className="cursor-pointer"
          onClick={() => {
            props.addShape(text);
          }}
        />
      </div>
      <div className="pb-3">
        <img
          src={imageIcon}
          alt="image_icon"
          className="cursor-pointer"
          onClick={() => {
            props.toggleImport();
          }}
        />
      </div>
    </div>
  );
}
