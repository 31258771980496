/* eslint-disable no-console */
import React from "react";
import { connect } from "react-redux";
import Konva from "konva";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  getTemplate,
  createOrUpdateTemplate,
  getCanvasPresets,
  changePublishStatus
} from "../../../redux/actions/templates";
import {
  getSavedLibrary,
  createOrUpdateSavedLibrary
} from "../../../redux/actions/savedLibraries";
import EditorHeader from "./components/EditorHeader";
import SideMenubar from "./components/SideMenubar";
import GCanvas from "./components/GCanvas";
import GModal from "../../components/modal/GModal";
import ImportImage from "./components/ImportImage";
import EditorSidebar from "./components/EditorSidebar";
import "./editor.scss";
import { undoRedo } from "../../../utils/undoRedo";
import BottomMenubar from "./components/BottomMenubar";
import CreateTemplate from "./components/CreateTemplate";
import { showLoader, hideLoader } from "../../../redux/reducers/loader";
import { image, customPolygon } from "./shapes";
import { groupBy, roundOff } from "../../../utils/utils";
import DigitalOceanSpaces from "../../../redux/services/digitalOceanSpaces.js";
import { uploadFileToSpace } from "../../../redux/services/spaces";
import { Prompt } from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";
import HeraldToastify, {
  ICONS
} from "../../components/HeraldToastify/HeraldToastify";
import AnimatedSidebar from "../../components/AnimatedSidebar/AnimatedSidebar";
import AnimationList from "./components/AnimationList/AnimationList";
import AnimationPreview from "./components/AnimationList/animationPreview";
import DurationProgressBar from "./components/DurationProgressBar";

const SIZE_LIST = {
  sm: 480,
  md: 960,
  lg: 1920,
  xl: 3840
};

class Editor extends React.Component {
  isNewTemplate = false;
  isChanged = false;
  isSaved = false;

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      user_id: null,
      name: "",
      colors: undoRedo(["", "", "", ""]),
      shapes: undoRedo([]),
      group: null,
      published: false,
      preview_url: null,
      sizeList: {
        sm: "",
        md: "",
        lg: "",
        xl: "",
        mp4: ""
      },
      dimensions: [20, 20],
      created_at: null,
      updated_at: null,

      initialScale: 1,
      scale: 1,
      width: 978,
      height: 550,
      isPointerSelect: false,
      pointerPos: null,
      stagePos: {
        x: 0,
        y: 0
      },
      isOpen: false,
      isCreateModalOpen: false,
      selectedShapeIndex: null,
      previousIndex: null,
      currentIndex: null,
      isSidebarOpen: true,
      isCustomShape: false,
      points: [],
      isAnimationOpen: false,
      animations: [],
      animationDuration: 0,
      isPlay: false,
      blob: null,
      videoPreviewUrl: "",
      isOpenPreview: false,
      loadRender: false
    };
  }
  componentDidMount() {
    const { match, canvasPresets, user, isAdmin } = this.props;
    if (match.params.id !== undefined) {
      this.getTemplateById();
    } else {
      this.isNewTemplate = true;
      this.toggleCreateModal();
    }

    if (canvasPresets.length === 0 && isAdmin) {
      this.props.getCanvasPresets();
    }

    if (user) {
      this.setState({
        user_id: user.id
      });
    }
  }

  getTemplateById() {
    const { isAdmin, match, teamColors } = this.props;
    if (isAdmin) {
      this.props.getTemplate(match.params.id, async res => {
        /**
         * Get layers from digital ocean spaces using upload url
         */
        const data = res.data[0];
        if (!data) {
          return;
        }
        const layerUrl = data.layer_url ? data.layer_url.split("?")[0] : "";
        const digitalOcean = new DigitalOceanSpaces(layerUrl);

        this.props.showLoader();
        const layers = await digitalOcean.getFileFromSpace();
        this.props.hideLoader();

        const layerShapes =
          layers && layers[0] && layers[0].shapes ? layers[0].shapes : [];

        const animations =
          layers && layers[0] && layers[0].animations
            ? layers[0].animations
            : [];
        data.animations = this.convertedAnimations(animations);

        const formatted = layerShapes.map(shape =>
          shape.id ? shape : { ...shape, id: uuidv4() }
        );

        this.loadShapesAfterFontLoaded(formatted, data);
      });
    } else {
      this.props.getSavedLibrary(match.params.id, async res => {
        /**
         * Get layers from digital ocean spaces using upload url
         */

        const data = res.data[0];
        if (!data) {
          return;
        }
        const layerUrl = data.layer_url ? data.layer_url.split("?")[0] : "";
        const digitalOcean = new DigitalOceanSpaces(layerUrl);

        this.props.showLoader();
        const layers = await digitalOcean.getFileFromSpace();
        this.props.hideLoader();

        const layerShapes =
          layers && layers[0] && layers[0].shapes ? layers[0].shapes : [];
        const animations =
          layers && layers[0] && layers[0].animations
            ? layers[0].animations
            : [];
        data.animations = this.convertedAnimations(animations);

        if (data.isnewtemplate) {
          data.colors = teamColors;
        }

        const resetColorShapes = this.resetToUserColors(layerShapes, data);
        const formatted = resetColorShapes.map(shape =>
          shape.id ? shape : { ...shape, id: uuidv4() }
        );

        this.loadShapesAfterFontLoaded(formatted, data);
      });
    }
  }

  resetToUserColors = (shapes, { isnewtemplate }) => {
    const { teamColors } = this.props;
    if (isnewtemplate) {
      /** Set template as changed, because we changed colors based on template colors after use template */
      this.isChanged = true;
      const changedShapes = shapes.map(shape => {
        if (shape.colorGroup >= 0) {
          return {
            ...shape,
            fill: teamColors[shape.colorGroup] || shape.fill
          };
        }
        return shape;
      });
      return changedShapes;
    }
    return shapes;
  };

  loadShapesAfterFontLoaded = (layerShapes, data) => {
    const fonts = new Set();

    layerShapes.forEach(shape => {
      if (shape.type === "text") {
        fonts.add(shape.fontFamily);
      }
    });
    const observers = [...fonts]
      .filter(font => !!font)
      .map(font => {
        const fontFaceObserver = new FontFaceObserver(font);
        return fontFaceObserver.load();
      });

    Promise.all(observers)
      .then(() => {
        data.shapes = layerShapes;
        this.setTemplateData(data);
      })
      .catch(() => {
        data.shapes = layerShapes;
        this.setTemplateData(data);
      });
  };

  setStageRef = node => {
    this.stage = node;
  };

  getStageRef = () => {
    return this.stage;
  };

  setTemplateData = template => {
    const { colors, shapes, animations } = this.state;
    this.setState({
      id: template.id,
      name: template.name,
      colors: template.colors ? undoRedo(template.colors) : colors,
      shapes: template.shapes ? undoRedo(template.shapes) : shapes,
      animations: template.animations || animations || [],
      group: template.sports_type_id,
      published: template.published,
      preview_url: template.preview_url,
      user_id: template.user_id,
      sizeList: {
        sm: template.download_1x_url,
        md: template.download_2x_url,
        lg: template.download_3x_url,
        xl: template.download_4x_url,
        mp4: template.download_video_url
      },
      dimensions: template.dimensions,
      created_at: template.created_at || "",
      updated_at: template.updated_at || ""
    });
  };

  get4XPixelratio = () => {
    const { width } = this.stage.size();
    const exportWidth = SIZE_LIST["xl"];
    const pixelRatio = exportWidth / width;
    return pixelRatio;
  };

  handleSave = async onSuccess => {
    const {
      id,
      user_id,
      name,
      colors: { present: colors },
      shapes: { present: shapes },
      group,
      published,
      dimensions,
      selectedShapeIndex,
      animations
    } = this.state;

    /**
     * Deselect the shape if selected before save
     */
    if (selectedShapeIndex != null) {
      this.setState({
        selectedShapeIndex: null
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    let pixelRatio = this.get4XPixelratio();
    let stage = this.stage;
    let dataURL = stage.toDataURL({
      pixelRatio,
      mimeType: "image/jpeg",
      quality: 1
    });

    const templateID = id || uuidv4();
    const template = {
      TemplateId: templateID,
      name: name || "New template",
      colors: [...colors],
      layers: [
        {
          shapes: [...shapes],
          animations: this.state.animations
        }
      ],
      sports_type_id: group,
      published: published,
      preview: dataURL,
      download_video_url: null,
      dimensions: dimensions
    };

    if (animations.length) {
      template.download_video_url = `GoEditDevTest/${user_id}/Templates/${templateID}_download_video_url.mp4`;
    }

    /*
     * If animation is present then do below steps
     * Play animation and make it record then upload to DO spaces
     */

    const successMsg = `Template ${id ? "updated" : "created"} successfully`;
    if (this.props.isAdmin) {
      this.props.createOrUpdateTemplate({
        template,
        cb: data => {
          Object.keys(data).length > 0 && this.setTemplateData(data);
          this.isSaved = true;
          this.isChanged = false;
          this.setInitial();
          onSuccess && onSuccess(data);
          toast.success(
            <HeraldToastify icon={ICONS.success} title={successMsg} />
          );
          this.handlePlayAnimation(true);
        }
      });
    } else {
      this.props.createOrUpdateSavedLibrary({
        template,
        cb: data => {
          this.setTemplateData(data);
          this.isSaved = true;
          this.isChanged = false;
          this.setInitial();
          onSuccess && onSuccess(data);
          toast.success(
            <HeraldToastify icon={ICONS.success} title={successMsg} />
          );
          this.handlePlayAnimation(true);
        }
      });
    }
  };

  updateName = event => {
    this.isChanged = true;
    const value = event.target.value;
    this.setState({
      name: value
    });
  };

  updateStatus = () => {
    this.setState({ published: !this.state.published }, () => {
      const { id, published } = this.state;
      if (id) {
        const template = {
          TemplateId: id,
          published: published
        };
        this.props.changePublishStatus({ template });
      }
    });
  };

  setSelectedShape = index => {
    const { previousIndex, currentIndex } = this.state;

    if (previousIndex !== null && currentIndex !== null) {
      return;
    }
    this.setState({
      selectedShapeIndex: index
    });
  };

  setShapes = updatedShapes => {
    const { shapes } = this.state;
    shapes.set(updatedShapes);

    return { ...shapes };
  };

  getPresentShapes = () => {
    const { present } = this.state.shapes;
    return present;
  };

  undo = () => {
    const { shapes, colors } = this.state;
    shapes.undo();
    colors.undo();

    this.setState({
      shapes: { ...shapes },
      colors: { ...colors }
    });
  };

  redo = () => {
    const { shapes, colors } = this.state;
    shapes.redo();
    colors.redo();

    this.setState({
      shapes: { ...shapes },
      colors: { ...colors }
    });
  };

  reset = () => {
    const { shapes, colors } = this.state;
    shapes.reset();
    colors.reset();

    this.setState({
      shapes: { ...shapes },
      colors: { ...colors }
    });
  };

  setInitial = () => {
    const { shapes } = this.state;
    shapes.setInit();

    this.setState({
      shapes: { ...shapes }
    });
  };

  addShape = (newShape, isRandomPosition = true) => {
    let { width: STAGE_WIDTH, height: STAGE_HEIGHT, initialScale } = this.state;
    STAGE_WIDTH = STAGE_WIDTH / initialScale;
    STAGE_HEIGHT = STAGE_HEIGHT / initialScale;
    const x =
      newShape.type === "image"
        ? newShape.x
        : Math.random() * (STAGE_WIDTH - 100);
    const y =
      newShape.type === "image"
        ? newShape.y
        : Math.random() * (STAGE_HEIGHT - 100);

    if (isRandomPosition) {
      /**
       * Add shape dynamically within given stage(x,y)
       */
      if (newShape) {
        const shape = {
          ...newShape,
          x: parseInt(x),
          y: parseInt(y),
          id: uuidv4()
        };
        /**
         * Add shape with konva random color
         */
        this.addShapeWithRandomColor(shape);
      }
    } else {
      if (newShape) {
        const shape = {
          ...newShape,
          id: uuidv4()
        };
        /**
         * Add shape with konva random color
         */
        this.addShapeWithRandomColor(shape);
      }
    }
  };

  addShapeWithRandomColor = shape => {
    /**
     * Add shape with konva random color
     */
    if (shape.type !== "text" && shape.type !== "image") {
      shape.fill = Konva.Util.getRandomColor();
    }
    if (shape.type !== "image") {
      /**
       * Add colorGroup to shape
       */
      shape.colorGroup = this.getColorGroup(shape.fill);
    }

    const { shapes } = this.state;
    const clonedShapes = shapes.present.slice();
    shape.name = `${shape.type || shape.Type}_${clonedShapes.length + 1}`;
    clonedShapes.push(shape);
    const updated = shapes.set(clonedShapes);
    this.setState({
      shapes: updated
    });
  };

  getColorGroup = fillColor => {
    const {
      colors: { present: colors }
    } = this.state;
    let index = -1;
    colors.some((color, i) => {
      const status = color.startsWith(fillColor);
      if (status) {
        index = i;
        return true;
      }
    });
    return index;
  };

  removeShape = index => {
    const { shapes } = this.state;
    const clonedShapes = shapes.present.slice();
    const removedShapes = clonedShapes.splice(index, 1);
    const updated = shapes.set(clonedShapes);
    this.setState({
      shapes: updated,
      selectedShapeIndex: null
    });
    this.removeAnimationItem(removedShapes[0].id);
  };

  handleReplace = ({ src, scale }) => {
    const { shapes, selectedShapeIndex, width, height } = this.state;
    const clonedShapes = shapes.present.slice();
    // console.log("PREV IMAGE", clonedShapes[selectedShapeIndex]);
    const cb = value => {
      const updatedNode = {
        ...image,
        ...clonedShapes[selectedShapeIndex],
        src: src,
        width: null,
        height: null,
        scaleX: value,
        scaleY: value
      };
      this.setState({ selectedShapeIndex: null }, () => {
        this.updateShapeAttrs(updatedNode, selectedShapeIndex);
      });
    };

    if (scale) {
      cb(scale);
      return;
    }

    this.props.showLoader();
    var imageObj = new Image();
    imageObj.src = src;
    const that = this;
    /**
     * Check image width/height and scale down to canvas width/height ratio
     */
    imageObj.onload = async function() {
      let scale = 1;
      const IWidth = this.width;
      const IHeight = this.height;
      if (IWidth > width || IHeight > height) {
        scale = Math.min(width / IWidth, height / IHeight);
      }
      console.log(IWidth, IHeight, ">>>>>>>>>>>>>", scale);
      cb(scale);
      that.props.hideLoader();
    };
  };

  updateShapeAttrs = (updatedNode, index) => {
    const { shapes } = this.state;
    const clonedShapes = shapes.present.slice();
    clonedShapes.splice(index, 1, { ...updatedNode });
    const updated = shapes.set(clonedShapes);
    this.setState({
      shapes: updated
    });
  };

  moveUp = index => {
    const { shapes } = this.state;
    const clonedShapes = shapes.present.slice();
    const lastIndex = clonedShapes.length - 1;
    clonedShapes.move(index, lastIndex);
    const updated = shapes.set(clonedShapes);

    this.setState({
      previousIndex: index,
      currentIndex: lastIndex,
      selectedShapeIndex: lastIndex,
      shapes: updated
    });
  };

  resetToOriginal = () => {
    const { previousIndex, currentIndex } = this.state;

    if (previousIndex !== null && currentIndex !== null) {
      const { shapes } = this.state;
      const clonedShapes = shapes.present.slice();
      clonedShapes.move(currentIndex, previousIndex);
      const updated = shapes.set(clonedShapes);
      this.setState({
        shapes: updated,
        previousIndex: null,
        currentIndex: null,
        selectedShapeIndex: null
      });
    }
  };

  updateColor = (newColor, index) => {
    const { colors } = this.state;
    const list = colors.present.slice();
    if (list.indexOf(newColor) === -1) {
      list.splice(index, 1, newColor);
      const updatedColors = colors.set(list);
      this.setState({
        colors: updatedColors
      });
      this.updateShapeColors(index, newColor);
    }
  };

  updateShapeColors = (index, newColor) => {
    const { shapes } = this.state;
    const clonedShapes = shapes.present.slice();
    const list = clonedShapes.map(shape => {
      if (shape.colorGroup === index) {
        return { ...shape, fill: newColor };
      }
      return shape;
    });
    const updated = shapes.set(list);
    this.setState({
      shapes: updated
    });
  };

  toggleImport = (isReplace = false) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isReplace: isReplace
    });
  };

  toggleSidebar = () => {
    this.setState({
      isSidebarOpen: !this.state.isSidebarOpen
    });
  };

  updateLayer = cards => {
    const { shapes } = this.state;
    const updated = shapes.set(cards);
    this.setState({
      shapes: updated
    });
  };

  toggleCreateModal = () => {
    this.setState({ isCreateModalOpen: !this.state.isCreateModalOpen });
  };

  onEditCanvas = () => {
    this.setState({
      isCreateModalOpen: true,
      isEditCanvas: true
    });
  };

  handleImageReplace = index => {
    this.setState({
      selectedShapeIndex: index,
      isOpen: !this.state.isOpen,
      isReplace: true
    });
  };

  handleStart = ({ group, colors, dimensions }) => {
    colors = colors ? undoRedo(colors) : this.state.colors;
    this.setState({
      group: group,
      colors: colors,
      dimensions: dimensions
    });
  };

  onCancel = () => {
    this.toggleCreateModal();
    setTimeout(() => {
      this.props.history.goBack();
    }, 0);
  };

  goBack = () => {
    if (this.props.isAdmin) {
      this.props.history.goBack();
    }
  };

  setPointerPosition = pos => {
    // console.log("Set Pointer position >>>>>>>>>", pos);
    this.setState({
      pointerPos: pos
    });
  };

  togglePointerSelect = () => {
    const { width, height, isPointerSelect, stagePos } = this.state;
    this.setState({
      isPointerSelect: !isPointerSelect,
      stagePos: isPointerSelect ? { x: 0, y: 0 } : stagePos, // set stagePos to {0,0} while deselect
      pointerPos: {
        x: width / 2,
        y: height / 2
      }
    });
  };

  handleMouseWheel = e => {
    e.evt.preventDefault();
    const { scale: oldScale, isPointerSelect } = this.state;
    if (isPointerSelect) {
      const newScale = e.evt.deltaY > 0 ? oldScale + 0.01 : oldScale - 0.01;
      this.handleZoom(newScale);
    }
  };

  handleZoom = newScale => {
    const {
      initialScale,
      scale: oldScale,
      pointerPos,
      isPointerSelect
    } = this.state;
    const stage = this.stage;
    const pointer = pointerPos || stage.getPointerPosition();
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale
    };

    if (newScale >= initialScale - 0.5 && newScale <= initialScale + 0.5) {
      let newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale
      };
      const diff = newScale - initialScale;
      if (-0.01 <= diff === diff <= 0.01 && !isPointerSelect) {
        // If diff in old scaling is b/w -0.01 and 0.01, means then stagepos set to {0, 0}, else it slightly misaligned
        newPos = {
          x: 0,
          y: 0
        };
      }
      this.setState({
        scale: newScale,
        stagePos: newPos,
        pointerPos: pointerPos || pointer
      });
    }
  };

  toggleCustomShape = () => {
    const { isCustomShape } = this.state;
    if (isCustomShape) {
      this.addCustomShape();
    }
    this.setState({
      isCustomShape: !isCustomShape,
      points: []
    });
  };

  resetPoints = () => {
    this.setState({
      isCustomShape: false,
      points: []
    });
  };

  addCustomShape = () => {
    const { points } = this.state;
    const linePoints = points.map(({ x, y }) => [x, y]).flat();
    const shape = {
      ...customPolygon,
      points: linePoints,
      id: uuidv4()
    };
    this.addShapeWithRandomColor(shape);
  };

  setPoints = () => {
    const { points, initialScale } = this.state;
    const { x, y } = this.stage.getPointerPosition();
    const pos = {
      x: roundOff(x / initialScale, 0),
      y: roundOff(y / initialScale, 0)
    };
    const clonedPoints = points.slice();
    clonedPoints.push({ ...pos });
    this.setState({
      points: clonedPoints
    });
  };

  removePoints = () => {
    const clonedPoints = this.state.points.slice();
    clonedPoints.pop();
    this.setState({
      points: clonedPoints
    });
  };

  setCanvasDimensions = ([width, height]) => {
    this.setState({
      width,
      height,
      pointerPos: {
        x: width / 2,
        y: height / 2
      }
    });
  };

  setCanvasScaling = scale => {
    this.setState({
      initialScale: scale,
      scale: scale
    });
  };

  toggleAnimatedSidebar = () => {
    this.setState({
      isAnimationOpen: !this.state.isAnimationOpen
    });
  };

  addAnimation = newAnimation => {
    const { animations } = this.state;
    animations.push(newAnimation);
    this.isChanged = true;
    const converted = this.convertedAnimations(animations);
    this.setState({
      animations: converted
    });
  };

  removeAnimation = index => {
    const { animations } = this.state;
    this.isChanged = true;
    animations.splice(index, 1);
    const converted = this.convertedAnimations(animations);
    this.setState({
      animations: converted
    });
  };

  removeAnimationItem = id => {
    const { animations } = this.state;
    this.isChanged = true;
    const filterAnimations = animations.filter(item => item.shapedId !== id);
    const converted = this.convertedAnimations(filterAnimations);
    this.setState({
      animations: converted
    });
  };

  handleAnimationChange = (updatedAnimation, index) => {
    const { animations } = this.state;
    this.isChanged = true;
    animations.splice(index, 1, updatedAnimation);
    const converted = this.convertedAnimations(animations);
    this.setState({
      animations: converted
    });
  };

  convertedAnimations = animations => {
    if (!animations.length) return [];
    animations[0].delay = 0;
    const converted = [];
    converted.push(animations[0]);
    for (let i = 1; i < animations.length; i++) {
      const current = animations[i];
      const previous = animations[i - 1];
      if (current.delayType === "withPrevious") {
        current.delay = previous.delay;
      } else {
        current.delay = previous.delay + previous.duration;
      }
      converted.push(current);
    }
    /* 
      While converting set max animation duration
    */
    const lastAnimation = converted.slice(-1)[0];
    const duration = lastAnimation.duration + lastAnimation.delay;
    this.setState({
      animationDuration: duration
    });
    return converted;
  };

  handlePlayAnimation = (isUpload = false) => {
    const { animations } = this.state;
    const lastAnimation = animations.slice(-1)[0];
    let duration = lastAnimation.duration + lastAnimation.delay;
    duration = duration + 3000;
    this.setState(
      { isPlay: true, loadRender: isUpload, selectedShapeIndex: null },
      () => {
        setTimeout(() => {
          this.setState({ isPlay: false, loadRender: false });
        }, duration);
      }
    );
    this.startRecording(duration, isUpload);
  };

  startRecording = (duration, isUpload) => {
    const chunks = [];
    const canvas = document.getElementsByTagName("canvas")[1];
    const stream = canvas.captureStream(); // grab our canvas MediaStream
    const rec = new MediaRecorder(stream); // init the recorder
    // every time the recorder has new data, we will store it in our array
    rec.ondataavailable = e => chunks.push(e.data);
    // only when the recorder stops, we construct a complete Blob from all the chunks
    rec.onstop = () => {
      const blob = new Blob(chunks, { type: "video/mp4" });
      this.exportVid(blob);
      isUpload && this.uploadVideoToSpaces(blob);
    };
    isUpload && this.props.showLoader();
    rec.start();
    setTimeout(() => {
      isUpload && this.props.hideLoader();
      rec.stop();
    }, duration + 250); // stop recording based on duration
  };

  uploadVideoToSpaces = blob => {
    /**
     * Load GoEditDevTest from env
     * below path syntax represents
     * GoEditDevTest/{userId}/Templates/{templateId}_download_video_url.webm
     */
    const { user_id, id } = this.state;
    const location = `GoEditDevTest/${user_id}/Templates/${id}_download_video_url.mp4`;
    this.props.showLoader();
    uploadFileToSpace({ location, data: blob }, (err, data) => {
      if (err) console.log(err, err.stack);
      else console.log(data);
      this.props.hideLoader();
    });
  };

  blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  exportVid = blob => {
    this.setState({
      blob,
      videoPreviewUrl: URL.createObjectURL(blob)
    });
  };

  handleDropAnimations = animations => {
    this.isChanged = true;
    const converted = this.convertedAnimations(animations);
    this.setState({ animations: converted });
  };

  handlePreviewAnimation = () => {
    this.setState({
      isOpenPreview: true
    });
  };

  togglePreviewClose = () => {
    this.setState({
      isOpenPreview: !this.state.isOpenPreview
    });
  };

  render() {
    const {
      id,
      name,
      shapes: { present: shapes, past, future },
      colors: { present: colors },
      published,
      sizeList,
      created_at,
      updated_at,
      isCreateModalOpen,
      isEditCanvas,
      dimensions,
      isOpen,
      isReplace,
      selectedShapeIndex,
      isSidebarOpen,
      initialScale,
      scale,
      width,
      height,
      stagePos,
      isPointerSelect,
      previousIndex,
      isCustomShape,
      points,
      isAnimationOpen,
      animations,
      isPlay,
      videoPreviewUrl,
      isOpenPreview,
      loadRender,
      animationDuration
    } = this.state;
    const { isAdmin, history } = this.props;
    // console.log(">>>>>>>>>editor>>>>>>>>>>>", shapes);
    const animatedByShapes = groupBy(animations, "shapedId");

    return (
      <div className="editor-container">
        <Prompt
          when={past.length > 0 || future.length > 0 || this.isChanged}
          message={() => `Your changes will be lost.Are you sure?`}
        />
        <EditorHeader
          history={history}
          id={id}
          name={name}
          published={published}
          template_preview={sizeList.md}
          dimensions={dimensions}
          updated_at={updated_at || created_at}
          selectedShapeIndex={selectedShapeIndex}
          setSelectedShape={this.setSelectedShape}
          updateName={this.updateName}
          updateStatus={this.updateStatus}
          isChanges={past.length > 0 || future.length > 0 || this.isChanged}
          isSaved={this.isSaved}
          videoPreviewUrl={videoPreviewUrl}
          handleSave={this.handleSave}
          // stage={this.stage}
          sizeList={sizeList}
        />
        <div className="editor-body">
          {/* style={{ height: "calc(100vh - 72px)", maxWidth: "100vw" }} */}
          <div className="flex-grow-1 d-flex flex-column">
            <div className="d-flex flex-grow-1">
              <SideMenubar
                toggleImport={this.toggleImport}
                addShape={this.addShape}
                toggleCustomShape={this.toggleCustomShape}
                isCustomShape={isCustomShape}
                isAdmin={isAdmin}
              />
              <GCanvas
                history={history}
                shapes={shapes}
                dimensions={dimensions}
                previousIndex={previousIndex}
                updateShapeAttrs={this.updateShapeAttrs}
                removeShape={this.removeShape}
                setSelectedShape={this.setSelectedShape}
                initialScale={initialScale}
                scale={scale}
                width={width}
                height={height}
                setCanvasDimensions={this.setCanvasDimensions}
                setCanvasScaling={this.setCanvasScaling}
                stagePos={stagePos}
                toggleImport={this.toggleImport}
                moveUp={this.moveUp}
                selectedShapeIndex={selectedShapeIndex}
                setStageRef={this.setStageRef}
                getStageRef={this.getStageRef}
                resetToOriginal={this.resetToOriginal}
                isPointerSelect={isPointerSelect}
                setPointerPosition={this.setPointerPosition}
                handleMouseWheel={this.handleMouseWheel}
                isCustomShape={isCustomShape}
                setPoints={this.setPoints}
                removePoints={this.removePoints}
                points={points}
                toggleCustomShape={this.toggleCustomShape}
                openAnimationSidebar={this.toggleAnimatedSidebar}
                resetPoints={this.resetPoints}
                animatedByShapes={animatedByShapes}
                isPlay={isPlay}
              />
            </div>
            <BottomMenubar
              isAdmin={isAdmin}
              undo={this.undo}
              redo={this.redo}
              reset={this.reset}
              toggleImport={this.toggleImport}
              addShape={this.addShape}
              isPointerSelect={isPointerSelect}
              togglePointerSelect={this.togglePointerSelect}
              canUndo={past.length > 0}
              canRedo={future.length > 0}
              initialZoom={initialScale}
              zoom={scale}
              handleZoom={this.handleZoom}
              canEditCanvas={this.isNewTemplate}
              onEditCanvas={this.onEditCanvas}
            />
          </div>
          {/* Device range above 992px */}
          <EditorSidebar
            cards={shapes}
            colors={colors}
            isOpen={isSidebarOpen}
            classNames="d-lg-flex d-none ml-auto"
            selectedShapeIndex={selectedShapeIndex}
            selectedShape={shapes[selectedShapeIndex]}
            stageWidth={this.stage ? this.stage.width() : 0}
            updateColor={this.updateColor}
            toggleSidebar={this.toggleSidebar}
            toggleImport={this.toggleImport}
            updateShapeAttrs={this.updateShapeAttrs}
            updateLayer={this.updateLayer}
            setSelectedShape={this.setSelectedShape}
            addShape={this.addShape}
            removeShape={this.removeShape}
            moveUp={this.moveUp}
            previousIndex={previousIndex}
            handleReplace={this.handleImageReplace}
            resetToOriginal={this.resetToOriginal}
            // clickToPlay={this.clickToPlay}
            // handleShapeAnimation={this.handleShapeAnimation}
            // getInitialAnimation={this.getInitialAnimation}
          />
          <AnimatedSidebar
            classNames="d-sm-none"
            isOpen={isAnimationOpen}
            toggleSidebar={this.toggleAnimatedSidebar}
          >
            <AnimationList
              animations={animations}
              isPlay={isPlay}
              allShapes={shapes}
              selectedShape={shapes[selectedShapeIndex]}
              videoPreviewUrl={videoPreviewUrl}
              addAnimation={this.addAnimation}
              removeAnimation={this.removeAnimation}
              handleAnimationChange={this.handleAnimationChange}
              handlePlayAnimation={() => this.handlePlayAnimation()}
              handleDropAnimations={this.handleDropAnimations}
              handlePreviewAnimation={this.handlePreviewAnimation}
              setSelectedShape={this.setSelectedShape}
            />
          </AnimatedSidebar>
        </div>
        <div className="d-lg-none d-block res-mobile-menu">
          <EditorSidebar
            cards={shapes}
            colors={colors}
            isOpen={isSidebarOpen}
            classNames="d-lg-none d-block"
            selectedShapeIndex={selectedShapeIndex}
            selectedShape={shapes[selectedShapeIndex]}
            stageWidth={this.stage ? this.stage.width() : 0}
            updateColor={this.updateColor}
            toggleSidebar={this.toggleSidebar}
            toggleImport={this.toggleImport}
            updateShapeAttrs={this.updateShapeAttrs}
            updateLayer={this.updateLayer}
            setSelectedShape={this.setSelectedShape}
            addShape={this.addShape}
            removeShape={this.removeShape}
            moveUp={this.moveUp}
            previousIndex={previousIndex}
            handleReplace={this.handleImageReplace}
            resetToOriginal={this.resetToOriginal}
            isAnimationOpen={isAnimationOpen}
            handleShapeAnimation={this.handleShapeAnimation}
            getInitialAnimation={this.getInitialAnimation}
          />
          <AnimatedSidebar
            isOpen={isAnimationOpen}
            toggleSidebar={this.toggleAnimatedSidebar}
          >
            <AnimationList
              animations={animations}
              isPlay={isPlay}
              allShapes={shapes}
              selectedShape={shapes[selectedShapeIndex]}
              videoPreviewUrl={videoPreviewUrl}
              addAnimation={this.addAnimation}
              removeAnimation={this.removeAnimation}
              handleAnimationChange={this.handleAnimationChange}
              handlePlayAnimation={() => this.handlePlayAnimation()}
              handleDropAnimations={this.handleDropAnimations}
              handlePreviewAnimation={this.handlePreviewAnimation}
              setSelectedShape={this.setSelectedShape}
            />
          </AnimatedSidebar>
        </div>
        <GModal
          isOpen={isOpen}
          onClose={this.toggleImport}
          isClose={false}
          variant="lg"
          backdrop={true}
        >
          {isOpen && (
            <ImportImage
              width={width}
              height={height}
              initialScale={initialScale}
              toggleImport={this.toggleImport}
              addShape={this.addShape}
              handleReplace={this.handleReplace}
              isReplace={isReplace}
            />
          )}
        </GModal>
        <GModal
          isOpen={isOpenPreview}
          onClose={this.togglePreviewClose}
          variant={"lg"}
          isClose={false}
          backdrop={true}
        >
          {isOpenPreview && (
            <AnimationPreview videoPreviewUrl={videoPreviewUrl} />
          )}
        </GModal>
        <CreateTemplate
          isOpen={isCreateModalOpen}
          onClose={this.toggleCreateModal}
          onCancel={this.onCancel}
          handleStart={this.handleStart}
          isEditCanvas={isEditCanvas}
        />
        {loadRender && isPlay ? (
          <DurationProgressBar loadDuration={animationDuration} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.auth.user_role.includes("SUPER_USER"),
  user: state.auth.user,
  teamColors: state.auth.user ? state.auth.user.colors : [],
  canvasPresets: state.template.canvasPresets
});

const mapDispatchToProps = {
  getTemplate: getTemplate,
  getSavedLibrary: getSavedLibrary,
  createOrUpdateTemplate: createOrUpdateTemplate,
  changePublishStatus: changePublishStatus,
  createOrUpdateSavedLibrary: createOrUpdateSavedLibrary,
  showLoader: showLoader,
  hideLoader: hideLoader,
  getCanvasPresets: getCanvasPresets
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Editor);
