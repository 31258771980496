import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteMyDesignsFolder,
  getMyDesignsFolderList,
  saveAndUpdateMyDesignsFolder
} from "../../../../redux/actions/savedLibraries";
import ConfirmPopup from "../../../components/confirmPopup/ConfirmPopup";
import HeraldToastify, {
  ICONS
} from "../../../components/HeraldToastify/HeraldToastify";
import AppInput from "../../../components/input/AppInput";
import GModal from "../../../components/modal/GModal";

import "../../templates/folders/folders.scss";

export class SavedLibraryFolders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      isOpen: false,
      isEdit: false
    };
  }

  componentDidMount() {
    this.getFolderList();
  }

  toggleCreate = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleEdit = folder => () => {
    this.setState({
      id: folder.id,
      name: folder.name,
      isOpen: true,
      isEdit: true
    });
  };

  handleChange = (e, key) => {
    const value = e.target.value;
    this.setState({
      [key]: value
    });
  };

  openFolder = id => () => {
    this.props.history.push(`/saved_libraries/${id || ""}`);
  };

  getFolderList = () => {
    this.props.getMyDesignsFolderList();
  };

  handleSubmit = () => {
    const { id, name } = this.state;
    const data = {
      id,
      name
    };
    this.props.saveAndUpdateMyDesignsFolder(data, () => {
      this.toggleCreate();
      this.getFolderList();
      toast.success(
        <HeraldToastify
          icon={ICONS.success}
          title={
            id ? "Folder updated successfully" : "Folder created successfully"
          }
        />
      );
    });
  };

  handleDeleteFolder = id => () => {
    const onYes = () => {
      this.props.deleteMyDesignsFolder(id, () => {
        this.getFolderList();
        toast.success(
          <HeraldToastify
            icon={ICONS.success}
            title="Folder deleted successfully"
          />
        );
      });
    };

    const options = {
      title: "Are you sure?",
      message: "This action will delete this folder!",
      buttons: [
        {
          label: "Yes, Delete it!",
          onClick: onYes
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmPopup onClose={onClose} {...options} />;
      }
    });
  };

  render() {
    const { name, isOpen, isEdit } = this.state;
    const { folders } = this.props;
    return (
      <div className="template-container px-xl-6 px-lg-5 px-md-5 px-sm-5 px-4">
        <h4 className="main-heading m-0">Saved library Folders</h4>

        <section className="row align-items-center pt-4 m-0 filter">
          {/* <div className="d-flex Poppins">
            <h5>Folder Indications: </h5>
            <div className="folder ml-4">
              <div className="folder__back">
                <div className="paper" />
                <div className="paper" />
                <div className="paper" />
                <div className="folder__front content-folder" />
                <div className="folder__front right" />
              </div>
            </div>
            <div className="ml-2">Data folder</div>
            <div className="folder ml-4">
              <div className="folder__back">
                <div className="paper" />
                <div className="paper" />
                <div className="paper" />
                <div className="folder__front empty-folder" />
                <div className="folder__front right" />
              </div>
            </div>
            <div className="ml-2">Empty folder</div>
          </div> */}
          <button
            className="btn btn-orange ml-sm-auto mt-sm-0 mt-xs-3 col-sm-auto"
            onClick={this.toggleCreate}
          >
            Create folder
          </button>
        </section>

        <section className="folder--group my-4">
          {folders.map((folder, index) => (
            <div
              key={folder.id}
              className="folder-container px-3 py-2"
              onClick={this.openFolder(folder.id)}
            >
              <div className="folder">
                <div className="folder__back">
                  <div className="paper" />
                  <div className="paper" />
                  <div className="paper" />
                  <div
                    className={`folder__front ${
                      folder.count > 0 ? "content-folder" : "content-folder"
                    }`}
                  />
                  <div className="folder__front right" />
                </div>
              </div>
              <div className="pl-2 folder-name">{folder.name}</div>
              {folder.id && (
                <div
                  className="dropdown ml-auto"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <span id="moreActions" data-toggle="dropdown">
                    <i className="fa fa-ellipsis-h" aria-hidden="true" />
                  </span>
                  <div className="dropdown-menu" aria-labelledby="moreActions">
                    <div
                      className="dropdown-item"
                      onClick={this.handleEdit(folder)}
                    >
                      Edit
                    </div>
                    <div className="dropdown-divider" />
                    <div
                      className="dropdown-item"
                      onClick={this.handleDeleteFolder(folder.id)}
                    >
                      Delete
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </section>

        <GModal
          isOpen={isOpen}
          isClose={true}
          onClose={this.toggleCreate}
          heading={isEdit ? "Edit Folder" : "Create folder"}
          variant="md"
        >
          <AppInput
            type="text"
            name="name"
            label="Folder name"
            value={name}
            onValueChange={this.handleChange}
          />

          <div className="row m-0 justify-content-end mt-3">
            <button
              className="btn btn-white col-sm-auto mt-3 mt-sm-0"
              onClick={this.toggleCreate}
            >
              Cancel
            </button>
            <button
              disabled={!name}
              className="btn btn-orange col-sm-auto ml-sm-4 mt-3 mt-sm-0"
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        </GModal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  folders: state.savedLibrary.folders
});

const mapDispatchToProps = {
  getMyDesignsFolderList: getMyDesignsFolderList,
  saveAndUpdateMyDesignsFolder: saveAndUpdateMyDesignsFolder,
  deleteMyDesignsFolder: deleteMyDesignsFolder
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedLibraryFolders);
