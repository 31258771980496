import React from "react";
import { toast } from "react-toastify";
import { savedLibraryConstants } from "../actions/savedLibraries.js";
import { groupBy } from "../../utils/utils.js";
import HeraldToastify, {
  ICONS
} from "../../home/components/HeraldToastify/HeraldToastify.jsx";

const initialState = {
  groupByFolder: {},
  templates: [],
  folders: [],
  groupedTemplates: {},
  template: null,
  layers: [],

  loading: false,
  error: null
};

const savedLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY:
      return {
        ...state,
        loading: true
      };
    case savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY_FAIL:
      return {
        ...state,
        loading: false
      };

    case savedLibraryConstants.GET_ALL_SAVED_LIBRARIES:
      return {
        ...state,
        loading: true
      };
    case savedLibraryConstants.GET_ALL_SAVED_LIBRARIES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const groupByFolder = groupBy(action.data.data, "folder");
      Object.keys(groupByFolder).forEach(key => {
        groupByFolder[key] = groupBy(groupByFolder[key], "sports");
      });
      return {
        ...state,
        loading: false,
        templates: [...action.data.data],
        groupedTemplates: groupBy(action.data.data, "sports"),
        groupByFolder: groupByFolder
      };
    case savedLibraryConstants.GET_ALL_SAVED_LIBRARIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case savedLibraryConstants.GET_SAVED_LIBRARY:
      return {
        ...state,
        loading: true
      };
    case savedLibraryConstants.GET_SAVED_LIBRARY_SUCCESS:
      return {
        ...state,
        loading: false,
        template: action.data.data[0]
      };
    case savedLibraryConstants.GET_SAVED_LIBRARY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case savedLibraryConstants.DUPLICATE_SAVED_LIBRARY:
      return {
        ...state,
        loading: true
      };
    case savedLibraryConstants.DUPLICATE_SAVED_LIBRARY_SUCCESS:
      toast.success(
        <HeraldToastify
          icon={ICONS.success}
          title="Template duplicated successfully"
        />
      );

      return {
        ...state,
        loading: false
      };
    case savedLibraryConstants.DUPLICATE_SAVED_LIBRARY_FAIL:
      return {
        ...state,
        loading: false
      };

    case savedLibraryConstants.DELETE_SAVED_LIBRARY:
      return {
        ...state,
        loading: true
      };
    case savedLibraryConstants.DELETE_SAVED_LIBRARY_SUCCESS:
      toast.success(
        <HeraldToastify
          icon={ICONS.success}
          title="Template deleted successfully"
        />
      );
      return {
        ...state,
        loading: false
      };
    case savedLibraryConstants.DELETE_SAVED_LIBRARY_FAIL:
      return {
        ...state,
        loading: false
      };

    case savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY_FOLDER:
      return {
        ...state,
        loading: true
      };
    case savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY_FOLDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case savedLibraryConstants.GET_SAVED_LIBRARY_FOLDERS:
      return {
        ...state,
        loading: true
      };
    case savedLibraryConstants.GET_SAVED_LIBRARY_FOLDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        folders: [{ id: null, name: "default" }, ...action.data.FolderInfo].map(
          item => ({
            ...item,
            value: item.id,
            label: item.name
          })
        )
      };
    case savedLibraryConstants.GET_SAVED_LIBRARY_FOLDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case savedLibraryConstants.DELETE_SAVED_LIBRARY_FOLDER:
      return {
        ...state,
        loading: true
      };
    case savedLibraryConstants.DELETE_SAVED_LIBRARY_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case savedLibraryConstants.DELETE_SAVED_LIBRARY_FOLDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default savedLibraryReducer;
