/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import Dropdown from "react-dropdown";
import axios from "axios";
import {
  getAllMediaLibrary,
  getUserTags
} from "../../../../redux/actions/mediaLibrary";
import MediaCard from "../../../components/mediaCard/MediaCard";
import { image } from "../shapes";
import searchImg from "../../../../assets/img/search@2x.png";
import "./ImportImage.scss";
import Compressor from "compressorjs";
import { hideLoader, showLoader } from "../../../../redux/reducers/loader";
import { getSignedURL } from "../../../../redux/services/media_axios";

class ImportImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selectedtag: null,
      file: null,
      scale: 1,
      image_url: null,
      activeTab: "",
      selectedMedia: null,
      URL: ""
    };
  }

  componentDidMount() {
    if (this.props.list.length === 0) {
      this.getMediaList();
    }
    this.getS3SignedURL();
    this.props.getUserTags();
  }

  getS3SignedURL = () => {
    getSignedURL().then(res => {
      const data = res.data;
      console.log(data.URL);
      this.setState({ URL: data.URL });
    });
  };

  getMediaList = () => {
    const { search, selectedTag } = this.state;
    const filterData = {
      galleryName: search,
      labelName: selectedTag && selectedTag !== "all" ? [selectedTag] : []
    };
    this.props.getAllMediaLibrary(filterData);
  };

  handleUpload = async e => {
    let scale = 1;
    let quality = 1;
    const that = this;
    var _URL = window.URL || window.webkitURL;
    let { width: STAGE_WIDTH, height: STAGE_HEIGHT, initialScale } = this.props;

    const file = e.target.files[0];
    if (!file) {
      return;
    }
    console.log(">>>>>>>Original file", file);
    console.log(">>>>Original File Size >>>>", file.size / (1000 * 1000), "MB");

    const img = new Image();
    img.onload = function() {
      const width = this.width;
      const height = this.height;
      STAGE_WIDTH = STAGE_WIDTH / initialScale;
      STAGE_HEIGHT = STAGE_HEIGHT / initialScale;
      if (width > STAGE_WIDTH || height > STAGE_HEIGHT) {
        scale = Math.min(STAGE_WIDTH / width, STAGE_HEIGHT / height);
      }
      console.log(width, height, ">>>>>>>>>>>>>", scale);
      /**
       * Checking file size, if file exceeds more than 1MB
       * compress with quality measured.
       */
      let { size, type } = file;
      size = size / (1000 * 1000);
      if (size > 1 && size <= 3) quality = 0.8;
      else if (size > 3 && size <= 4) quality = 0.7;
      else if (size > 4) quality = 0.6;
      if (size > 3) {
        new Compressor(file, {
          quality: quality,
          success(convertedFile) {
            console.log("Converted file", convertedFile);
            let cSize = convertedFile.size / (1000 * 1000);
            console.log("Converted file Size>>", cSize, "MB");
            that.setState({
              scale: scale,
              file: convertedFile
            });
          },
          error(err) {
            console.log(err.message);
            that.setState({
              scale: scale,
              file: file
            });
          }
        });
      } else {
        that.setState({
          scale: scale,
          file: file
        });
      }
    };
    img.src = _URL.createObjectURL(file);

    /*
    const reader = new FileReader();
    reader.onload = e => {
      const base_64 = e.target.result;
      var image = new Image(); //Initiate the JavaScript Image object.
      image.src = base_64; //Set the Base64 string return from FileReader as source.

      const that = this;
      let scale = 1;
      //  Check image width/height and scale down to canvas width/height ratio
      image.onload = function() {
        const width = this.width;
        const height = this.height;
        if (width > STAGE_WIDTH || height > STAGE_HEIGHT) {
          scale =
            (Math.max(STAGE_WIDTH, STAGE_HEIGHT) - 25) /
            Math.max(width, height);
        }
        console.log(width, height, ">>>>>>>>>>>>>", scale);
        that.setState({
          file: file,
          image_url: base_64,
          scale
        });
      };
    };
    reader.readAsDataURL(file);
    */
    /*
    if (type === "image/svg+xml") {
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({
          file: file,
          image_url: e.target.result
        });
      };
      reader.readAsDataURL(file);
    } else {
      const { width: STAGE_WIDTH, height: STAGE_HEIGHT } = this.props;
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: Math.min(STAGE_WIDTH, STAGE_HEIGHT) - 50,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(file, options);
      console.log(`compressedFile size ${compressedFile.size / 1000} kB`);
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({
          file: file,
          image_url: e.target.result
        });
      };
      reader.readAsDataURL(compressedFile);
    }
     */
  };

  handleImport = () => {
    const { isReplace } = this.props;
    const cb = IMAGE_URL => {
      if (!isReplace) {
        const shape = {
          ...image,
          scaleX: scale,
          scaleY: scale,
          src: IMAGE_URL
        };
        this.props.addShape(shape);
      } else {
        const { file } = this.state;
        const data = {
          src: IMAGE_URL,
          scale: file ? scale : null
        };
        this.props.handleReplace(data);
      }
    };

    const { file, image_url, scale, URL } = this.state;
    if (file) {
      const IMAGE_URL = URL.split("?")[0];
      this.props.showLoader();
      axios({
        method: "put",
        url: URL,
        headers: {
          "x-amz-acl": "public-read",
          "Content-Type": "image/jpeg"
        },
        data: file
      })
        .then(() => {
          cb(IMAGE_URL);
          this.props.hideLoader();
        })
        .catch(err => {
          this.props.hideLoader();
        });
    } else {
      cb(image_url);
    }
    this.props.toggleImport();
  };

  handleSearch = e => {
    const value = e.target.value;
    this.setState({ search: value }, () => {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.getMediaList();
      }, 500);
    });
  };

  handleSelect = media => {
    this.setState({
      selectedMedia: media,
      image_url: media && media.image_url ? media.image_url : null
    });

    if (!media) return; // If media is not available then return;
  };

  onSelectTag = ({ value: tag }) => {
    this.setState({ selectedTag: tag }, this.getMediaList);
  };

  onTabChange = tabName => () => {
    const { activeTab } = this.state;
    if (activeTab !== tabName) {
      this.setState({
        activeTab: tabName,
        selectedMedia: null,
        image_url: null,
        file: null
      });
    }
  };

  render() {
    const { file, image_url, selectedMedia, search, selectedTag } = this.state;
    const { list, tags, toggleImport, isReplace } = this.props;
    const set = new Set(tags);
    const options = [...set];
    return (
      <div className="import-container">
        <h5>{isReplace ? "Replace" : "Import"} images</h5>
        <ul className="nav">
          <li className="nav-item">
            <a
              className="nav-link pl-0"
              onClick={this.onTabChange("mediaLibrary")}
              data-toggle="tab"
              href="#mediaLibrary"
            >
              Media Library
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link pl-0 active"
              onClick={this.onTabChange("upload")}
              data-toggle="tab"
              href="#import"
            >
              Upload
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show "
            id="mediaLibrary"
            role="tabpanel"
            aria-labelledby="media-tab"
          >
            <div className="media_container p-4">
              <div className="row mx-0 mb-4">
                <div className="position-relative search-container mb-2 col-sm-auto">
                  <input
                    type="text"
                    className="search-box"
                    value={search}
                    placeholder="Search by name"
                    onChange={this.handleSearch}
                  />
                  <img
                    alt="search_icon"
                    src={searchImg}
                    className="search-icon"
                  />
                </div>
                <div className="media_container_tags mb-2">
                  <Dropdown
                    placeholder="Select tag"
                    options={options}
                    value={selectedTag}
                    onChange={this.onSelectTag}
                  />
                </div>
              </div>
              <div className="row mx-0">
                {list.map(media => (
                  <div className="media_cards pr-2 pb-5" key={media.id}>
                    <MediaCard
                      media={media}
                      type="import"
                      handleSelect={this.handleSelect}
                      isChecked={selectedMedia && selectedMedia.id === media.id}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade show active"
            id="import"
            role="tabpanel"
            aria-labelledby="import-tab"
            style={{ height: "max-content" }}
          >
            <div className="drop-box container mb-3">
              <div className="box d-sm-block d-none">
                <div className="drag_text">
                  {file && file.name ? file.name : "Drag a file here"}
                  <br />
                  Or
                </div>
                <button className="btn btn-orange">
                  Select a file from your device
                </button>
              </div>
              <div className="box d-sm-none d-block">
                <button className="btn btn-orange">Choose file</button>
              </div>
              <input
                type="file"
                accept="image/*"
                className="file-upload"
                title=""
                onChange={this.handleUpload}
              />
            </div>
          </div>
        </div>
        <div className="row m-0 justify-content-end mt-3">
          <button
            className="btn btn-white col-sm-auto mt-3 mt-sm-0"
            onClick={toggleImport}
          >
            Cancel
          </button>
          <button
            disabled={!(file || image_url)}
            className="btn btn-orange col-sm-auto ml-sm-4 mt-3 mt-sm-0"
            onClick={this.handleImport}
          >
            {isReplace ? "Replace" : "Add"}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  list: state.media.mediaLibraries,
  tags: state.media.userTags
});

const mapDispatchToProps = {
  getAllMediaLibrary: getAllMediaLibrary,
  getUserTags: getUserTags,
  showLoader: showLoader,
  hideLoader: hideLoader
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportImage);
