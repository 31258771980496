// eslint-disable-next-line no-unused-vars
import React from "react";
import { toast } from "react-toastify";
import { authConstants } from "../actions/auth";
import HeraldToastify, {
  ICONS
} from "../../home/components/HeraldToastify/HeraldToastify";

const initialState = {
  loading: false,
  status: "",
  profiles: {},
  data: null,
  shareData: {},
  error: null
};

const AuthSocialReducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.GET_INSTAGRAM_USER_PROFILE:
      return {
        ...state,
        loading: true
      };
    case authConstants.GET_INSTAGRAM_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profiles: action.data
      };
    case authConstants.GET_INSTAGRAM_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case authConstants.GET_INSTAGRAM_SUCCESSCB_INIT:
      return {
        ...state,
        loading: true
      };
    case authConstants.GET_INSTAGRAM_SUCCESSCB_SUCCESS:
      toast.success(
        <HeraldToastify
          icon={ICONS.success}
          title="Instagram Connected in succesfully"
        />
      );
      return {
        ...state,
        loading: false
      };
    case authConstants.GET_INSTAGRAM_SUCCESSCB_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case authConstants.INSTAGRAM_SOCIAL_SHARE_INIT:
      return {
        ...state,
        loading: true
      };
    case authConstants.INSTAGRAM_SOCIAL_SHARE_SUCCESS:
      toast.success(
        <HeraldToastify
          icon={ICONS.success}
          title="Template Shared succesfully"
        />
      );
      return {
        ...state,
        loading: false
      };
    case authConstants.INSTAGRAM_SOCIAL_SHARE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case authConstants.REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_INIT:
      return {
        ...state,
        loading: true
      };
    case authConstants.REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_SUCCESS:
      toast.success(
        <HeraldToastify
          icon={ICONS.success}
          title="Instagram Account Removed succesfully"
        />
      );
      return {
        ...state,
        loading: false
      };
    case authConstants.REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default AuthSocialReducer;
