import {
  signIn,
  signUp,
  getLoggedInUser,
  logout,
  passwordChange,
  updateUserProfileSettings,
  addTeamInfo,
  updateTeamInfo,
  getCategoryList,
  fetchUsersList,
  getSubscribedCategoryList,
  fetchOnBoardUserList,
  OnBoardUserSubscription,
  forgotPassword,
  instaSocialProfile,
  instaSuccessCB,
  instaSocialShare,
  removeInstaAccount
} from "../services/auth_axios";

export const authConstants = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",

  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAIL: "USER_LOGOUT_FAIL",

  USER_REGISTER: "USER_REGISTER",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL: "USER_REGISTER_FAIL",

  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAIL: "FORGOT_PASSWORD_FAIL",

  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

  FETCH_LOGGED_IN_USER: "FETCH_LOGGED_IN_USER",
  FETCH_LOGGED_IN_USER_SUCCESS: "FETCH_LOGGED_IN_USER_SUCCESS",
  FETCH_LOGGED_IN_USER_FAIL: "FETCH_LOGGED_IN_USER_FAIL",

  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAIL: "UPDATE_USER_PROFILE_FAIL",

  GET_ALL_CATEGORIES: "GET_ALL_CATEGORIES",
  GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FAIL: "GET_ALL_CATEGORIES_FAIL",

  GET_SUBSCRIBED_CATEGORIES: "GET_SUBSCRIBED_CATEGORIES",
  GET_SUBSCRIBED_CATEGORIES_SUCCESS: "GET_SUBSCRIBED_CATEGORIES_SUCCESS",
  GET_SUBSCRIBED_CATEGORIES_FAIL: "GET_SUBSCRIBED_CATEGORIES_FAIL",

  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAIL: "GET_ALL_USERS_FAIL",

  PENDING_ONBOARD_USER_SUBSCRIPTION: "PENDING_ONBOARD_USER_SUBSCRIPTION",
  PENDING_ONBOARD_USER_SUBSCRIPTION_SUCCESS:
    "PENDING_ONBOARD_USER_SUBSCRIPTION_SUCCESS",
  PENDING_ONBOARD_USER_SUBSCRIPTION_FAIL:
    "PENDING_ONBOARD_USER_SUBSCRIPTION_FAIL",

  ONBOARD_USER_SUBSCRIPTION: "ONBOARD_USER_SUBSCRIPTION",
  ONBOARD_USER_SUBSCRIPTION_SUCCESS: "ONBOARD_USER_SUBSCRIPTION_SUCCESS",
  ONBOARD_USER_SUBSCRIPTION_FAIL: "ONBOARD_USER_SUBSCRIPTION_FAIL",

  CREATE_TEAM: "CREATE_TEAM",
  CREATE_TEAM_SUCCESS: "CREATE_TEAM_SUCCESS",
  CREATE_TEAM_FAIL: "CREATE_TEAM_FAIL",

  UPDATE_TEAM: "UPDATE_TEAM",
  UPDATE_TEAM_SUCCESS: "UPDATE_TEAM_SUCCESS",
  UPDATE_TEAM_FAIL: "UPDATE_TEAM_FAIL",

  GET_INSTAGRAM_USER_PROFILE: "GET_INSTAGRAM_USER_PROFILE",
  GET_INSTAGRAM_USER_PROFILE_SUCCESS: "GET_INSTAGRAM_USER_PROFILE_SUCCESS",
  GET_INSTAGRAM_USER_PROFILE_FAIL: "GET_INSTAGRAM_USER_PROFILE_FAIL",

  GET_INSTAGRAM_SUCCESSCB_INIT: "GET_INSTAGRAM_SUCCESSCB_INIT",
  GET_INSTAGRAM_SUCCESSCB_SUCCESS: "GET_INSTAGRAM_SUCCESSCB_SUCCESS",
  GET_INSTAGRAM_SUCCESSCB_FAIL: "GET_INSTAGRAM_SUCCESSCB_FAIL",

  INSTAGRAM_SOCIAL_SHARE_INIT: "INSTAGRAM_SOCIAL_SHARE_INIT",
  INSTAGRAM_SOCIAL_SHARE_SUCCESS: "INSTAGRAM_SOCIAL_SHARE_SUCCESS",
  INSTAGRAM_SOCIAL_SHARE_FAIL: "INSTAGRAM_SOCIAL_SHARE_FAIL",

  REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_INIT: "REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_INIT",
  REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_SUCCESS:
    "REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_SUCCESS",
  REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_FAIL: "REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_FAIL"
};

export const userLogin = (data, cb) => {
  return {
    types: [
      authConstants.USER_LOGIN,
      authConstants.USER_LOGIN_SUCCESS,
      authConstants.USER_LOGIN_FAIL
    ],
    service: signIn(data),
    onSuccess: cb
  };
};

export const userLogout = () => {
  return {
    types: [
      authConstants.USER_LOGOUT,
      authConstants.USER_LOGOUT_SUCCESS,
      authConstants.USER_LOGOUT_FAIL
    ],
    service: logout()
  };
};

export const userRegister = (data, cb) => {
  return {
    types: [
      authConstants.USER_REGISTER,
      authConstants.USER_REGISTER_SUCCESS,
      authConstants.USER_REGISTER_FAIL
    ],
    service: signUp(data),
    onSuccess: cb
  };
};

export const changePassword = data => {
  return {
    types: [
      authConstants.CHANGE_PASSWORD,
      authConstants.CHANGE_PASSWORD_SUCCESS,
      authConstants.CHANGE_PASSWORD_FAIL
    ],
    service: passwordChange(data)
  };
};

export const userForgotPassword = (data, cb) => {
  return {
    types: [
      authConstants.FORGOT_PASSWORD,
      authConstants.FORGOT_PASSWORD_SUCCESS,
      authConstants.FORGOT_PASSWORD_FAIL
    ],
    service: forgotPassword(data),
    onSuccess: cb
  };
};

export const fetchLoggedInUser = () => {
  return {
    types: [
      authConstants.FETCH_LOGGED_IN_USER,
      authConstants.FETCH_LOGGED_IN_USER_SUCCESS,
      authConstants.FETCH_LOGGED_IN_USER_FAIL
    ],
    service: getLoggedInUser()
  };
};

export const updateUserProfile = data => {
  return {
    types: [
      authConstants.UPDATE_USER_PROFILE,
      authConstants.UPDATE_USER_PROFILE_SUCCESS,
      authConstants.UPDATE_USER_PROFILE_FAIL
    ],
    service: updateUserProfileSettings(data)
  };
};

export const getAllCategories = () => {
  return {
    types: [
      authConstants.GET_ALL_CATEGORIES,
      authConstants.GET_ALL_CATEGORIES_SUCCESS,
      authConstants.GET_ALL_CATEGORIES_FAIL
    ],
    disableLoading: true,
    service: getCategoryList()
  };
};

export const getSubscribedCategories = () => {
  return {
    types: [
      authConstants.GET_SUBSCRIBED_CATEGORIES,
      authConstants.GET_SUBSCRIBED_CATEGORIES_SUCCESS,
      authConstants.GET_SUBSCRIBED_CATEGORIES_FAIL
    ],
    disableLoading: true,
    service: getSubscribedCategoryList()
  };
};

export const getAllUserSubscriptions = ({ cb }) => {
  return {
    types: [
      authConstants.GET_ALL_USERS,
      authConstants.GET_ALL_USERS_SUCCESS,
      authConstants.GET_ALL_USERS_FAIL
    ],
    service: fetchUsersList(),
    onSuccess: cb
  };
};

export const getPendingOnBoardUserSubscription = ({ cb }) => {
  return {
    types: [
      authConstants.PENDING_ONBOARD_USER_SUBSCRIPTION,
      authConstants.PENDING_ONBOARD_USER_SUBSCRIPTION_SUCCESS,
      authConstants.PENDING_ONBOARD_USER_SUBSCRIPTION_FAIL
    ],
    service: fetchOnBoardUserList(),
    onSuccess: cb
  };
};

export const onBoardUser = (id, cb) => {
  return {
    types: [
      authConstants.ONBOARD_USER_SUBSCRIPTION,
      authConstants.ONBOARD_USER_SUBSCRIPTION_SUCCESS,
      authConstants.ONBOARD_USER_SUBSCRIPTION_FAIL
    ],
    service: OnBoardUserSubscription(id),
    onSuccess: cb
  };
};

export const createTeam = (data, cb) => {
  return {
    types: [
      authConstants.CREATE_TEAM,
      authConstants.CREATE_TEAM_SUCCESS,
      authConstants.CREATE_TEAM_FAIL
    ],
    service: addTeamInfo(data),
    onSuccess: cb
  };
};

export const updateTeam = data => {
  return {
    types: [
      authConstants.UPDATE_TEAM,
      authConstants.UPDATE_TEAM_SUCCESS,
      authConstants.UPDATE_TEAM_FAIL
    ],
    service: updateTeamInfo(data)
  };
};

export const getInstaSocialProfiles = () => {
  return {
    types: [
      authConstants.GET_INSTAGRAM_USER_PROFILE,
      authConstants.GET_INSTAGRAM_USER_PROFILE_SUCCESS,
      authConstants.GET_INSTAGRAM_USER_PROFILE_FAIL
    ],
    service: instaSocialProfile()
  };
};

export const getInstaSuccessCB = (data, cb) => {
  return {
    types: [
      authConstants.GET_INSTAGRAM_SUCCESSCB_INIT,
      authConstants.GET_INSTAGRAM_SUCCESSCB_SUCCESS,
      authConstants.GET_INSTAGRAM_SUCCESSCB_FAIL
    ],
    service: instaSuccessCB(data),
    onSuccess: cb
  };
};

export const postInstaSocialShare = (data, cb) => {
  return {
    types: [
      authConstants.INSTAGRAM_SOCIAL_SHARE_INIT,
      authConstants.INSTAGRAM_SOCIAL_SHARE_SUCCESS,
      authConstants.INSTAGRAM_SOCIAL_SHARE_FAIL
    ],
    service: instaSocialShare(data),
    onSuccess: cb
  };
};

export const removeInstaSocialAccount = (id, cb) => {
  return {
    types: [
      authConstants.REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_INIT,
      authConstants.REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_SUCCESS,
      authConstants.REMOVE_INSTAGRAM_SOCIAL_ACCOUNT_FAIL
    ],
    service: removeInstaAccount(id),
    onSuccess: cb
  };
};
