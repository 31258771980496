/* eslint-disable no-unused-vars */
import React from "react";
import "./exportImage.scss";
import { roundOff } from "../../../utils/utils";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";

const SIZE_LIST = {
  sm: 480,
  md: 960,
  lg: 1920,
  xl: 3840
};

class ExportImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sizes: new Set()
    };
  }

  /*
  handleDownload = () => {
    const { stage } = this.props;
    const sizes = [...this.state.sizes];
    const name = `${this.props.name || "pictures"}`;
    if (stage) {
      // var zip = new JSZip();
      sizes.map(size => {
        const fileName = `${name}-${size}.jpeg`;
        const { width, height } = stage.size();
        const [exportWidth, exportHeight] = sizeList[size];
        const pixelRatio = exportWidth / width;
        const dataURI = stage.toDataURL({
          pixelRatio,
          mimeType: "image/jpeg",
          quality: 1
        });
        this.downloadFile(dataURI, fileName);
        // const base64 = dataURI.split("base64,")[1];
        // zip.file(fileName, base64, { base64: true });
        return dataURI;
      });
      this.props.onClose();
      // zip.generateAsync({ type: "blob" }).then(content => {
      //   saveAs(content, `${name}.zip`);
      //   this.props.onClose();
      // });
    } else {
      this.props.onClose();
    }
  };
  */

  handleDownload = () => {
    const { sizeList } = this.props;
    const sizes = [...this.state.sizes];
    sizes.map(size => {
      window.open(sizeList[size], "_blank");
    });
    this.props.onClose();
  };

  /*
  downloadFile = (dataURI, fileName) => {
    let link = document.createElement("a");
    link.download = fileName;
    link.href = dataURI;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  */

  handleCheck = event => {
    const { value, checked } = event.target;
    const sizes = this.state.sizes;
    if (checked) {
      sizes.add(value);
    } else {
      sizes.delete(value);
    }
    this.setState({
      sizes: sizes
    });
  };

  getSize = size => {
    const [W, H] = this.props.dimensions;
    const width = SIZE_LIST[size];
    const ratio = width / W;
    const height = roundOff(ratio * H, 0);
    return `(${width} X ${height})`;
  };

  render() {
    const { onClose, dimensions, sizeList } = this.props;
    const { sizes } = this.state;

    return (
      <section className="download_container p-4">
        <h5 className="heading">Choose sizes</h5>
        <div className="pt-3">
          <label className="checbox_container">
            Small {this.getSize("sm")}
            <input
              type="checkbox"
              name="size"
              value="sm"
              checked={sizes.has("sm")}
              onChange={this.handleCheck}
            />
            <span className="checkmark" />
          </label>
          <label className="checbox_container">
            Medium {this.getSize("md")}
            <input
              type="checkbox"
              name="size"
              value="md"
              checked={sizes.has("md")}
              onChange={this.handleCheck}
            />
            <span className="checkmark" />
          </label>
          <label className="checbox_container">
            Large {this.getSize("lg")}
            <input
              type="checkbox"
              name="size"
              value="lg"
              checked={sizes.has("lg")}
              onChange={this.handleCheck}
            />
            <span className="checkmark" />
          </label>
          <label className="checbox_container">
            Extra Large {this.getSize("xl")}
            <input
              type="checkbox"
              name="size"
              value="xl"
              checked={sizes.has("xl")}
              onChange={this.handleCheck}
            />
            <span className="checkmark" />
          </label>
          {sizeList.mp4 && (
            <p className="or">
              <span>or</span>
            </p>
          )}
          {sizeList.mp4 && (
            <label className="checbox_container">
              Export as Video (mp4)
              <input
                type="checkbox"
                name="size"
                value="mp4"
                checked={sizes.has("mp4")}
                onChange={this.handleCheck}
              />
              <span className="checkmark" />
            </label>
          )}
        </div>
        <div className="row m-0 justify-content-sm-end mt-3">
          <button className="btn btn-white col-sm-auto" onClick={onClose}>
            Cancel
          </button>
          <button
            disabled={sizes.size === 0}
            className="btn btn-orange col-sm-auto ml-sm-4 mt-sm-0 mt-3"
            onClick={this.handleDownload}
          >
            Export
          </button>
        </div>
      </section>
    );
  }
}

export default ExportImage;
