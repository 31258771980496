/* eslint-disable prettier/prettier */
import React from 'react';


export const ICONS = {
  info: <i className="material-icons">info</i>,
  success: <i className="material-icons">check_circle_outline</i>,
  warning: <i className="material-icons">warning</i>,
  error: <i className="material-icons">error</i>,
}

function HeraldToastify({ icon, title, content, toastProps, sideAction, actions= [], percent }) {
  return (
    <section>
      <div className="d-flex align-items-center">
        {icon || ''}
        <span className="mx-2">{title ? title : ''}</span>
        {sideAction &&
        <button onClick={sideAction.onClick} style={{marginTop: '2px'}} className="ml-auto mr-2 text-sm">
          {sideAction.name} </button>}
          {percent ? <button className="ml-auto mr-1 text-sm">{percent}%</button> : ''}
      </div>
      {content &&
      <div className="mt-1">
        <div className="toast--content text-xs">{content}</div>
        <div className="pt-3">
        {actions.map((action, index) => (<div key={`notify-action-${index}`} onClick={action.onClick} className="text-xs cursor-pointer">
          {action.name} </div>))
        }
        </div>
      </div>}
    </section>
  )
}

export default HeraldToastify
