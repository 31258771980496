import { combineReducers } from "redux";
import authReducer from "./auth.js";
import templateReducer from "./templates.js";
import savedLibraryReducer from "./savedLibraries.js";
import mediaLibraryReducer from "./mediaLibrary.js";
import loadReducer from "./loader.js";
import subscriptionReducer from "./subscriptions.js";
import AuthSocialReducer from "./authSocial.js";

const IReducers = combineReducers({
  auth: authReducer,
  socialAuth: AuthSocialReducer,
  template: templateReducer,
  savedLibrary: savedLibraryReducer,
  media: mediaLibraryReducer,
  subscription: subscriptionReducer,
  loader: loadReducer
});

export default IReducers;
