import {
  getListOfSavedLibraries,
  getSavedLibraryById,
  deleteSavedLibraryById,
  saveOrUpdateSavedLibrary,
  duplicateSavedLibrary,
  getLatestLibraries,
  shareToTwitter,
  getMyDesignsFolders,
  saveMyDesignsFolder,
  deleteMyDesignsFolderById
} from "../services/savedLibraries_axios.js";

export const savedLibraryConstants = {
  CREATE_UPDATE_SAVED_LIBRARY: "CREATE_UPDATE_SAVED_LIBRARY",
  CREATE_UPDATE_SAVED_LIBRARY_SUCCESS: "CREATE_UPDATE_SAVED_LIBRARY_SUCCESS",
  CREATE_UPDATE_SAVED_LIBRARY_FAIL: "CREATE_UPDATE_SAVED_LIBRARY_FAIL",

  GET_SAVED_LIBRARY: "GET_SAVED_LIBRARY",
  GET_SAVED_LIBRARY_SUCCESS: "GET_SAVED_LIBRARY_SUCCESS",
  GET_SAVED_LIBRARY_FAIL: "GET_SAVED_LIBRARY",

  DELETE_SAVED_LIBRARY: "DELETE_SAVED_LIBRARY",
  DELETE_SAVED_LIBRARY_SUCCESS: "DELETE_SAVED_LIBRARY_SUCCESS",
  DELETE_SAVED_LIBRARY_FAIL: "DELETE_SAVED_LIBRARY_FAIL",

  DUPLICATE_SAVED_LIBRARY: "DUPLICATE_SAVED_LIBRARY",
  DUPLICATE_SAVED_LIBRARY_SUCCESS: "DUPLICATE_SAVED_LIBRARY_SUCCESS",
  DUPLICATE_SAVED_LIBRARY_FAIL: "DUPLICATE_SAVED_LIBRARY_FAIL",

  GET_ALL_SAVED_LIBRARIES: "GET_ALL_SAVED_LIBRARIES",
  GET_ALL_SAVED_LIBRARIES_SUCCESS: "GET_ALL_SAVED_LIBRARIES_SUCCESS",
  GET_ALL_SAVED_LIBRARIES_FAIL: "GET_ALL_SAVED_LIBRARIES_FAIL",

  SHARE_TEMPLATE_TO_TWITTER: "SHARE_TEMPLATE_TO_TWITTER",
  SHARE_TEMPLATE_TO_TWITTER_SUCCESS: "SHARE_TEMPLATE_TO_TWITTER_SUCCESS",
  SHARE_TEMPLATE_TO_TWITTER_FAIL: "SHARE_TEMPLATE_TO_TWITTER_FAIL",

  GET_SAVED_LIBRARY_FOLDERS: "GET_SAVED_LIBRARY_FOLDERS",
  GET_SAVED_LIBRARY_FOLDERS_SUCCESS: "GET_SAVED_LIBRARY_FOLDERS_SUCCESS",
  GET_SAVED_LIBRARY_FOLDERS_FAIL: "GET_SAVED_LIBRARY_FOLDERS_FAIL",

  CREATE_UPDATE_SAVED_LIBRARY_FOLDER: "CREATE_UPDATE_SAVED_LIBRARY_FOLDER",
  CREATE_UPDATE_SAVED_LIBRARY_FOLDER_SUCCESS:
    "CREATE_UPDATE_SAVED_LIBRARY_FOLDER_SUCCESS",
  CREATE_UPDATE_SAVED_LIBRARY_FOLDER_FAIL:
    "CREATE_UPDATE_SAVED_LIBRARY_FOLDER_FAIL",

  DELETE_SAVED_LIBRARY_FOLDER: "DELETE_SAVED_LIBRARY_FOLDER",
  DELETE_SAVED_LIBRARY_FOLDER_SUCCESS: "DELETE_SAVED_LIBRARY_FOLDER_SUCCESS",
  DELETE_SAVED_LIBRARY_FOLDER_FAIL: "DELETE_SAVED_LIBRARY_FOLDER_FAIL"
};

export const createOrUpdateSavedLibrary = ({ template, cb }) => {
  return {
    types: [
      savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY,
      savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY_SUCCESS,
      savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY_FAIL
    ],
    service: saveOrUpdateSavedLibrary(template),
    onSuccess: cb
  };
};

export const getAllSavedLibraries = data => {
  return {
    types: [
      savedLibraryConstants.GET_ALL_SAVED_LIBRARIES,
      savedLibraryConstants.GET_ALL_SAVED_LIBRARIES_SUCCESS,
      savedLibraryConstants.GET_ALL_SAVED_LIBRARIES_FAIL
    ],
    service: getListOfSavedLibraries(data)
  };
};

export const getLatestSavedLibraries = () => {
  return {
    types: [
      savedLibraryConstants.GET_ALL_SAVED_LIBRARIES,
      savedLibraryConstants.GET_ALL_SAVED_LIBRARIES_SUCCESS,
      savedLibraryConstants.GET_ALL_SAVED_LIBRARIES_FAIL
    ],
    service: getLatestLibraries()
  };
};

export const getSavedLibrary = (id, cb) => {
  return {
    types: [
      savedLibraryConstants.GET_SAVED_LIBRARY,
      savedLibraryConstants.GET_SAVED_LIBRARY_SUCCESS,
      savedLibraryConstants.GET_SAVED_LIBRARY_FAIL
    ],
    service: getSavedLibraryById(id),
    onSuccess: cb
  };
};

export const copySavedLibrary = ({ data, cb }) => {
  return {
    types: [
      savedLibraryConstants.DUPLICATE_SAVED_LIBRARY,
      savedLibraryConstants.DUPLICATE_SAVED_LIBRARY_SUCCESS,
      savedLibraryConstants.DUPLICATE_SAVED_LIBRARY_FAIL
    ],
    service: duplicateSavedLibrary(data),
    onSuccess: cb
  };
};

export const deleteSavedLibrary = ({ id, cb }) => {
  return {
    types: [
      savedLibraryConstants.DELETE_SAVED_LIBRARY,
      savedLibraryConstants.DELETE_SAVED_LIBRARY_SUCCESS,
      savedLibraryConstants.DELETE_SAVED_LIBRARY_FAIL
    ],
    service: deleteSavedLibraryById(id),
    onSuccess: cb
  };
};

export const shareTemplateToTwitter = (id, cb) => {
  return {
    types: [
      savedLibraryConstants.SHARE_TEMPLATE_TO_TWITTER,
      savedLibraryConstants.SHARE_TEMPLATE_TO_TWITTER_SUCCESS,
      savedLibraryConstants.SHARE_TEMPLATE_TO_TWITTER_FAIL
    ],
    service: shareToTwitter(id),
    onSuccess: cb
  };
};

export const getMyDesignsFolderList = (onSuccess = null, onFail = null) => {
  return {
    types: [
      savedLibraryConstants.GET_SAVED_LIBRARY_FOLDERS,
      savedLibraryConstants.GET_SAVED_LIBRARY_FOLDERS_SUCCESS,
      savedLibraryConstants.GET_SAVED_LIBRARY_FOLDERS_FAIL
    ],
    service: getMyDesignsFolders(),
    onSuccess: onSuccess,
    onFail: onFail
  };
};

export const saveAndUpdateMyDesignsFolder = (data, onSuccess, onFail) => {
  return {
    types: [
      savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY_FOLDER,
      savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY_FOLDER_SUCCESS,
      savedLibraryConstants.CREATE_UPDATE_SAVED_LIBRARY_FOLDER_FAIL
    ],
    service: saveMyDesignsFolder(data),
    onSuccess: onSuccess,
    onFail: onFail
  };
};

export const deleteMyDesignsFolder = (id, onSuccess, onFail) => {
  return {
    types: [
      savedLibraryConstants.DELETE_SAVED_LIBRARY_FOLDER,
      savedLibraryConstants.DELETE_SAVED_LIBRARY_FOLDER_SUCCESS,
      savedLibraryConstants.DELETE_SAVED_LIBRARY_FOLDER_FAIL
    ],
    service: deleteMyDesignsFolderById(id),
    onSuccess: onSuccess,
    onFail: onFail
  };
};
