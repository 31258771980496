import React from "react";
import motionIcon from "../../../../../assets/img/motion-icon.svg";
import DndAnimationList from "./DndAnimationList";

const defaultAnimation = {
  type: "FADE IN",
  delayType: "withPrevious",
  duration: 600,
  shapedId: "",
  delay: 0,
  spinAngle: 0,
  shape: {}
};

const Animation = ({
  animations,
  selectedShape,
  addAnimation,
  removeAnimation,
  handleAnimationChange,
  isPlay,
  handlePlayAnimation,
  handleDropAnimations,
  handlePreviewAnimation,
  videoPreviewUrl,
  setSelectedShape,
  allShapes
}) => {
  const handleAddAnimation = () => {
    if (!selectedShape) {
      alert("Please Select Any One Shape!");
      return;
    }
    const requiredSelectedShape = {
      type: selectedShape.type || selectedShape.Type,
      name: selectedShape.name,
      id: selectedShape.id,
      layerName: selectedShape ? selectedShape.layerName : ""
    };
    const lastAnimatedObj = animations[animations.length - 1];
    const data = { ...defaultAnimation };
    data.shapedId = selectedShape.id || "";
    data.shape = selectedShape ? requiredSelectedShape : {};
    data.delay = lastAnimatedObj ? lastAnimatedObj.delay : 0;
    addAnimation(data);
  };

  return (
    <div className="animated-controls-wrap">
      <div className="animated-control-header">
        <img src={motionIcon} alt="motion-icon" />
        <h2>Motion</h2>
      </div>
      <div className="animated-control-item">
        <DndAnimationList
          animations={animations}
          selectedShapeIndex={selectedShape ? selectedShape.id : ""}
          removeAnimation={removeAnimation}
          handleAnimationChange={handleAnimationChange}
          handleDropAnimations={handleDropAnimations}
          allShapes={allShapes}
          setSelectedShape={setSelectedShape}
        />
      </div>
      <button
        className="mt-4 animation-sidebar-add-text"
        onClick={handleAddAnimation}
      >
        <span>+</span> Add animation
      </button>
      <div className="animation-action">
        <button
          className="mt-3 animation-action-btn"
          disabled={animations && animations.length === 0 ? true : false}
          onClick={handlePlayAnimation}
        >
          Play
        </button>
        {videoPreviewUrl !== "" && (
          <button
            className="mt-3 animation-action-btn"
            onClick={handlePreviewAnimation}
          >
            Preview
          </button>
        )}
      </div>
      {isPlay && (
        <div className="loader-wrapper">
          <div className="loader-wrapper-container">
            <div className="loader-wrapper-spinner">
              <div className="loader" />
              <p className="loader-text">Loading Preset</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Animation;
