/* eslint-disable no-unused-vars */
import { client } from "../apiClient";
import { getCookie } from "../../utils/utils";

class BaseService {
  defautHeaders = { "Content-Type": "application/json" };
  defautOptions = { withCredentials: true };
  constructor() {}

  setHttpHeader(httpheaders) {
    let headers = { ...this.defautHeaders, ...httpheaders };
    const token = getCookie("Authorization");
    if (token) {
      headers["Authorization"] = token;
    }
    return headers;
  }

  get({ url, body, queryParams, options = {} }) {
    const params = new URLSearchParams();
    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          if (queryParams[key] !== undefined && queryParams[key] !== null) {
            params.append(key, queryParams[key]);
          }
        }
      }
      url = `${url}?${params.toString()}`;
    }
    options.headers = this.setHttpHeader(options.headers || {});
    return client.get(url, {
      ...this.defautOptions,
      ...options,
      responseType: options.responseType || "json"
    });
  }

  post({ url, body, queryParams, options = {} }) {
    const params = new URLSearchParams();
    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          if (queryParams[key] !== undefined && queryParams[key] !== null) {
            params.append(key, queryParams[key]);
          }
        }
      }
      url = `${url}?${params.toString()}`;
    }
    options.headers = this.setHttpHeader(options.headers || {});
    return client.post(url, body, {
      ...this.defautOptions,
      ...options,
      responseType: options.responseType || "json"
    });
  }

  put({ url, body, queryParams, options = {} }) {
    const params = new URLSearchParams();
    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          if (queryParams[key] !== undefined && queryParams[key] !== null) {
            params.append(key, queryParams[key]);
          }
        }
      }
      url = `${url}?${params.toString()}`;
    }
    options.headers = this.setHttpHeader(options.headers || {});
    return client.put(url, body, {
      ...this.defautOptions,
      ...options,
      responseType: options.responseType || "json"
    });
  }

  upload({ url, body }) {
    return client.post(url, body, {
      withCredentials: true
    });
  }

  delete({ url, body, queryParams, options = {} }) {
    const params = new URLSearchParams();
    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          if (queryParams[key] !== undefined && queryParams[key] !== null) {
            params.append(key, queryParams[key]);
          }
        }
      }
      url = `${url}?${params.toString()}`;
    }
    options.headers = this.setHttpHeader(options.headers || {});
    return client.delete(url, {
      ...this.defautOptions,
      ...options,
      data: body,
      responseType: options.responseType || "json"
    });
  }
}

const base = new BaseService();

export default base;

// interface IRequestOptions {
//   url: string;
//   body?: any;
//   queryParams?: { [param: string]: any };
//   options?: IOptions;
// }

// interface IOptions {
//   headers?: {
//     [header: string]: string | string[];
//   };
//   responseType?: 'json' | 'arraybuffer' | 'blob' | 'text';
//   withCredentials?: boolean;
// }
