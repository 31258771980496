/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import Dropdown from "react-dropdown";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Switch from "react-switch";
import makeAnimated from "react-select/animated";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  getSubscriptionPlanById,
  getSubscriptionPlans,
  updateSubscription
} from "../../../redux/actions/subscriptions";
import { required, isValidURL, isEmpty } from "../../../utils/validations";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import "./plans.scss";

const animatedComponents = makeAnimated();

const customStyles = {
  control: base => ({
    ...base,
    minHeight: 44
  })
};

class Plan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription_id: "",
      payment_url: "",
      sports_type_arr: [],
      published: false,

      errors: {}
    };
    this.validations = {
      payment_url: [required, isValidURL("Invalid payment URL")]
    };
  }
  componentDidMount() {
    const { plans } = this.props;
    if (plans.length === 0) {
      this.getPlans();
    }
    this.getPlan();
  }

  getPlans = () => {
    this.props.getSubscriptionPlans({});
  };

  getPlan = () => {
    const { match } = this.props;
    this.props.getSubscriptionPlanById({
      id: match.params.id,
      cb: res => {
        const { id, plan_name, payment_url, sports_type, published } = res[0];
        const arr = sports_type.map(item => ({
          label: item.name,
          value: item.id
        }));
        this.setState({
          subscription_id: id,
          plan_name: plan_name,
          payment_url,
          sports_type_arr: arr,
          published
        });
      }
    });
  };

  handleValidation = () => {
    let isValid = true;
    const errors = { ...this.state.errors };
    const keys = Object.keys(this.validations);
    keys.forEach(key => {
      const list = this.validations[key];
      if (!isEmpty(list)) {
        const length = list.length;
        for (let i = 0; i < length; i++) {
          const message = list[i](this.state[key]);
          if (message) {
            errors[key] = message;
            isValid = false;
            break;
          }
          errors[key] = null;
        }
      }
    });
    this.setState({
      errors
    });
    return isValid;
  };

  handleChange = key => e => {
    const value = e.target.value;
    this.setState(
      {
        [key]: value
      },
      this.handleValidation
    );
  };

  handleSelectCategories = list => {
    this.setState({ sports_type_arr: list });
  };

  handleChecked = checked => {
    this.setState({ published: checked });
  };

  handleSave = () => {
    if (this.handleValidation()) {
      const {
        subscription_id,
        plan_name,
        payment_url,
        sports_type_arr,
        published
      } = this.state;
      let arr = sports_type_arr
        ? sports_type_arr.map(item => ({
            id: item.value,
            name: item.label
          }))
        : [];
      arr = arr.map(sport => {
        if (sport.id === sport.name) {
          delete sport.id;
        }
        return sport;
      });
      const data = {
        subscription_id,
        plan_name,
        payment_url,
        sports_type_arr: arr,
        published
      };

      this.props.updateSubscription(data);
    }
  };

  handleCopy = () => {
    this.setState({ copied: true }, () => {
      setTimeout(() => {
        this.setState({ copied: false });
      }, 4000);
    });
  };

  render() {
    const { match, categories, plans, plan } = this.props;
    const {
      plan_name,
      payment_url,
      sports_type_arr,
      published,
      errors,
      copied
    } = this.state;
    const opts = plans.map(item => ({
      label: item.plan_name,
      value: item.id
    }));
    return (
      <div className="plan_container">
        {plan && (
          <div className="col-xl-6 col-lg-7 col-md-9 col-sm-12">
            <h4>Update plan ( {plan_name} ) </h4>
            {/* <div className="pb-3 mt-4 d-flex align-items-center">
              <label className="col-xl-4 col-lg-5 col-md-6 col-sm-12 px-0 font-weight-bold">
                Payment Name:
              </label>
              <div className="d-inline-block col-xl-8 col-lg-7 col-lg-6 col-sm-12 px-0">
                <div className="position-relative">
                  <input
                    type="text"
                    name="payment_url"
                    value={plan_name}
                    className="w-100 py-2 pl-2 pr-12 my-2"
                    onChange={this.handleChange("plan_name")}
                  />
                </div>
              </div>
            </div> */}
            <div className="pb-3 mt-4 d-flex align-items-center">
              <label className="col-xl-4 col-lg-5 col-md-6 col-sm-12 px-0 font-weight-bold">
                Payment URL:
              </label>
              <div className="d-inline-block col-xl-8 col-lg-7 col-lg-6 col-sm-12 px-0">
                <div className="position-relative">
                  <input
                    type="text"
                    name="payment_url"
                    value={payment_url}
                    className="w-100 py-2 pl-2 pr-12 my-2"
                    disabled
                    // onChange={this.handleChange("payment_url")}
                  />
                  <CopyToClipboard text={payment_url} onCopy={this.handleCopy}>
                    <i
                      onClick={this.copyToClipboard}
                      className={`material-icons content_copy ${
                        copied ? "copied" : ""
                      }`}
                    >
                      content_copy
                    </i>
                  </CopyToClipboard>
                </div>
                <div className="error-message invalid pt-1">
                  {errors.payment_url}
                </div>
              </div>
            </div>
            <div className="pb-3" style={{ height: "75px" }}>
              <label className="col-xl-4 col-lg-5 col-md-6 col-sm-12 px-0 font-weight-bold">
                Subscription plan:
              </label>
              <Dropdown
                placeholder="Plan"
                className="col-xl-8 col-lg-7 col-lg-6 col-sm-12 px-0 py-2 d-inline-block"
                options={opts}
                value={match.params.id}
                disabled={true}
              />
            </div>
            <div className="pb-3">
              <label className="col-xl-4 col-lg-5 col-md-6 col-sm-12 px-0 font-weight-bold">
                Sports types:
              </label>
              <CreatableSelect
                placeholder="Select sports categories"
                className="col-xl-8 col-lg-7 col-lg-6 col-sm-12 px-0 py-2 d-inline-block"
                name="sports_type_arr"
                value={sports_type_arr}
                options={categories}
                isMulti
                closeMenuOnSelect={false}
                isClearable={false}
                components={animatedComponents}
                styles={customStyles}
                onChange={this.handleSelectCategories}
              />
            </div>
            <div
              className="pb-3 flex align-items-center"
              style={{ height: "75px" }}
            >
              <label className="col-xl-4 col-lg-5 col-md-6 col-sm-12 px-0 font-weight-bold">
                Published:
              </label>
              <Switch onChange={this.handleChecked} checked={published} />
            </div>
            <div className="float-right">
              <button className="btn btn-orange" onClick={this.handleSave}>
                Update
              </button>
            </div>
          </div>
        )}
        <div className="back_container" style={{ left: "10px" }}>
          <Link to={"/plans"} className="back_btn">
            <FiArrowLeft size={18} color="white" />
            <span className="action_name pl-2">Go back</span>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.auth.categories,
  plan: state.subscription.plan,
  plans: state.subscription.plans
});

const mapDispatchToProps = {
  getSubscriptionPlans: getSubscriptionPlans,
  getSubscriptionPlanById: getSubscriptionPlanById,
  updateSubscription: updateSubscription
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plan);
