/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
import React from "react";
import Dropdown from "react-dropdown";
import TemplateCard from "../templateCard/TemplateCard";
import { Link } from "react-router-dom";
import { sortBy } from "../../../utils/utils";
import "./templateGroup.scss";
import AppCarousel from "../carousel/appCarousel";
import GModal from "../modal/GModal";
import ExportImage from "../exportImage/ExportImage";

const sortTypes = [
  {
    label: "Updated time (desc)",
    value: ["updated_at", "desc"]
  },
  {
    label: "Updated time (asc)",
    value: ["updated_at", "asc"]
  },
  {
    label: "Name (desc)",
    value: ["name", "desc"]
  },
  {
    label: "Name (asc)",
    value: ["name", "asc"]
  }
];

export default class TemplateGroup extends React.Component {
  constructor(props) {
    super(props);
    const { templates } = props;
    this.state = {
      templates: [...templates],
      selectedOption: {
        label: "Updated time (desc)",
        value: ["update_at", "desc"]
      },
      exportTemplate: null,
      isOpen: false,
      windowWidth: window.innerWidth,
      slideSetting: {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        lazyLoad: "ondemand",
        responsive: [
          {
            breakpoint: 2250,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5
            }
          },
          {
            breakpoint: 1699,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
          {
            breakpoint: 1399,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { templates: oldT } = prevProps;
    const { templates: newT } = this.props;

    if (oldT.length !== newT.length) {
      const [key, type] = this.state.selectedOption.value;
      const clonedTemplates = newT.slice();
      clonedTemplates.sort(sortBy(key, type));
      this.setState({
        templates: [...clonedTemplates]
      });
    }
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleSort = selectedOption => {
    const [key, type] = selectedOption.value;
    const clonedTemplates = this.state.templates.slice();
    clonedTemplates.sort(sortBy(key, type));

    this.setState({
      selectedOption: selectedOption,
      templates: [...clonedTemplates]
    });
  };

  handleExport = template => {
    this.setState({ isOpen: true, exportTemplate: template });
  };

  closeExportModal = () => {
    this.setState({ isOpen: false, exportTemplate: null });
  };

  // set State Window width
  handleResize = () => this.setState({ windowWidth: window.innerWidth });

  /*viewMoreConditions = () => {
    let setcount = 1;
    if (this.state.windowWidth > 991) {
      setcount = 5;
    } else if (this.state.windowWidth < 991 && this.state.windowWidth >= 600) {
      setcount = 3;
    } else {
      setcount = 2;
    }
    return setcount;
  };*/

  render() {
    const { name, count, type, getList, folderId } = this.props;
    const { templates, selectedOption, isOpen, exportTemplate } = this.state;
    return (
      <React.Fragment>
        <div className="d-flex search-sort-bar">
          <h4 className="group-name m-0">
            <span className="pr-4">
              {name} ({count})
            </span>
            <div className="pt-3 pt-sm-0 px-0">
              <label className="pr-3 sort_label">Sort By:</label>
              <div className="d-inline-block sort_container">
                <Dropdown
                  placeholder="Sort by"
                  options={sortTypes}
                  value={selectedOption}
                  onChange={this.handleSort}
                  menuClassName="menuClassName"
                  placeholderClassName="placeholder"
                />
              </div>
            </div>
          </h4>
          {count > 0 && (
            <div className="navigation-link ml-auto">
              <Link
                to={{
                  pathname: folderId
                    ? `/${type}/${folderId}/category/${encodeURIComponent(
                        name
                      )}`
                    : `/${type}/category/${encodeURIComponent(name)}`,
                  data: { ...this.props }
                }}
                className="view-more-link"
              >
                View All
              </Link>
            </div>
          )}
        </div>

        <div className="mt-4">
          <AppCarousel {...this.props} {...this.state.slideSetting}>
            {templates.map(template => {
              return (
                <div key={template.id} className="h-100">
                  <TemplateCard
                    template={template}
                    type={type}
                    getList={getList}
                    handleExport={() => this.handleExport(template)}
                  />
                </div>
              );
            })}
          </AppCarousel>
          <GModal
            isOpen={isOpen}
            variant="md"
            backdrop={true}
            onClose={this.closeExportModal}
          >
            {isOpen && (
              <ExportImage
                isOpen={isOpen}
                onClose={this.closeExportModal}
                dimensions={exportTemplate.dimensions}
                sizeList={{
                  sm: exportTemplate.download_1x_url,
                  md: exportTemplate.download_2x_url,
                  lg: exportTemplate.download_3x_url,
                  xl: exportTemplate.download_4x_url,
                  mp4: exportTemplate.download_video_url
                }}
              />
            )}
          </GModal>
        </div>
      </React.Fragment>
    );
  }
}
