import React, { useEffect, useState } from "react";
import "./Progress.scss";

const Progress = ({ duration }) => {
  const [style, setStyle] = useState({});
  const [status, setStatus] = useState("");

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `100%`,
      animationDuration: `${duration}ms`,
      animationDelay: "1s",
      animationTimingFunction: "linear"
    };
    setStyle(newStyle);
  }, 1000);

  useEffect(() => {
    var percent = duration / 100;
    var i = 0;
    var statusText = "";
    var interval = setInterval(function() {
      i++;
      if (i >= 0) {
        statusText = "Initializing";
      }
      if (i >= 30) {
        statusText = "Video Generating...";
      }
      if (i >= 80) {
        statusText = "Almost done !!!";
      }
      if (i >= 95) {
        statusText = "Video Generated";
        clearInterval(interval);
      }
      setStatus(statusText);
    }, percent);
  }, []);

  return (
    <div className="progress-bar-wrap">
      <h3>{status}</h3>
      <div className="progress-bar-full">
        <div className="progress-done" style={style} />
      </div>
    </div>
  );
};

export default Progress;
