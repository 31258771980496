/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Dropdown from "react-dropdown";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import ConfirmPopup from "../confirmPopup/ConfirmPopup";
import editIcon from "../../../assets/img/edit-icon.png";
import duplicateIcon from "../../../assets/img/duplicate-icon.png";
import exportIcon from "../../../assets/img/export-icon.png";
import deleteIcon from "../../../assets/img/delete-icon.png";
import moveIcon from "../../../assets/img/move-icon.png";
import useTemplate from "../../../assets/img/tap-icon.png";
import notFound from "../../../assets/img/notFound.png";
import motionIcon from "../../../assets/img/motion-icon.svg";

import {
  createOrUpdateTemplate,
  deleteTemplate,
  copyTemplate
} from "../../../redux/actions/templates";
import {
  createOrUpdateSavedLibrary,
  deleteSavedLibrary,
  copySavedLibrary
} from "../../../redux/actions/savedLibraries";
import "./templateCard.scss";
import HeraldToastify, { ICONS } from "../HeraldToastify/HeraldToastify";

class TemplateCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedGroup: "",
      selectedFolder: "",
      dropdownType: "",
      isDropdownMenu: false,
      imageLoading: true
    };
  }

  toggleModal = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen
    });
  };

  handleEdit = () => {
    const { history, template } = this.props;
    history.push(`/editor/${template.id}`);
  };

  handleDuplicate = () => {
    const { template, getList } = this.props;
    const {
      name,
      colors,
      layer_url,
      download_1x_url,
      download_2x_url,
      download_3x_url,
      download_4x_url,
      preview_url,
      published,
      dimensions,
      template_extension_type
    } = template;
    const data = {
      name: `${name} copy`,
      colors: colors,
      layer_url: layer_url,
      // preview_url: preview_url,
      download_1x_url: download_1x_url,
      download_2x_url: download_2x_url,
      download_3x_url: download_3x_url,
      download_4x_url: download_4x_url,
      template_extension_type: template_extension_type,
      sports_type_id: this.state.selectedGroup,
      dimensions: dimensions,
      published: published
    };
    if (this.state.isDropdownMenu) this.toggleDropdown("duplicate");

    if (template.table_name === "templates") {
      this.props.copyTemplate({ data, cb: getList });
    } else if (template.table_name === "my_templates") {
      this.props.copySavedLibrary({ data, cb: getList });
    }
  };

  handleDelete = () => {
    const onYes = () => {
      const { template, getList } = this.props;
      if (template.table_name === "templates") {
        this.props.deleteTemplate({ id: template.id, cb: getList });
      } else if (template.table_name === "my_templates") {
        this.props.deleteSavedLibrary({ id: template.id, cb: getList });
      }
    };
    const options = {
      title: "Are you sure?",
      message: "This action will delete this template!",
      buttons: [
        {
          label: "Yes, Delete it!",
          onClick: onYes
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmPopup onClose={onClose} {...options} />;
      }
    });
  };

  handleMove = () => {
    const { template, getList } = this.props;
    const { id } = template;
    const data = {
      TemplateId: id,
      folder: this.state.selectedFolder.label
    };

    if (template.table_name === "templates") {
      this.props.createOrUpdateTemplate({ template: data, cb: getList });
    } else if (template.table_name === "my_templates") {
      this.props.createOrUpdateSavedLibrary({ template: data, cb: getList });
    }
    this.setState({ isDropdownMenu: !this.state.isDropdownMenu });
  };

  handleUseTemplate = () => {
    const { template, userColors } = this.props;
    const {
      name,
      colors,
      layer_url,
      sports_type_id,
      download_1x_url,
      download_2x_url,
      download_3x_url,
      download_4x_url,
      preview_url,
      dimensions,
      template_extension_type
    } = template;
    const data = {
      name: name,
      colors: userColors.map((color, index) => color || colors[index]), // replace usercolors
      layer_url: layer_url,
      download_1x_url: download_1x_url,
      download_2x_url: download_2x_url,
      download_3x_url: download_3x_url,
      download_4x_url: download_4x_url,
      template_extension_type: template_extension_type,
      sports_type_id: sports_type_id,
      dimensions: dimensions,
      folder: this.state.selectedFolder.label
    };
    this.props.createOrUpdateSavedLibrary({
      template: data,
      cb: res => {
        toast.success(
          <HeraldToastify
            icon={ICONS.success}
            title="Template copied to my designs successfully"
          />
        );
        this.props.history.push(`/editor/${res.id}`);
      }
    });
  };

  checkDuplicate = () => {
    const { isAdmin, categories, subscribedCategories } = this.props;
    let list = [];
    if (isAdmin) {
      list = [...categories];
    } else {
      list = [...subscribedCategories];
    }
    if (list.length === 1) {
      const selectedOption = list[0];
      this.setState({ selectedGroup: selectedOption.value }, () => {
        this.handleDuplicate();
      });
      this.setState({ isDropdownMenu: !this.state.isDropdownMenu });
    } else {
      this.toggleDropdown("duplicate");
    }
  };

  checkMoveFolder = () => {
    this.toggleDropdown("move");
  };

  checkUseTemplate = () => {
    this.toggleDropdown("use");
  };

  toggleDropdown = (dropdownType = "duplicate") => {
    this.setState({
      isDropdownMenu: !this.state.isDropdownMenu,
      dropdownType: dropdownType,
      selectedGroup: "",
      selectedFolder: ""
    });
  };

  handleCategorySelect = selectedOption => {
    this.setState({ selectedGroup: selectedOption.value });
  };

  handleFolderSelect = selectedOption => {
    this.setState({ selectedFolder: selectedOption });
  };

  getCategoryOptions = () => {
    const { isAdmin, categories, subscribedCategories } = this.props;
    if (isAdmin) {
      return [{ value: "", label: "All Categories" }, ...categories];
    } else {
      return [{ value: "", label: "All Categories" }, ...subscribedCategories];
    }
  };

  getFolderOptions = () => {
    const { isAdmin, templateFolders, myFolders, template, type } = this.props;
    let options = [];
    if (isAdmin) {
      options = [...templateFolders];
    } else {
      options = [...myFolders];
    }
    if ((type === "templates" && isAdmin) || type === "saved_libraries") {
      const filtered = options.filter(item => item.id !== template.folder);
      return filtered; // If templates view in own profile, we need to show filtered options
    } else {
      return options; // If templates view in user side, we need to show all options
    }
  };

  renderDropdown = () => {
    const { dropdownType, selectedFolder, selectedGroup } = this.state;
    if (dropdownType === "duplicate") {
      return (
        <>
          <div className="flex-grow-1">
            <Dropdown
              placeholder="All Categories"
              options={this.getCategoryOptions()}
              value={selectedGroup}
              onChange={this.handleCategorySelect}
              placeholderClassName="placeholder"
            />
          </div>
          <div className="mt-2">
            <button
              className="t-duplicate-action-icon mr-2"
              style={{ color: "green" }}
              onClick={this.handleDuplicate}
              disabled={!selectedGroup}
            >
              <i className="material-icons" style={{ fontSize: 16 }}>
                check
              </i>
            </button>
            <button
              className="t-duplicate-action-icon mr-2"
              onClick={this.toggleDropdown}
              style={{ color: "red" }}
            >
              <i className="material-icons" style={{ fontSize: 16 }}>
                close
              </i>
            </button>
          </div>
        </>
      );
    } else if (dropdownType === "move" || dropdownType === "use") {
      return (
        <>
          <div className="flex-grow-1">
            <Dropdown
              placeholder="Select folder"
              options={this.getFolderOptions()}
              value={selectedFolder}
              onChange={this.handleFolderSelect}
              placeholderClassName="placeholder"
            />
          </div>
          <div className="mt-2">
            <button
              className="t-duplicate-action-icon mr-2"
              style={{ color: "green" }}
              onClick={
                dropdownType === "move"
                  ? this.handleMove
                  : this.handleUseTemplate
              }
              disabled={!selectedFolder}
            >
              <i className="material-icons" style={{ fontSize: 16 }}>
                check
              </i>
            </button>
            <button
              className="t-duplicate-action-icon mr-2"
              onClick={this.toggleDropdown}
              style={{ color: "red" }}
            >
              <i className="material-icons" style={{ fontSize: 16 }}>
                close
              </i>
            </button>
          </div>
        </>
      );
    }
  };

  showLoader = () => {
    return (
      <div className="loader-load">
        <span />
        <span />
        <span />
      </div>
    );
  };

  render() {
    const { template, type, isAdmin } = this.props;
    const { isOpen, isDropdownMenu, imageLoading } = this.state;
    return (
      <React.Fragment>
        <div className="t-card-full d-flex flex-column">
          <div className="t-card-box d-align-middle flex-grow-1">
            <div style={{ display: imageLoading ? "block" : "none" }}>
              {this.showLoader()}
            </div>
            <img
              src={template.download_1x_url || notFound}
              className="t-card-img"
              alt="template_image"
              onLoad={() => this.setState({ imageLoading: false })}
            />
            {template && template.download_video_url && (
              <img
                src={motionIcon}
                className="animated-template"
                title="Animation Template"
                alt="motion-icon"
              />
            )}
          </div>
          <div
            className={`t-card-body ${isDropdownMenu && "t-duplicate-menu"}`}
          >
            {((type === "templates" && isAdmin) ||
              type === "saved_libraries") && (
              <>
                {isDropdownMenu ? (
                  this.renderDropdown()
                ) : (
                  <Fragment>
                    <div className="t-name-heading px-2" title={template.name}>
                      {template.name}
                    </div>
                    <div className="action-bottom px-2 pt-3">
                      <div className="text-center">
                        <div className="t-action-label">Edit</div>
                        <img
                          src={editIcon}
                          alt="edit_icon"
                          className="t-action-icon"
                          onClick={this.handleEdit}
                          title="Edit"
                        />
                      </div>
                      <div className="text-center">
                        <div className="t-action-label">Duplicate</div>
                        <img
                          src={duplicateIcon}
                          alt="duplicate_icon"
                          className="t-action-icon"
                          onClick={this.checkDuplicate}
                          title="Duplicate"
                        />
                      </div>
                      <div className="text-center">
                        <div className="t-action-label">Export</div>
                        <img
                          alt="export_icon"
                          src={exportIcon}
                          className="t-action-icon"
                          onClick={this.props.handleExport}
                          title="Export"
                        />
                      </div>
                      <div className="text-center">
                        <div className="t-action-label">Delete</div>
                        <img
                          alt="delete_icon"
                          src={deleteIcon}
                          className="t-action-icon"
                          onClick={this.handleDelete}
                          title="Delete"
                        />
                      </div>
                      <div className="text-center">
                        <div className="t-action-label">Move</div>
                        <img
                          alt="move_icon"
                          src={moveIcon}
                          className="t-action-icon"
                          onClick={this.checkMoveFolder}
                          title="Move"
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              </>
            )}
            {type === "templates" && !isAdmin && (
              <div className="t-actions user-select-actions">
                {isDropdownMenu ? (
                  this.renderDropdown()
                ) : (
                  <>
                    <div className="t-name-heading px-2" title={template.name}>
                      {template.name}
                    </div>
                    <div className="text-center px-2">
                      <div className="t-action-label">
                        <img
                          alt="use_template"
                          src={useTemplate}
                          className="t-action-icon use-template-icon"
                          onClick={this.checkUseTemplate}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userColors: state.auth.user.colors,
  isAdmin: state.auth.user_role.includes("SUPER_USER"),
  templateFolders: state.template.folders,
  myFolders: state.savedLibrary.folders,
  categories: state.auth.categories,
  subscribedCategories: state.auth.subscribedCategories
});

const mapDispatchToProps = {
  createOrUpdateTemplate: createOrUpdateTemplate,
  deleteTemplate: deleteTemplate,
  copyTemplate: copyTemplate,

  createOrUpdateSavedLibrary: createOrUpdateSavedLibrary,
  deleteSavedLibrary: deleteSavedLibrary,
  copySavedLibrary: copySavedLibrary
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TemplateCard));
