/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */

import React, { Fragment } from "react";
import { connect } from "react-redux";
import "./dashboard.scss";
import { Link } from "react-router-dom";
import { getLatestTemplates } from "../../../redux/actions/templates";
import {
  getLatestSavedLibraries,
  getMyDesignsFolderList
} from "../../../redux/actions/savedLibraries";
import TemplateCard from "../../components/templateCard/TemplateCard";
import AppCarousal from "../../components/carousel/appCarousel";
import GModal from "../../components/modal/GModal";
import ExportImage from "../../components/exportImage/ExportImage";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      exportTemplate: null
    };
  }

  componentDidMount() {
    this.getListOfTemplates();
    this.getListOfLibraries();
    if (this.props.folders.length === 0) {
      this.getFolderList();
    }
  }

  getFolderList = () => {
    this.props.getMyDesignsFolderList(this.setFolder);
  };

  getListOfTemplates = () => {
    this.props.getLatestTemplates();
  };

  getListOfLibraries = () => {
    this.props.getLatestSavedLibraries();
  };

  handleExport = template => {
    this.setState({ isOpen: true, exportTemplate: template });
  };

  closeExportModal = () => {
    this.setState({ isOpen: false, exportTemplate: null });
  };

  render() {
    const { templates, saved_libraries, loading } = this.props;
    const { isOpen, exportTemplate } = this.state;

    // eslint-disable-next-line no-console
    console.log(templates);

    const slideSetting = {
      dots: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 2250,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1699,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="dashboard-container px-xl-6 px-lg-5 px-md-5 px-sm-5 px-4">
        <h4>Templates</h4>
        <div className="row m-0 align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-7 col-sm-8 pl-0 sub-heading">
            Choose a template and create a new design
          </div>
          <Link to="/templates" className="ml-sm-auto mt-sm-0 mt-xs-3 view-all">
            View all templates
          </Link>
        </div>
        {templates.length === 0 && !loading && (
          <div className="empty_container">
            <h4>Your subscriptions might be expired or not activated.</h4>
            <h5>
              Please check your <Link to="/subscriptions">subscriptions</Link>
            </h5>
          </div>
        )}
        {templates.length > 0 && !loading && (
          <section className="dashboard-group">
            <AppCarousal {...slideSetting}>
              {templates.map(template => {
                return (
                  <div key={template.id}>
                    <TemplateCard
                      template={template}
                      type="templates"
                      getList={this.getListOfTemplates}
                      handleExport={() => this.handleExport(template)}
                    />
                  </div>
                );
              })}
            </AppCarousal>
          </section>
        )}

        <h4 className="main-heading mt-4">Your Designs</h4>
        <div className="row m-0 align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-7 col-sm-8 pl-0 sub-heading">
            Continue with your existing design
          </div>
          <Link
            to="/saved_libraries/folders"
            className="ml-sm-auto mt-sm-0 mt-xs-3 view-all"
          >
            View all designs
          </Link>
        </div>
        {saved_libraries.length === 0 && !loading && (
          <div className="empty_container">
            <h5>No templates found</h5>
          </div>
        )}
        {saved_libraries.length > 0 && !loading && (
          <section className="dashboard-group pb-4">
            <AppCarousal {...slideSetting}>
              {saved_libraries.map(template => (
                <div key={template.id}>
                  <TemplateCard
                    template={template}
                    type="saved_libraries"
                    getList={this.getListOfLibraries}
                    handleExport={() => this.handleExport(template)}
                  />
                </div>
              ))}
            </AppCarousal>
          </section>
        )}
        <GModal
          isOpen={isOpen}
          variant="md"
          backdrop={true}
          onClose={this.closeExportModal}
        >
          {isOpen && (
            <ExportImage
              isOpen={isOpen}
              onClose={this.closeExportModal}
              dimensions={exportTemplate.dimensions}
              sizeList={{
                sm: exportTemplate.download_1x_url,
                md: exportTemplate.download_2x_url,
                lg: exportTemplate.download_3x_url,
                xl: exportTemplate.download_4x_url,
                mp4: exportTemplate.download_video_url
              }}
            />
          )}
        </GModal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  folders: state.savedLibrary.folders,
  member: state.auth.member,
  isAdmin: state.auth.user_role.includes("SUPER_USER"),
  loading: state.template.loading || state.savedLibrary.loading,
  templates: state.template.templates || [],
  saved_libraries: state.savedLibrary.templates || []
});

const mapDispatchToProps = {
  getMyDesignsFolderList: getMyDesignsFolderList,
  getLatestTemplates: getLatestTemplates,
  getLatestSavedLibraries: getLatestSavedLibraries
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
