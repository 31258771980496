import { createStore, applyMiddleware, compose } from "redux";
import IReducers from "./reducers";
import IMiddleware from "./middlewares/customMiddleware";
// import { client } from "../apollo";
import { client } from "./apiClient";

const isDev = process.env.NODE_ENV === "development";
const composedEnhancers =
  isDev && !!window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const IStore = createStore(
  IReducers,
  composedEnhancers(applyMiddleware(IMiddleware(client)))
);

// IStore.subscribe(() => {
//   console.log(IStore.getState());
// });

export default IStore;
