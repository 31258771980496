/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import {
  getPendingOnBoardUserSubscription,
  onBoardUser
} from "../../../redux/actions/auth";
import "./pendingOnboard.scss";
import { cancelSubscriptionPlan } from "../../../redux/actions/subscriptions";
import { confirmAlert } from "react-confirm-alert";
import ConfirmPopup from "../../components/confirmPopup/ConfirmPopup";

class PendingOnboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: []
    };

    this.filterParams = {
      buttons: ["reset"],
      debounceMs: 500,
      suppressAndOrCondition: true
    };

    this.columnDefs = [
      {
        headerName: "Name",
        field: "name"
      },
      {
        headerName: "Email",
        field: "email"
      },
      {
        headerName: "Plan name",
        field: "plan_name"
      },
      {
        headerName: "Plan Cost",
        field: "plan_cost"
      },
      {
        headerName: "Plan duration",
        field: "recurring_type",
        valueFormatter: this.getPlanDuration
      },
      {
        headerName: "Action",
        cellRenderer: params => {
          const plan = params.data;
          var eDiv = document.createElement("div");
          eDiv.innerHTML = `<button class="btn btn-orange mr-2 p-2">${
            plan.status
          }</button>
                  <button class="btn btn-white mr-4 p-2">Cancel</button>`;
          var onBoardBtn = eDiv.querySelectorAll(".btn-orange")[0];
          var onCancelBtn = eDiv.querySelectorAll(".btn-white")[0];

          onBoardBtn.addEventListener("click", () => {
            this.handleOnboard(plan);
          });

          onCancelBtn.addEventListener("click", () => {
            this.handleCancel(plan);
          });

          return eDiv;
        }
      }
    ];

    this.defaultColumnDef = {
      sortable: true,
      resizable: true,
      suppressMenu: true,
      cellClass: "grid-cell-centered",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      filterParams: { ...this.filterParams }
    };
  }

  componentDidMount() {
    this.getOnBoardList();
  }

  getOnBoardList = () => {
    this.props.getPendingOnBoardUserSubscription({
      cb: data => {
        this.setState({
          rowData: [...data]
        });
      }
    });
  };

  getPlanDuration = ({ value }) => {
    switch (value) {
      case "weeks":
        return "weekly";
      case "months":
        return "monthly";
      case "years":
        return "yearly";
      default:
        return "";
    }
  };

  handleOnboard = plan => {
    this.props.onBoardUser(plan.user_id, () => {
      this.getOnBoardList();
    });
  };

  handleCancel = plan => {
    const onYes = () => {
      const data = {
        subscription_id: plan.id
      };
      this.props.cancelSubscriptionPlan({
        user_id: plan.user_id,
        data,
        cb: this.getOnBoardList
      });
    };
    const options = {
      title: "Are you sure?",
      message: "This action will cancel user subscription!",
      buttons: [
        {
          label: "Yes, Cancel it!",
          onClick: onYes
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmPopup onClose={onClose} {...options} />;
      }
    });
  };

  render() {
    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: "calc(100vh - 75px)",
          overflowX: "hidden"
        }}
      >
        <AgGridReact
          columnDefs={this.columnDefs}
          defaultColDef={this.defaultColumnDef}
          rowData={this.state.rowData}
          suppressCellSelection={true}
          rowHeight={75}
          onGridReady={params => {
            this.gridApi = params.api;
            this.gridApi.sizeColumnsToFit();
          }}
          animateRows={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.auth.users
});

const mapDispatchToProps = {
  getPendingOnBoardUserSubscription: getPendingOnBoardUserSubscription,
  onBoardUser: onBoardUser,
  cancelSubscriptionPlan: cancelSubscriptionPlan
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingOnboard);
