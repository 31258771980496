/* eslint-disable no-unused-vars */
import Templates from "./pages/templates/Templates";
import SavedLibraries from "./pages/savedLibraries/SavedLibraries";
import Dashboard from "./pages/dashboard/Dashboard";
import UserProfile from "./pages/userProfle/UserProfile";
import MediaLibrary from "./pages/mediaLibrary/MediaLibrary";
import Editor from "./pages/editor/Editor.jsx";
import Login from "./pages/login/login";
import Signup from "./pages/signup/signup";
import TeamInfo from "./pages/teamInfo/TeamInfo";
import Subscriptions from "./pages/subscriptions/Subscriptions";
import UserSubscriptions from "./pages/userSubscriptions/UserSubscriptions";
import PendingOnboard from "./pages/pendingOnboard/PendingOnboard";
import FAQ from "./pages/faq/faq";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import Plans from "./pages/plans/Plans";
import Plan from "./pages/plans/Plan";
import TemplateFolders from "./pages/templates/folders/TemplateFolders";
import SavedLibraryFolders from "./pages/savedLibraries/folders/SavedLibraryFolders";
import CategoryTemplateList from "./pages/templates/CategoryTemplateList";
import CategorySavedLibraryList from "./pages/savedLibraries/CategorySavedLibraryList";

export const authenticatedRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true
  },
  {
    path: "/template/folders",
    component: TemplateFolders,
    exact: true
  },
  {
    path: "/saved_libraries/folders",
    component: SavedLibraryFolders,
    exact: true
  },
  {
    path: "/templates",
    component: Templates,
    exact: true
  },
  {
    path: "/templates/:id",
    component: Templates,
    exact: true
  },
  {
    path: "/saved_libraries",
    component: SavedLibraries,
    exact: true
  },
  {
    path: "/saved_libraries/:id",
    component: SavedLibraries,
    exact: true
  },
  {
    path: "/templates/category/:name",
    component: CategoryTemplateList,
    exact: true
  },
  {
    path: "/templates/:folderId/category/:name",
    component: CategoryTemplateList,
    exact: true
  },
  {
    path: "/saved_libraries/category/:name",
    component: CategorySavedLibraryList,
    exact: true
  },
  {
    path: "/saved_libraries/:folderId/category/:name",
    component: CategorySavedLibraryList,
    exact: true
  },
  {
    path: "/media-library",
    component: MediaLibrary,
    exact: true
  },
  {
    path: "/editor",
    component: Editor,
    exact: true
  },
  {
    path: "/editor/:id",
    component: Editor,
    exact: true
  },
  {
    path: "/my-profile",
    component: UserProfile,
    exact: true
  },
  {
    path: "/team-info",
    component: TeamInfo,
    exact: true
  },
  {
    path: "/subscriptions",
    component: Subscriptions,
    exact: true
  },
  {
    path: "/user-subscriptions",
    component: UserSubscriptions,
    exact: true
  },
  {
    path: "/pending-onboard",
    component: PendingOnboard,
    exact: true
  },
  {
    path: "/plans",
    component: Plans,
    exact: true
  },
  {
    path: "/plan/:id",
    component: Plan,
    exact: true
  }
];

export const unAuthenticatedRoutes = [
  {
    path: "/login",
    component: Login,
    exact: true
  },
  {
    path: "/signup",
    component: Signup,
    exact: true
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    exact: true
  },
  {
    path: "/faq",
    component: FAQ,
    exact: true
  }
];
