/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import CreatableSelect from "react-select/creatable";
import {
  getAllMediaLibrary,
  addMediaLibrary,
  getUserTags
} from "../../../redux/actions/mediaLibrary";
import searchImg from "../../../assets/img/search@2x.png";
import MediaCard from "../../components/mediaCard/MediaCard";
import GModal from "../../components/modal/GModal";
import "./mediaLibrary.scss";

const tagsOptions = [
  { label: "Basketball", value: "basketball" },
  { label: "Volleyball", value: "volleyball" },
  { label: "Boxing", value: "boxing" },
  { label: "Baseball", value: "baseball" },
  { label: "Wrestling", value: "wrestling" },
  { label: "Football", value: "football" }
];

class MediaLibrary extends React.Component {
  timeoutId = 0;
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      isOpen: false,
      file: null,
      image_url: null,
      selectedTags: new Set(),
      tagList: []
    };
  }

  componentDidMount() {
    this.getMediaList();
    this.props.getUserTags();
  }

  getMediaList = () => {
    const { search, selectedTags } = this.state;
    const filterData = {
      galleryName: search,
      labelName: [...selectedTags]
    };
    this.props.getAllMediaLibrary(filterData, data => {
      const list = data.map(item => ({ id: item.id, src: item.image_url }));
      this.setState({
        galleryList: list
      });
    });
  };

  handleSearch = e => {
    const value = e.target.value;
    this.setState({ search: value }, () => {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.getMediaList();
      }, 500);
    });
  };

  handleSelectTags = tag => {
    const { selectedTags } = this.state;
    if (selectedTags.has(tag)) {
      selectedTags.delete(tag);
    } else {
      if (tag === "all") {
        selectedTags.clear();
        selectedTags.add(tag);
      } else {
        selectedTags.delete("all");
        selectedTags.add(tag);
      }
    }
    this.setState(
      {
        selectedTags: selectedTags
      },
      () => {
        this.getMediaList();
      }
    );
  };

  toggleImport = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      file: null,
      image_url: null,
      tagList: []
    });
  };

  handleUpload = async e => {
    const file = e.target.files[0];
    const { size, type } = file;
    console.log(type, "File Size in kb>>>>>>>>>", size / 1000, "kb");
    const reader = new FileReader();
    reader.onload = e => {
      this.setState({
        file: file,
        image_url: e.target.result
      });
    };
    reader.readAsDataURL(file);
    /*
    if (type === "image/svg+xml") {
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({
          file: file,
          image_url: e.target.result
        });
      };
      reader.readAsDataURL(file);
    } else {
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 500,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(file, options);
      console.log(`compressedFile size ${compressedFile.size / 1000} kB`);
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({
          file: file,
          image_url: e.target.result
        });
      };
      reader.readAsDataURL(compressedFile);
    }
    */
  };

  handleChange = newValue => {
    this.setState({
      tagList: newValue
    });
  };

  handleImport = () => {
    const { tagList, file } = this.state;
    const labels = tagList.map(tag => tag.value.toLowerCase());
    labels.push("all");
    const formData = new FormData();
    formData.append("lableName", labels.join(", "));
    formData.append("Media", file);
    this.props.addMediaLibrary(formData, this.afterSuccess);
    this.toggleImport();
  };

  afterSuccess = () => {
    /**
     * After add/delete media, update list and labels(user tags)
     */
    this.getMediaList();
    this.props.getUserTags();
  };

  tagCard = ({ tag }) => {
    const { selectedTags } = this.state;
    return tag ? (
      <div
        className={`d-inline-block mr-3 mb-3 tag-card ${
          selectedTags.has(tag.toLowerCase()) ? "active" : ""
        }`}
        onClick={() => {
          this.handleSelectTags(tag.toLowerCase());
        }}
      >
        <span className="name text-capitalize">{tag}</span>
      </div>
    ) : null;
  };

  render() {
    const { search, file, isOpen } = this.state;
    const { list, tags } = this.props;

    const tagSet = new Set(tags);
    const filteredTags = [...tagSet];
    return (
      <section className="media-container px-xl-6 px-lg-5 px-md-5 px-sm-5 px-4">
        <h4>Media Library</h4>
        <div className="row m-0 align-items-center py-3">
          <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-xs-12 px-0 position-relative search-container">
            <input
              type="text"
              className="search-box"
              value={search}
              placeholder="Search by name"
              onChange={this.handleSearch}
            />
            <img alt="search_icon" src={searchImg} className="search-icon" />
          </div>
          <button
            className="btn btn-orange ml-sm-auto mt-sm-0 mt-xs-3 col-sm-auto"
            onClick={this.toggleImport}
          >
            Import Images
          </button>
        </div>
        {filteredTags.length > 0 && (
          <div className="mb-3">
            <h6>Tags:</h6>
            <section className="row m-0">
              {filteredTags.map((tag, index) => (
                <this.tagCard tag={tag} key={index} />
              ))}
            </section>
          </div>
        )}
        <div className="media--group">
          {list.map((media, index) => (
            <div className="" key={media.id}>
              <MediaCard media={media} getList={this.afterSuccess} />
            </div>
          ))}
        </div>
        <GModal isOpen={isOpen} isClose={false} variant="lg">
          <div className="import-container">
            <h5>Import images</h5>
            <div className="drop-box container mb-3">
              <div className="box d-sm-block d-none">
                <div className="drag_text">
                  {file && file.name ? file.name : "Drag a file here"}
                  <br />
                  Or
                </div>
                <button className="btn btn-orange">
                  Select a file from your device
                </button>
              </div>
              <div className="box d-sm-none d-block">
                <button className="btn btn-orange">Choose file</button>
              </div>
              <input
                type="file"
                accept="image/*"
                className="file-upload"
                title=""
                onChange={this.handleUpload}
              />
            </div>
            <h6>Add tags</h6>
            <div className="width-50">
              <CreatableSelect
                value={this.state.tagList}
                isMulti
                onChange={this.handleChange}
                options={tagsOptions}
              />
            </div>
            <div className="row m-0 justify-content-end mt-3">
              <button
                className="btn btn-white col-sm-auto mt-3 mt-sm-0"
                onClick={this.toggleImport}
              >
                Cancel
              </button>
              <button
                disabled={!file}
                className="btn btn-orange col-sm-auto ml-sm-4 mt-3 mt-sm-0"
                onClick={this.handleImport}
              >
                Import
              </button>
            </div>
          </div>
        </GModal>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  list: state.media.mediaLibraries,
  tags: state.media.userTags
});

const mapDispatchToProps = {
  addMediaLibrary: addMediaLibrary,
  getAllMediaLibrary: getAllMediaLibrary,
  getUserTags: getUserTags
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaLibrary);

/**
 * Image compression usoing compress.js

  import Compress from "compress.js";
  const compress = new Compress();
  compress
  .compress([file], {
    size: 4, // the max size in MB, defaults to 2MB
    quality: 1, // the quality of the image, max is 1,
    maxWidth: 800, // the max width of the output image, defaults to 1920px
    maxHeight: 500, // the max height of the output image, defaults to 1920px
    resize: true // defaults to true, set false if you do not want to resize the image width and height
  })
  .then(res => {
    // returns an array of compressed images
    const { prefix, data, endSizeInMb, ext } = res[0];
    console.log(ext, "File Size in kb>>>", endSizeInMb * 1000, "kb");
    const base64 = prefix + data;
    this.setState({
      file: file,
      image_url: base64
    });
  });

 * Image compression usoing browser imageCompression
    "browser-image-compression": "^1.0.12",
  const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 500,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(file, options);
      console.log(`compressedFile size ${compressedFile.size / 1000} kB`);
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({
          file: file,
          image_url: e.target.result
        });
      };
      reader.readAsDataURL(compressedFile);
 */
