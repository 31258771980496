/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import Dropdown from "react-dropdown";
import constants from "../../../app.properties";
import GModal from "../../../components/modal/GModal";
import { connect } from "react-redux";
import { SketchPicker } from "react-color";

class CreateTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.colorPresets = {
      default: props.colors,
      ...constants.colorPresets
    };
    const preset = props.canvasPresets[0];
    const group = props.categories[0];
    this.state = {
      page: 1,
      isPickerOpen: false,
      pickerColor: null,
      colorIndex: null,
      activeCanvasPreset: preset || {},
      dimensions: preset ? preset.dimension : [],
      activeColorPreset: "default",

      group: group ? group.value : null,
      colors: [...props.colors]
    };
  }

  componentDidUpdate(prevProps) {
    const { isEditCanvas, canvasPresets, categories } = this.props;
    if (isEditCanvas && this.state.page !== 1) {
      this.setState({
        page: 1
      });
    }
    if (prevProps.canvasPresets.length === 0 && canvasPresets.length > 0) {
      const preset = canvasPresets[0];
      this.setState({
        activeCanvasPreset: preset,
        dimensions: preset.dimension
      });
    }
    if (prevProps.categories.length === 0 && categories.length > 0) {
      const group = categories[0].value;
      this.setState({
        group: group
      });
    }
  }

  ModalHeader = () => {
    const { page } = this.state;
    const { isEditCanvas, onCancel } = this.props;
    const lastPage = isEditCanvas ? 1 : 2;

    return (
      <div className="header">
        <h5>Create Template</h5>
        <div>
          {page > 1 && (
            <button className="btn btn-grey mr-4" onClick={this.moveBackward}>
              Back
            </button>
          )}
          {page < lastPage ? (
            <Fragment>
              <button className="btn btn-grey mr-4" onClick={onCancel}>
                Cancel
              </button>
              <button
                className="btn btn-light-orange"
                onClick={this.moveForward}
              >
                Next
              </button>
            </Fragment>
          ) : (
            <button className="btn btn-light-orange" onClick={this.handleStart}>
              Start
            </button>
          )}
        </div>
      </div>
    );
  };

  RenderCanvasPreset = () => {
    const { group, activeCanvasPreset, dimensions } = this.state;
    const { categories, canvasPresets } = this.props;
    return (
      <div className="row m-0 preset_container">
        <div className="col-6 py-4 pl-4 pr-0">
          <div className="mb-3">
            <div className="sub_heading">Select a category</div>
            <div className="dropdown_container">
              <Dropdown
                placeholder="Select Category"
                options={categories}
                value={group}
                onChange={this.setGroup}
                placeholderClassName="placeholder"
              />
            </div>
          </div>
          <div className="mb-3">
            <div className="sub_heading">
              Select a canvas presets for your template
            </div>
            <div className="row m-0 justify-content-between preset_list pr-4">
              {canvasPresets.map((preset, index) => (
                <div className="mb-4" key={index}>
                  <div
                    className={`preset_card d-align-middle mb-2 ${preset.name ===
                      activeCanvasPreset.name && "active"}`}
                    onClick={() => {
                      this.setCanvasPreset(preset);
                    }}
                  >
                    <div className={`box d-align-middle ${preset.orientation}`}>
                      {preset.text}
                    </div>
                  </div>
                  <div className="preset_name">{preset.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-6 d-align-middle">
          <section>
            <div className="d-flex py-3">
              <div className="col-6 pl-0 pr-5">
                <label>Width</label>
                <input
                  type="number"
                  name="width"
                  value={dimensions[0] || 0}
                  className="resolutions"
                  onChange={this.changeWidth}
                />
              </div>
              <div className="col-6 pl-0 pr-5">
                <label>Height</label>
                <input
                  type="number"
                  name="height"
                  value={dimensions[1] || 0}
                  className="resolutions"
                  onChange={this.changeHeight}
                />
              </div>
            </div>
            <div className="preset_card-lg d-align-middle my-3">
              <div
                className={`box d-align-middle ${
                  activeCanvasPreset.orientation
                }`}
              >
                {activeCanvasPreset.text}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  };

  RenderColorPreset = () => {
    const { colorPresets } = this;
    const { activeColorPreset, colors, isPickerOpen, pickerColor } = this.state;
    return (
      <div className="row m-0 preset_container">
        <div
          className="col-6 py-4 pl-4 pr-0"
          style={{ backgroundColor: "rgba(153, 153, 153, 0.2)" }}
        >
          <div className="mb-3">
            <div className="sub_heading">
              Choose a color preset for your template
            </div>
            <div className="row m-0 justify-content-between preset_list pr-4">
              {Object.keys(colorPresets).map((key, index) => (
                <div className="mb-4" key={index}>
                  <div className="preset_name pl-2">{key}</div>
                  <div
                    className={`colors_card ${key === activeColorPreset &&
                      "active"}`}
                    onClick={() => {
                      this.setColorPreset(key, colorPresets[key]);
                    }}
                  >
                    {colorPresets[key].map((color, index) => (
                      <span
                        key={index}
                        className="color-sm"
                        style={{ backgroundColor: color }}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-6 d-align-middle">
          <div className="colors_card">
            {colors.map((color, index) => (
              <span
                key={index}
                className="color-lg"
                style={{ backgroundColor: color }}
                onClick={() => {
                  this.openPicker(color, index);
                }}
              />
            ))}
            {isPickerOpen && (
              <div className="mt-2 fill_picker">
                <div className="cover" onClick={this.closePicker} />
                <SketchPicker
                  color={pickerColor || "black"}
                  disableAlpha={false}
                  onChangeComplete={this.setPickerColor}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  moveForward = () => {
    this.setState({
      page: this.state.page + 1
    });
  };

  moveBackward = () => {
    this.setState({
      page: this.state.page - 1
    });
  };

  setGroup = ({ value }) => {
    this.setState({
      group: value
    });
  };

  setCanvasPreset = preset => {
    this.setState({
      activeCanvasPreset: preset,
      dimensions: preset.dimension
    });
  };

  setColorPreset = (type, colors) => {
    this.setState({
      activeColorPreset: type,
      colors: colors
    });
  };

  openPicker = (color, index) => {
    this.setState({
      isPickerOpen: true,
      pickerColor: color,
      colorIndex: index
    });
  };

  closePicker = () => {
    const { pickerColor, colors, colorIndex } = this.state;
    const clonedColors = colors.slice();
    clonedColors.splice(colorIndex, 1, pickerColor);
    this.setState({
      isPickerOpen: false,
      pickerColor: null,
      colorIndex: null,
      colors: clonedColors
    });
  };

  setPickerColor = ({ hex, rgb }) => {
    /**
     * Append opacity to hex value
     */
    let a = rgb.a;
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;

    this.setState({
      pickerColor: hex
    });
  };

  handleStart = () => {
    const { group, colors, dimensions } = this.state;
    if (this.props.isEditCanvas) {
      this.props.handleStart({ group, dimensions });
    } else {
      this.props.handleStart({ group, colors, dimensions });
    }
    this.props.onClose();
  };

  changeWidth = event => {
    const value = event.target.value;
    const cloned = this.state.dimensions.slice();
    cloned[0] = Number(value);
    this.setState({
      dimensions: cloned
    });
  };

  changeHeight = event => {
    const value = event.target.value;
    const cloned = this.state.dimensions.slice();
    cloned[1] = Number(value);
    this.setState({
      dimensions: cloned
    });
  };

  render() {
    const { isOpen } = this.props;
    const { page } = this.state;
    return (
      <div>
        <GModal
          isOpen={isOpen}
          onClose={this.handleStart}
          isClose={false}
          variant="lg"
          backdrop={true}
        >
          {isOpen && (
            <div className="create_modal">
              <this.ModalHeader />
              {page === 1 && <this.RenderCanvasPreset />}
              {page === 2 && <this.RenderColorPreset />}
            </div>
          )}
        </GModal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  colors: state.auth.user.colors,
  categories: state.auth.categories,
  canvasPresets: state.template.canvasPresets
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTemplate);
