import React from "react";
import "./confirmPopup.scss";

function ConfirmPopup({ title, message, buttons, onClose }) {
  const [yes, no] = buttons;
  return (
    <div className="custom-ui">
      <h5 className="title">{title || "Are you sure?"}</h5>
      <p className="message">{message || "Do you want this action!!"}</p>
      <div className="text-right">
        <button
          className="btn btn-white mr-4"
          onClick={() => {
            no.onClick();
            onClose();
          }}
        >
          {no.label || "No"}
        </button>
        <button
          className="btn btn-orange"
          onClick={() => {
            yes.onClick();
            onClose();
          }}
        >
          {yes.label || "Yes"}
        </button>
      </div>
    </div>
  );
}

export default ConfirmPopup;
