/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import Dropdown from "react-dropdown";
import "../templates/templates.scss";
import searchImg from "../../../assets/img/search@2x.png";
import TemplateGroup from "../../components/templateGroup/TemplateGroup";
import {
  getAllSavedLibraries,
  getMyDesignsFolderList
} from "../../../redux/actions/savedLibraries";

class SavedLibraries extends React.Component {
  timeoutId = 0;

  constructor(props) {
    super(props);
    const data = JSON.parse(sessionStorage.getItem("filter"));
    sessionStorage.removeItem("filter");
    this.state = {
      name: data ? data.name : "",
      group: data ? data.group : "",
      currentFolder: null
    };
  }

  componentDidMount() {
    const { folders } = this.props;
    this.getListOfTemplates();
    if (folders.length === 0) {
      this.getFolderList();
    } else {
      this.setFolder();
    }
  }

  getFolderList = () => {
    this.props.getMyDesignsFolderList(this.setFolder);
  };

  setFolder = () => {
    const { match, folders } = this.props;
    const folderId = match.params.id || null;
    const folder = folders.filter(item => item.id === folderId);
    this.setState({
      currentFolder: folder[0]
    });
  };

  getListOfTemplates = () => {
    const { name, group } = this.state;
    const data = {
      name: name,
      sport_name: group && group !== "All Categories" ? [group] : [],
      folder: this.props.match.params.id || ""
    };
    this.props.getAllSavedLibraries(data);
  };

  handleSelect = selectedOption => {
    this.setState({ group: selectedOption.label }, () => {
      this.getListOfTemplates();
    });
  };

  handleSearch = e => {
    const value = e.target.value;
    this.setState({ name: value }, () => {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.getListOfTemplates();
      }, 750);
    });
  };

  setFilterData = () => {
    const { name, group } = this.state;
    const data = {
      name: name,
      group: group && group !== "All Categories" ? group : ""
    };
    sessionStorage.setItem("filter", JSON.stringify(data));
  };

  render() {
    const { name, group, currentFolder } = this.state;
    const { subscribedCategories, groupByFolder, loading, match } = this.props;
    const groupedTemplates = groupByFolder[match.params.id || null] || {};
    const groups = Object.keys(groupedTemplates);
    // console.log("groupedTemplates >>>>>>>>>>>", groupedTemplates);
    return (
      <div className="template-container px-xl-6 px-lg-5 px-md-5 px-sm-5 px-4">
        <h4 className="main-heading m-0">
          Your Designs
          {currentFolder && (
            <span className="text-capitalize ml-2">
              {currentFolder ? `(${currentFolder.name})` : ""}
            </span>
          )}
        </h4>
        <section className="row align-items-center py-4 m-0 filter">
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 pl-0 pr-lg-0 pr-sm-3 pr-0 mr-lg-5 dropdown-container">
            <Dropdown
              placeholder="All Categories"
              options={[
                { value: "", label: "All Categories" },
                ...subscribedCategories
              ]}
              value={group}
              onChange={this.handleSelect}
              placeholderClassName="placeholder"
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 px-0 mt-xs-3 position-relative search-container">
            <input
              type="text"
              className="search-box"
              value={name}
              placeholder="Search by name"
              onChange={this.handleSearch}
            />
            <img alt="search_icon" src={searchImg} className="search-icon" />
          </div>
        </section>
        {groups.length === 0 && !loading ? (
          <div className="empty_container mt-4">
            <h5>No templates found</h5>
          </div>
        ) : (
          <Fragment>
            {groups.map(group => (
              <section key={group} className="pt-4">
                <TemplateGroup
                  name={group}
                  count={groupedTemplates[group].length}
                  templates={groupedTemplates[group]}
                  type="saved_libraries"
                  getList={this.getListOfTemplates}
                  folderId={this.props.match.params.id}
                  setFilterData={this.setFilterData}
                />
              </section>
            ))}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  folders: state.savedLibrary.folders,
  groupByFolder: state.savedLibrary.groupByFolder || {},
  loading: state.savedLibrary.loading,
  subscribedCategories: state.auth.subscribedCategories
});

const mapDispatchToProps = {
  getMyDesignsFolderList: getMyDesignsFolderList,
  getAllSavedLibraries: getAllSavedLibraries
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedLibraries);
