/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import GModal from "../../../components/modal/GModal";
import logo from "../../../../assets/img/logo_orange@2x.png";
import editIcon from "../../../../assets/img/edit@2x.png";
import downloadIcon from "../../../../assets/img/download@2x.png";
import exportIcon from "../../../../assets/img/export@2x.png";
import shareIcon from "../../../../assets/img/share@2x.png";
import ExportImage from "../../../components/exportImage/ExportImage";
import moment from "moment";
import { shareTemplateToTwitter } from "../../../../redux/actions/savedLibraries";
import {
  getInstaSocialProfiles,
  postInstaSocialShare
} from "../../../../redux/actions/auth";

class EditorHeader extends React.Component {
  nameBox;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      action: null,
      comments: "",
      shareCategory: "image"
    };
    this.onChangeShareCategory = this.onChangeShareCategory.bind(this);
  }

  componentDidMount() {
    this.props.getInstaSocialProfiles();
  }

  componentDidUpdate({ name: pName }) {
    if (pName !== this.props.name) {
      this.expandInputBox();
    }
  }

  toggleExport = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
      action: "export"
    });

    !isOpen && this.deSelectNode();
  };

  //  Open Instagram dialog to Share
  toggleInstaShareDialog = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: isOpen,
      action: "instagram",
      comments: this.props.name
    });
    isOpen && this.deSelectNode();
  };

  toggleShare = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
      action: "share"
    });
  };

  deSelectNode = () => {
    const { selectedShapeIndex } = this.props;
    /**
     * Deselect the shape if selected before Download
     */
    if (selectedShapeIndex != null) {
      this.props.setSelectedShape(null);
    }
  };

  openPopup = url => {
    window.open(
      url,
      "popup",
      "width=600,height=600,scrollbars=no,resizable=no"
    );
  };

  shareToFacebook = () => {
    const { template_preview } = this.props;
    this.toggleShare("share");
    /*
      const sharing_message = "Design & Publish Professional Team Graphics at https://goeditgraphics.com";
      //Do you want some quote message while upload append this in url quote=${encodeURIComponent(sharing_message)} 
    */
    const facebookUrl = `http://www.facebook.com/sharer.php?u=${encodeURIComponent(
      template_preview
    )}`;
    this.openPopup(facebookUrl);
  };

  shareToTwitter = () => {
    const { id } = this.props;
    this.toggleShare("share");
    this.props.shareTemplateToTwitter(id, data => {
      const template_preview =
        data.entities && data.entities.media[0]
          ? data.entities.media[0].display_url
          : "";
      // const sharing_message = `Design & Publish Professional Team Graphics at https://goeditgraphics.com/ ${template_preview}`;
      const twitterUrl = `https://twitter.com/compose/tweet?text=${encodeURIComponent(
        template_preview
      )}`;
      this.openPopup(twitterUrl);
    });
  };

  //  Init Instagram Api function
  connectInstagramAccount = () => {
    const { history } = this.props;
    localStorage.setItem("previous-page", history.location.pathname);
    const url = `${window.location.origin}/my-profile`;
    window.location.href = `https://www.facebook.com/v3.2/dialog/oauth?response_type=code&redirect_uri=${encodeURIComponent(url)}&scope=public_profile%2Cinstagram_content_publish%2Cinstagram_manage_comments%2Cinstagram_basic%2Cpages_show_list&client_id=679472346634770`;
  };

  shareToInstagram = () => {
    const { template_preview, sizeList, profiles } = this.props;
    const { isOpen, shareCategory } = this.state;
    const profile_id =
      profiles.Profiles && profiles.Profiles[0]
        ? profiles.Profiles[0].profile_id
        : "";
    const shareOptions = {
      content: this.state.comments,
      profile_id: profile_id
    };
    if (shareCategory === "video") {
      shareOptions.video = sizeList.mp4;
    } else {
      shareOptions.image = template_preview;
    }
    try {
      this.props.postInstaSocialShare(shareOptions, response => {
        if (response && response.status) {
          this.setState({
            comments: "",
            isOpen: !isOpen,
            action: null
          });
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error =>", error);
    }
  };

  handleExport = () => {
    const { isChanges } = this.props;
    if (isChanges) {
      this.props.handleSave(this.toggleExport);
    } else {
      this.toggleExport();
    }
  };

  handleShare = () => {
    const { isChanges } = this.props;
    if (isChanges) {
      this.props.handleSave(this.toggleShare);
    } else {
      this.toggleShare();
    }
  };

  saveTeplate = event => {
    this.props.handleSave();
  };

  expandInputBox = e => {
    if (this.nameBox) {
      const value = e ? e.target.value : this.props.name;
      let width = (value.length + 1) * 10;
      if (width < 200) width = 200;
      else if (width > 600) width = 600;
      this.nameBox.style.width = width + "px";
    }
  };

  onChangeShareCategory(event) {
    const changeValue = event.target.value;
    this.setState({
      shareCategory: changeValue
    });
  }

  render() {
    const {
      isAdmin,
      name,
      published,
      updated_at,
      dimensions,
      updateName,
      updateStatus,
      isChanges,
      isSaved,
      history,
      // stage,
      sizeList,
      template_preview,
      profiles
    } = this.props;
    const { isOpen, action, shareCategory } = this.state;

    return (
      <div className="editor-header-container pl-lg-5 pl-md-4 pl-sm-3 pl-2 py-2">
        <Link onClick={history.goBack} to="" className="navbar-brand">
          <img alt="logo" src={logo} className="brand-logo" />
        </Link>
        <div className="position-relative mr-4">
          <input
            ref={node => (this.nameBox = node)}
            type="text"
            className="name-box"
            name="name"
            value={name}
            onChange={updateName}
          />
          <img
            alt="edit_icon"
            src={editIcon}
            className="edit"
            onClick={this.toggleEdit}
          />
        </div>
        <div className="row m-0 justify-content-end flex-wrap ml-auto">
          <div className="last_saved">
            {updated_at && (
              <div className="mt-3 mr-4">
                Last saved {moment(updated_at).format("MMM Do hh:mm A")}
              </div>
            )}
            {isSaved && !isChanges ? (
              <button
                className="btn btn-grey mr-4 mt-2"
                disabled={!isChanges}
                onClick={this.saveTeplate}
              >
                Saved
              </button>
            ) : (
              <button
                className="btn btn-save mr-4 mt-2"
                disabled={!isChanges}
                onClick={this.saveTeplate}
              >
                Save
              </button>
            )}
          </div>
          <button
            className="btn-override p-0 text-center mr-4 mt-2"
            onClick={this.handleExport}
            disabled={!sizeList.sm}
          >
            <img alt="download_icon" src={downloadIcon} className="action" />
            <div className="action-name">Export</div>
          </button>
          {!isAdmin && (
            <button
              className="btn-override p-0 text-center mr-4 mt-2"
              onClick={this.handleShare}
            >
              <img alt="download_icon" src={shareIcon} className="action" />
              <div className="action-name">Share</div>
            </button>
          )}
          {isAdmin && (
            <button
              className="btn-override p-0 text-center mr-4 mt-2"
              onClick={updateStatus}
            >
              <img alt="export_icon" src={exportIcon} className="action" />
              <div className="action-name">
                {published ? "Unpublish" : "Publish"}
              </div>
            </button>
          )}
        </div>
        <GModal
          isOpen={isOpen}
          onClose={action === "export" ? this.toggleExport : this.toggleShare}
          variant={action === "export" || action === "instagram" ? "md" : "sm"}
          isClose={false}
          backdrop={true}
        >
          {isOpen && action === "export" && (
            <ExportImage
              isOpen={isOpen}
              onClose={this.toggleExport}
              dimensions={dimensions}
              // stage={stage}
              name={name}
              sizeList={sizeList}
            />
          )}
          {isOpen && action === "share" && (
            <div className="d-flex justify-content-around editor-social-share">
              <span
                className="text-center cursor-pointer facebook-icon"
                onClick={this.shareToFacebook}
              >
                <i className="fa fa-facebook" />
              </span>
              {profiles &&
              Object.keys(profiles).length > 0 &&
              profiles.status ? (
                <img
                  src={profiles.Profiles[0].profile_picture_url}
                  alt={profiles.Profiles[0].account_name}
                  title="Instagram"
                  onClick={this.toggleInstaShareDialog}
                />
              ) : (
                <span
                  className="text-center cursor-pointer instagram-icon"
                  onClick={this.connectInstagramAccount}
                >
                  <i className="fa fa-instagram" />
                </span>
              )}
              <span
                className="text-center cursor-pointer twitter-icon"
                onClick={this.shareToTwitter}
              >
                <i className="fa fa-twitter" />
              </span>
            </div>
          )}
          {isOpen && action === "instagram" && (
            <div className="d-flex justify-content-around instagram-share-dialog">
              <div className="insta-dialog-body">
                <div className="post-form">
                  <div className="preview-img">
                    <img src={template_preview} alt="" />
                  </div>
                  {sizeList && sizeList.mp4 && (
                    <div className="export-select-wrap">
                      <h3>Share as</h3>
                      <div className="share-select-category">
                        <input
                          type="radio"
                          name="shareCategory"
                          value="image"
                          id="image"
                          checked={shareCategory === "image"}
                          onChange={this.onChangeShareCategory}
                        />
                        <label htmlFor="image"> Image</label>
                        <input
                          type="radio"
                          name="shareCategory"
                          value="video"
                          id="video"
                          checked={shareCategory === "video"}
                          onChange={this.onChangeShareCategory}
                        />
                        <label htmlFor="video">Video</label>
                      </div>
                    </div>
                  )}
                  <div className="comment-form-info">
                    <textarea
                      value={this.state.comments}
                      name="comments"
                      onChange={e =>
                        this.setState({ comments: e.target.value })
                      }
                      placeholder="write a comment"
                    />
                  </div>
                </div>
              </div>
              <div className="insta-dialog-bottom">
                <button onClick={this.shareToInstagram}>Share</button>
              </div>
            </div>
          )}
        </GModal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.auth.user_role.includes("SUPER_USER"),
  profiles: state.socialAuth.profiles
});

const mapDispatchToProps = {
  shareTemplateToTwitter: shareTemplateToTwitter,
  getInstaSocialProfiles: getInstaSocialProfiles,
  postInstaSocialShare: postInstaSocialShare
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorHeader);
