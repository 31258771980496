/* eslint-disable no-unused-vars */
import React, { memo } from "react";
import viewIcon from "../../../../assets/img/eye@2x.png";
import replaceIcon from "../../../../assets/img/replace@2x.png";

const ImageCard = memo(
  ({
    card,
    index,
    selectedShapeIndex,
    selectedType,
    previousIndex,
    moveUp,
    handleReplace,
    resetToOriginal
  }) => {
    let hide = false;
    if (!card.listening) {
      /**
       * Hide the locked images.They are not allowed to change
       */
      return null;
    } else if (selectedType === "image" && selectedShapeIndex !== index) {
      /**
       * If we select any image, then show that only image
       * So that its easy for user to find out the right item
       */
      hide = true;
    }
    return (
      <div className={`i-card d-align-middle ${hide ? "d-none" : ""}`}>
        <img src={card.src} alt="pic" />
        <div className="i-hover">
          {previousIndex === null ? (
            <div className="i-actions">
              <div className="text-center">
                <div className="i-action-label">View</div>
                <img
                  src={viewIcon}
                  alt="edit_icon"
                  className="i-action-icon"
                  onClick={() => {
                    moveUp(index);
                  }}
                />
              </div>
              <div className="text-center">
                <div className="i-action-label">Replace</div>
                <img
                  src={replaceIcon}
                  alt="edit_icon"
                  className="i-action-icon"
                  onClick={() => {
                    handleReplace(index);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="i-actions">
              <button className="btn btn-done" onClick={resetToOriginal}>
                Done
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ImageCard;
