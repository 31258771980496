/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./home/app";
import IStore from "./redux/store";
import AppLoader from "./home/components/loader/AppLoader";
import * as serviceWorker from "./serviceWorker";
import "./assets/fonts/index"; // to load list of google fonts via npm(webfontloader)

import "../node_modules/jquery/dist/jquery.js";
import "../node_modules/popper.js/dist/esm/popper.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../node_modules/react-select-search/style.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "./styles.scss";
// import GProvider from "./context";
// import { client } from "./apollo";
// import { ApolloProvider } from "react-apollo";
// import "./assets/scss/styles/common.scss";
// import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
// import GApp from "./g_app";

const history = createBrowserHistory({ basename: "/app/#" });

ReactDOM.render(
  <Provider store={IStore}>
    <Router history={history}>
      <App />
    </Router>
    <AppLoader />
  </Provider>,
  document.getElementById("root")
);
serviceWorker.register();

{
  /* <ApolloProvider client={client}>
<BrowserRouter basename="/app/#" history={history}>
  <App />
</BrowserRouter>
<GApp />
<AppLoader />
</ApolloProvider> */
}
