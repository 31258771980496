export const isObject = obj => !!obj && obj.constructor === Object;
export const isArray = obj => !!obj && obj.constructor === Array;

export const isEmpty = value =>
  value === null ||
  value === undefined ||
  value === "" ||
  (isArray(value) && value.length === 0) ||
  (isObject(value) && Object.keys(value).length === 0);

export function trimTrailingSpace(value) {
  value = value && value.replace(/^\s+/gi, "");
  return value || "";
}

export const required = value => {
  if (value && typeof value === "string") {
    value = trimTrailingSpace(value);
  }
  if (isEmpty(value)) {
    return "Required";
  }
  return null;
};

export const minLength = min => value => {
  if (!isEmpty(value) && value.length < min) {
    return `Min ${min} characters required`;
  }
  return null;
};

export const maxLength = max => value => {
  if (!isEmpty(value) && value.length > max) {
    return `Not more than ${max} characters`;
  }
  return null;
};

export const isNumber = value => {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return `Must be an number`;
  }
  return null;
};

export const minValue = min => value => {
  if (
    !isEmpty(value) &&
    Number.isInteger(Number(value)) &&
    Number(value) < min
  ) {
    return `Value must be greater than ${min}`;
  }
  return null;
};

export const maxValue = max => value => {
  if (
    !isEmpty(value) &&
    Number.isInteger(Number(value)) &&
    Number(value) > max
  ) {
    return `Value must be less than ${max}`;
  }
  return null;
};

export const isMobile = value => {
  const mobileRegex = /^([0-9]{2, 4}|\+[0-9]{1,3})?([0-9]{10})$/;
  if (!isEmpty(value) && !mobileRegex.test(value)) {
    return "Invalid phone number";
  }
  return null;
};

export function isValidEmail(value) {
  const emailRegex = /([\w-]+(?:\.[\w-]+)*)@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (value && !value.match(emailRegex)) {
    return "Enter valid email address";
  }
  return null;
}

export const isValidURL = message => value => {
  const regex = /(http(s)?:\/\/)?([\w-]+\.)+[\w-]+([\w- ;,./?%&=]*)?/i;
  if (value && !value.match(regex)) {
    return message || "Invalid URL";
  }
  return null;
};

export function isDataURL(s) {
  let regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
  return !!s.match(regex);
}
