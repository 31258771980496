/* eslint-disable no-unused-vars */
import React from "react";
import { SketchPicker } from "react-color";
import { connect } from "react-redux";
import Properties from "./Properties";
import Layers from "./Layers";
import ImageCard from "./ImageCard";
import TextCard from "./TextCard";

class EditorSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.isAdmin ? "properties" : "images",
      isPickerOpen: false,
      pickerColor: null,
      selectedColorIndex: null
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedShapeIndex, selectedShape, isAdmin } = this.props;
    if (selectedShape && prevProps.selectedShapeIndex !== selectedShapeIndex) {
      /**
       * Set Active tab only when select shape
       */
      this.setActiveSideTab();
    }
  }

  setActiveSideTab = () => {
    const { selectedShape, isAdmin } = this.props;
    let active = "";
    if (isAdmin) {
      // active = "properties";
    } else {
      const type = (selectedShape.Type || selectedShape.type).toLowerCase();
      if (type === "image") {
        active = "images";
      } else if (type === "text") {
        active = "text";
      }
    }
    /**
     * Set Active tab when its diffrent
     */
    if (active && active !== this.state.activeTab) {
      this.setActiveTab(active);
    }
  };

  Nav = ({ children }) => {
    return <ul className="nav justify-content-around">{children}</ul>;
  };

  NavItem = ({ eventKey, title }) => {
    const { activeTab } = this.state;
    return (
      <li
        className="nav-item"
        onClick={() => {
          this.setActiveTab(eventKey);
        }}
      >
        <a
          className={`nav-link ${activeTab === eventKey ? "active" : ""}`}
          href={`#${eventKey}`}
          data-toggle="tab"
        >
          {title}
        </a>
      </li>
    );
  };

  Tabs = ({ children }) => {
    return <div className="tab-content">{children}</div>;
  };

  Tab = ({ eventKey, children }) => {
    const { activeTab } = this.state;
    return (
      <div
        className={`tab-pane fade ${
          activeTab === eventKey ? "active show" : ""
        }`}
        id={eventKey}
      >
        {children}
      </div>
    );
  };

  setActiveTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  };

  setSelectedColorIndex = (color, index) => {
    const { pickerColor, selectedColorIndex, isPickerOpen } = this.state;
    this.setState({
      pickerColor: color,
      selectedColorIndex: selectedColorIndex === index ? null : index,
      isPickerOpen: selectedColorIndex === index ? !isPickerOpen : true
    });
    if (index === selectedColorIndex) {
      this.props.updateColor(pickerColor, selectedColorIndex);
    }
  };

  setPickerColor = ({ hex, rgb }) => {
    /**
     * Append opacity to hex value
     */
    const { selectedColorIndex } = this.state;
    let a = rgb.a;
    if (a < 1) {
      a = ((a * 255) | (1 << 8)).toString(16).slice(1);
      hex = hex + a;
    }
    this.setState({
      pickerColor: hex
    });
    this.props.updateColor(hex, selectedColorIndex);
  };

  render() {
    const {
      isAdmin,
      isOpen,
      toggleImport,
      selectedShape,
      selectedShapeIndex,
      setSelectedShape,
      updateShapeAttrs,
      stageWidth,
      cards,
      colors,
      previousIndex,
      moveUp,
      handleReplace,
      classNames,
      resetToOriginal
    } = this.props;
    const {
      activeTab,
      isPickerOpen,
      pickerColor,
      selectedColorIndex
    } = this.state;
    let selectedShapeType = "";
    if (selectedShapeIndex !== null) {
      selectedShapeType = (
        cards[selectedShapeIndex].Type || cards[selectedShapeIndex].type
      ).toLowerCase();
    }
    return (
      <div className={`sidebar_drawer ${classNames} ${isOpen ? "" : "hide"}`}>
        {isAdmin && (
          <div className="flex-grow-1 overflow-auto">
            <this.Nav>
              <this.NavItem eventKey="properties" title="Properties" />
              <this.NavItem eventKey="layers" title="Layers" />
            </this.Nav>
            <this.Tabs>
              <this.Tab eventKey="properties">
                <Properties
                  toggleImport={toggleImport}
                  selectedShape={selectedShape}
                  selectedShapeIndex={selectedShapeIndex}
                  updateShapeAttrs={updateShapeAttrs}
                  stageWidth={stageWidth}
                  presetColors={colors}
                />
              </this.Tab>
              <this.Tab eventKey="layers">
                <Layers
                  cards={cards}
                  selectedShapeIndex={selectedShapeIndex}
                  setSelectedShape={setSelectedShape}
                  updateLayer={this.props.updateLayer}
                  updateShapeAttrs={this.props.updateShapeAttrs}
                  addShape={this.props.addShape}
                  removeShape={this.props.removeShape}
                />
              </this.Tab>
            </this.Tabs>
          </div>
        )}
        {!isAdmin && (
          <div>
            <this.Nav>
              <this.NavItem eventKey="images" title="Images" />
              <this.NavItem eventKey="text" title="Text" />
            </this.Nav>
            <this.Tabs>
              <this.Tab eventKey="images">
                <div className="images_tab">
                  {cards.map((card, index) =>
                    (card.type || card.Type).toLowerCase() === "image" ? (
                      <ImageCard
                        key={card.id}
                        card={card}
                        index={index}
                        selectedShapeIndex={selectedShapeIndex}
                        selectedType={selectedShapeType}
                        previousIndex={previousIndex}
                        moveUp={moveUp}
                        handleReplace={handleReplace}
                        resetToOriginal={resetToOriginal}
                      />
                    ) : null
                  )}
                </div>
              </this.Tab>
              <this.Tab eventKey="text">
                {/* Render eerytime when text tab is selected, so that text box is focus 
                 and auto grow based on content  */}
                {activeTab === "text" && (
                  <div className="text_tab">
                    {cards.map((card, index) =>
                      (card.type || card.Type).toLowerCase() === "text" ? (
                        <TextCard
                          key={card.id}
                          card={card}
                          index={index}
                          presetColors={colors}
                          selectedShape={selectedShape}
                          selectedShapeIndex={selectedShapeIndex}
                          setSelectedShape={setSelectedShape}
                          selectedType={selectedShapeType}
                          updateShapeAttrs={updateShapeAttrs}
                        />
                      ) : null
                    )}
                  </div>
                )}
              </this.Tab>
            </this.Tabs>
          </div>
        )}
        <div className="template_colors mt-auto">
          <div className="label">Theme colors</div>
          <div className="d-flex justify-content-lg-between pt-3">
            {colors.map((color, index) => (
              <div
                key={`color-${index}`}
                className={`color mr-4 ${
                  index === selectedColorIndex ? "active" : ""
                }`}
                style={{ background: color }}
                onClick={() => {
                  this.setSelectedColorIndex(color, index);
                }}
              />
            ))}
          </div>
          {isPickerOpen && (
            <div className="mt-3 color_picker">
              <div
                className="cover"
                onClick={() => {
                  this.setSelectedColorIndex(pickerColor, selectedColorIndex);
                }}
              />
              <SketchPicker
                color={pickerColor}
                onChangeComplete={this.setPickerColor}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.auth.user_role.includes("SUPER_USER")
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorSidebar);
