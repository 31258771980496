export const roundOff = (num, dec = 1) => {
  return Math.round(num * 10 ** dec) / 10 ** dec;
};

export const isInt = n => {
  return Number(n) === n && n % 1 === 0;
};

export const isFloat = n => {
  return Number(n) === n && n % 1 !== 0;
};

export const groupBy = (list = [], key) => {
  const grouped = list.reduce((r, c) => {
    r[c[key]] = [...(r[c[key]] || []), c];
    return r;
  }, {});
  return grouped;
};

export const sortBy = (key, order = "asc") => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }
    let varA, varB;

    if (
      typeof a[key] === "string" &&
      typeof b[key] === "string" &&
      Date.parse(a[key]) &&
      Date.parse(b[key])
    ) {
      varA = Date.parse(a[key]);
      varB = Date.parse(b[key]);
    } else {
      varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days);
    expires = date.toUTCString();
  }
  if (expires) {
    document.cookie = `${name}=${value};path=/;expires=${expires};`;
  } else {
    document.cookie = `${name}=${value};path=/;`;
  }
}
export function getCookie(name) {
  const cokkieArray = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return cokkieArray ? cokkieArray[2] : null;
}

export function deleteCookie(name) {
  setCookie(name, "", -1);
}

Array.prototype.move = function(from, to) {
  const arr = this;
  arr.splice(to, 0, arr.splice(from, 1)[0]);
};

Array.prototype.swap = function(i, j) {
  const arr = this;
  [arr[i], arr[j]] = [arr[j], arr[i]];
};

String.prototype.isIEquals = function(value) {
  const str = this;
  const regex = new RegExp(value, "gi");
  return str.match(regex) !== null;
};

export function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  var bHasOwnProperty = hasOwnProperty.bind(objB);
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

export function getDataUrl(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

export const convertDataURIToBinary = dataURI => {
  var BASE64_MARKER = ";base64,";
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

export const convertDataURIToBlob = dataURI => {
  const array = convertDataURIToBinary(dataURI);
  const blob = new Blob([array], { type: "image/png" });

  return blob;
};

function createImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.src = url;
  });
}

export const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    resolve(canvas.toDataURL());
    // canvas.toBlob(file => {
    //   resolve(URL.createObjectURL(file));
    // }, "image/jpeg");
  });
};
