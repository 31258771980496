import base from "./base.service";

export const getListOfMedia = data => {
  return () =>
    base.post({
      url: "/galleries/viewGallery",
      body: data
    });
};

export const insertMedia = data => {
  return () =>
    base.post({
      url: "/galleries/save_media",
      body: data
    });
};

export const deleteMedia = id => {
  return () =>
    base.delete({
      url: `/galleries/trash_media/${id}`
    });
};

export const getUserMediaTags = () => {
  return () =>
    base.get({
      url: "/labels/getLabels"
    });
};

export const getSignedURL = () => {
  return base.get({ url: "/templates/getSignedURL" });
};
