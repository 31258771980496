/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import "./header.scss";
import brandLogo from "../../../assets/img/brand_logo@2x.png";
import profileLogo from "../../../assets/img/profile_logo@2x.png";
import { userLogout } from "../../../redux/actions/auth";
import { getCookie } from "../../../utils/utils";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isLoggedIn = () => {
    return getCookie("Authorization") !== null;
  };

  redirectToHome = () => {
    const { user_role } = this.props;
    if (user_role.length > 0) {
      if (user_role.includes("SUPER_USER")) {
        this.props.history.push("/template/folders");
      } else if (user_role.includes("USER")) {
        this.props.history.push("/saved_libraries/folders");
      }
    } else {
      this.props.history.push("/login");
    }
  };

  render() {
    const { isAdmin, location } = this.props;
    return (
      <section
        className={`header-container ${
          location && location.pathname.includes("/editor") ? "d-none" : ""
        }`}
      >
        <nav className="navbar navbar-expand-lg navbar-light custom-navbar">
          <li
            onClick={this.redirectToHome}
            className="navbar-brand cursor-pointer"
          >
            <img src={brandLogo} alt="Logo" className="brand-logo" />
          </li>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
              {this.isLoggedIn() ? (
                <Fragment>
                  {isAdmin && (
                    <li className="nav-item pr-3">
                      <Link to="/plans" className="nav-link custom-nav-link">
                        Plans
                      </Link>
                    </li>
                  )}
                  {!isAdmin && (
                    <li className="nav-item pr-3">
                      <Link
                        to="/dashboard"
                        className="nav-link custom-nav-link"
                      >
                        Dashboard
                      </Link>
                    </li>
                  )}
                  <li className="nav-item pr-3">
                    <Link
                      to="/media-library"
                      className="nav-link custom-nav-link"
                    >
                      Media Library
                    </Link>
                  </li>
                  <li className="nav-item pr-3">
                    <Link to="/team-info" className="nav-link custom-nav-link">
                      Team Info
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbardrop"
                      data-toggle="dropdown"
                    >
                      <img
                        alt="profileLogo"
                        src={profileLogo}
                        className="profile-logo"
                      />
                    </a>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/my-profile">
                        My Account
                      </Link>
                      {isAdmin ? (
                        <Fragment>
                          <Link
                            className="dropdown-item"
                            to="/user-subscriptions"
                          >
                            User Subscriptions
                          </Link>
                          <Link className="dropdown-item" to="/pending-onboard">
                            Pending Onboard
                          </Link>
                        </Fragment>
                      ) : (
                        <Link className="dropdown-item" to="/subscriptions">
                          Subscription
                        </Link>
                      )}
                      <a
                        className="dropdown-item"
                        onClick={this.props.userLogout}
                      >
                        Log out
                      </a>
                    </div>
                  </li>
                </Fragment>
              ) : (
                <li className="nav-item active">
                  <Link to="/Login" className="nav-link custom-nav-link">
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  user_role: state.auth.user_role,
  isAdmin: state.auth.user_role.includes("SUPER_USER")
});

const mapDispatchToProps = {
  userLogout: userLogout
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
