/* eslint-disable no-useless-constructor */
import React from "react";
import { connect } from "react-redux";
import {
  getAllMediaLibrary,
  deleteMediaLibrary
} from "../../../redux/actions/mediaLibrary";
import notFound from "../../../assets/img/notFound.png";
import deleteIcon from "../../../assets/img/delete@2x.png";
import checkbox from "../../../assets/img/checkbox@2x.png";
import checked from "../../../assets/img/checkbox_checked@2x.png";
import "./mediaCard.scss";
import { confirmAlert } from "react-confirm-alert";
import ConfirmPopup from "../confirmPopup/ConfirmPopup";

class MediaCard extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDelete = () => {
    const { media, getList } = this.props;
    this.props.deleteMediaLibrary(media.id, getList);
  };

  handleDelete = () => {
    const onYes = () => {
      const { media, getList } = this.props;
      this.props.deleteMediaLibrary(media.id, getList);
    };
    const options = {
      title: "Are you sure?",
      message: "This action will delete this media!",
      buttons: [
        {
          label: "Yes, Delete it!",
          onClick: onYes
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmPopup onClose={onClose} {...options} />;
      }
    });
  };

  render() {
    const { media, type, isChecked, handleSelect } = this.props;
    return (
      <React.Fragment>
        <div className="m-card d-align-middle">
          <img
            src={media.image_url}
            alt="gallery"
            onError={e => {
              e.target.src = notFound;
            }}
          />
          <div className="m-hover">
            {type === "import" ? (
              <div className="m-checkbox">
                <img
                  alt="action_icon"
                  src={isChecked ? checked : checkbox}
                  className={isChecked ? "checked" : ""}
                  onClick={() => {
                    handleSelect(isChecked ? null : media);
                  }}
                />
              </div>
            ) : (
              <div className="m-actions">
                <div className="text-center">
                  <div className="m-action-label">Delete</div>
                  <img
                    alt="action_icon"
                    src={deleteIcon}
                    className="m-action-icon"
                    onClick={this.handleDelete}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {media.name && <div className="m-name ellipsis pt-1">{media.name}</div>}
      </React.Fragment>
    );
  }
}

const mapStateToprops = () => ({});

const mapDispatchToprops = {
  getAllMediaLibrary: getAllMediaLibrary,
  deleteMediaLibrary: deleteMediaLibrary
};

export default connect(
  mapStateToprops,
  mapDispatchToprops
)(MediaCard);
