import {
  fetchPlans,
  fetchUserSubscriptions,
  subscribeUserPlan,
  cancelUserSubscriptionPlan,
  fetchPaymentToggle,
  fetchPlanById,
  updateSubscriptionPlan,
  fetchRefreshPlans
} from "../services/subscriptions_axios";

export const subscriptionConstants = {
  GET_SUBSCRIPTION_PLANS: "GET_SUBSCRIPTION_PLANS",
  GET_SUBSCRIPTION_PLANS_SUCCESS: "GET_SUBSCRIPTION_PLANS_SUCCESS",
  GET_SUBSCRIPTION_PLANS_FAIL: "GET_SUBSCRIPTION_PLANS_FAIL",

  GET_REFRESH_PLANS: "GET_REFRESH_PLANS",
  GET_REFRESH_PLANS_SUCCESS: "GET_REFRESH_PLANS_SUCCESS",
  GET_REFRESH_PLANS_FAIL: "GET_REFRESH_PLANS_FAIL",

  GET_SUBSCRIPTION_PLAN_BY_ID: "GET_SUBSCRIPTION_PLAN_BY_ID",
  GET_SUBSCRIPTION_PLAN_BY_ID_SUCCESS: "GET_SUBSCRIPTION_PLAN_BY_ID_SUCCESS",
  GET_SUBSCRIPTION_PLAN_BY_ID_FAIL: "GET_SUBSCRIPTION_PLAN_BY_ID_FAIL",

  UPDATE_SUBSCRIPTION_PLAN: "UPDATE_SUBSCRIPTION_PLAN",
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS: "UPDATE_SUBSCRIPTION_PLAN_SUCCESS",
  UPDATE_SUBSCRIPTION_PLAN_FAIL: "UPDATE_SUBSCRIPTION_PLAN_FAIL",

  GET_USER_SUBSCRIPTIONS: "GET_USER_SUBSCRIPTIONS",
  GET_USER_SUBSCRIPTIONS_SUCCESS: "GET_USER_SUBSCRIPTIONS_SUCCESS",
  GET_USER_SUBSCRIPTIONS_FAIL: "GET_USER_SUBSCRIPTIONS_FAIL",

  GET_PAYMENT_TOGGLE: "GET_PAYMENT_TOGGLE",
  GET_PAYMENT_TOGGLE_SUCCESS: "GET_PAYMENT_TOGGLE_SUCCESS",
  GET_PAYMENT_TOGGLE_FAIL: "GET_PAYMENT_TOGGLE_FAIL",

  SUBSCRIBE_PLAN: "SUBSCRIBE_PLAN",
  SUBSCRIBE_PLAN_SUCCESS: "SUBSCRIBE_PLAN_SUCCESS",
  SUBSCRIBE_PLAN_FAIL: "SUBSCRIBE_PLAN_FAIL",

  CANCEL_SUBSCRIPTION_PLAN: "CANCEL_SUBSCRIPTION_PLAN",
  CANCEL_SUBSCRIPTION_PLAN_SUCCESS: "CANCEL_SUBSCRIPTION_PLAN_SUCCESS",
  CANCEL_SUBSCRIPTION_PLAN_FAIL: "CANCEL_SUBSCRIPTION_PLAN_FAIL"
};

export const getSubscriptionPlans = ({ cb }) => {
  return {
    types: [
      subscriptionConstants.GET_SUBSCRIPTION_PLANS,
      subscriptionConstants.GET_SUBSCRIPTION_PLANS_SUCCESS,
      subscriptionConstants.GET_SUBSCRIPTION_PLANS_FAIL
    ],
    service: fetchPlans(),
    onSuccess: cb
  };
};

export const getRefreshPlans = ({ cb }) => {
  return {
    types: [
      subscriptionConstants.GET_REFRESH_PLANS,
      subscriptionConstants.GET_REFRESH_PLANS_SUCCESS,
      subscriptionConstants.GET_REFRESH_PLANS_FAIL
    ],
    service: fetchRefreshPlans(),
    onSuccess: cb
  };
};

export const getSubscriptionPlanById = ({ id, cb }) => {
  return {
    types: [
      subscriptionConstants.GET_SUBSCRIPTION_PLAN_BY_ID,
      subscriptionConstants.GET_SUBSCRIPTION_PLAN_BY_ID_SUCCESS,
      subscriptionConstants.GET_SUBSCRIPTION_PLAN_BY_ID_FAIL
    ],
    service: fetchPlanById(id),
    onSuccess: cb
  };
};

export const updateSubscription = (data, cb) => {
  return {
    types: [
      subscriptionConstants.UPDATE_SUBSCRIPTION_PLAN,
      subscriptionConstants.UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
      subscriptionConstants.UPDATE_SUBSCRIPTION_PLAN_FAIL
    ],
    service: updateSubscriptionPlan(data),
    onSuccess: cb
  };
};

export const getPaymentToggle = (data, cb) => {
  return {
    types: [
      subscriptionConstants.GET_PAYMENT_TOGGLE,
      subscriptionConstants.GET_PAYMENT_TOGGLE_SUCCESS,
      subscriptionConstants.GET_PAYMENT_TOGGLE_FAIL
    ],
    service: fetchPaymentToggle(data),
    onSuccess: cb
  };
};

export const getUserSubscriptions = () => {
  return {
    types: [
      subscriptionConstants.GET_USER_SUBSCRIPTIONS,
      subscriptionConstants.GET_USER_SUBSCRIPTIONS_SUCCESS,
      subscriptionConstants.GET_USER_SUBSCRIPTIONS_FAIL
    ],
    service: fetchUserSubscriptions()
  };
};

export const subscribePlan = ({ data, cb }) => {
  return {
    types: [
      subscriptionConstants.SUBSCRIBE_PLAN,
      subscriptionConstants.SUBSCRIBE_PLAN_SUCCESS,
      subscriptionConstants.SUBSCRIBE_PLAN_FAIL
    ],
    service: subscribeUserPlan(data),
    onSuccess: cb
  };
};

export const cancelSubscriptionPlan = ({ user_id, data, cb }) => {
  return {
    types: [
      subscriptionConstants.CANCEL_SUBSCRIPTION_PLAN,
      subscriptionConstants.CANCEL_SUBSCRIPTION_PLAN_SUCCESS,
      subscriptionConstants.CANCEL_SUBSCRIPTION_PLAN_FAIL
    ],
    service: cancelUserSubscriptionPlan(user_id, data),
    onSuccess: cb
  };
};
