import React from "react";
import "./faq.scss";
import AppInput from "../../components/input/AppInput";

class FAQ extends React.Component {
  constructor() {
    super();
    this.state = {
      activeCards: new Set(),
      searchValue: "",
      faqs: [
        {
          question: "What if the user selects a plan and pays online?",
          answer:
            "Redirected to Zoho payment URL, and onboarded with trail activated."
        },
        {
          question: "What if the user selects a plan and pays offline?",
          answer:
            "Trail activated immediately and plan activation is in pending status for admin onboard. Once Admin onboarded, the plan will be active."
        },
        {
          question:
            "What if user toggle from offline to online mode? (current activated plan)",
          answer:
            "Credit card information is been requested by the user to toggle online mode for the first time."
        },
        {
          question:
            "What if user toggle from online to offline mode? (current activated plan)",
          answer:
            "Toggles to offline mode and auto-debit to user's credit card will be stopped from the upcoming billing cycle."
        },
        {
          question: "What if user/admin cancels user’s plan?",
          answer: "Plan will be deactivated immediately."
        },
        {
          question:
            "What if the user reactivates the canceled plan and pays offline?",
          answer:
            "Subscription will be initiated to pending status for admin onboard. Once admin onboarded, the plan will be reactivated without the trial period."
        },
        {
          question:
            "Is there any refund option available before user card charge during the free trial?",
          answer:
            "User will not be charged during their trial period. And no refund is applicable."
        },
        {
          question:
            "When will the user card charge if a user activates plan during his/her free-trial?",
          answer:
            "After the free trial expires, the user’s card will be charged with the plan amount."
        }
      ]
    };
  }

  setActiveCard = eventKey => {
    const newSet = new Set(this.state.activeCards);
    if (newSet.has(eventKey)) {
      newSet.delete(eventKey);
    } else {
      newSet.add(eventKey);
    }
    this.setState({
      activeCards: newSet
    });
  };

  Card = ({ title, eventKey, children }) => {
    const { activeCards } = this.state;
    return (
      <div className="card">
        <div
          className={`card-header ${
            activeCards.has(eventKey) ? "" : "collapsed"
          }`}
          onClick={() => {
            this.setActiveCard(eventKey);
          }}
        >
          {" "}
          <span>Q</span> &nbsp;&nbsp;
          {title}
        </div>
        <div
          id={eventKey}
          className={`collapse ${activeCards.has(eventKey) ? "show" : ""}`}
        >
          <div className="card-body pl-5">{children}</div>
        </div>
      </div>
    );
  };

  getQuesAndAnsDiv = () => {
    const { searchValue, faqs } = this.state;
    const filteredFaq = [];
    faqs.filter((faq, index) => {
      if (
        !searchValue ||
        faq.question.toLowerCase().match(searchValue.toLowerCase()) ||
        faq.answer.toLowerCase().match(searchValue.toLowerCase())
      ) {
        filteredFaq.push(
          <this.Card
            title={faq.question}
            eventKey={index}
            children={faq.answer}
          />
        );
      }
    });
    return filteredFaq;
  };

  handleChange = (e, key) => {
    const value = e.target.value;
    const newActiveCards = new Set();
    this.state.faqs.forEach((faq, index) => {
      if (value && faq.answer.toLowerCase().match(value.toLowerCase()))
        newActiveCards.add(index);
    });
    this.setState({
      [key]: value,
      activeCards: newActiveCards
    });
  };

  getContactDiv = () => {
    return (
      <>
        <div className="no-result pt-3">
          No queries for your searched keyword
        </div>
        <div className="contact pt-1">
          Please contact &nbsp;
          <a
            href="mailto:sales@goeditgraphics.com"
            target="_blank"
            rel="noreferrer"
            className="mail-to"
          >
            sales@goeditgraphics.com
          </a>
          &nbsp; for more help!
        </div>
      </>
    );
  };

  render() {
    const { searchValue } = this.state;
    const quesAnsDiv = this.getQuesAndAnsDiv();
    return (
      <>
        <div className="faq_header pl-1 pb-4">Frequently Asked Questions</div>
        <div className="search pl-5 pr-5 pb-3 mb-4">
          <AppInput
            type="text"
            name="searchValue"
            label="Type keyword to find answers"
            value={searchValue}
            onValueChange={this.handleChange}
          />
        </div>
        <div className="mx-5 px-5">
          {quesAnsDiv.length ? quesAnsDiv : this.getContactDiv()}
        </div>
      </>
    );
  }
}

export default FAQ;
