/* eslint-disable no-unused-vars */
import React from "react";
import AppInput from "../../components/input/AppInput";
import loginlogo from "../../../assets/img/GoEdit-Logo.png";
import facebookIcon from "../../../assets/img/facebook@2x.png";
import instagramIcon from "../../../assets/img/instagram@2x.png";
import twitterIcon from "../../../assets/img/twitter@2x.png";
import youtubeIcon from "../../../assets/img/youtube@2x.png";
import "./ForgotPassword.scss";
import { Link } from "react-router-dom";
import { userForgotPassword, userLogin } from "../../../redux/actions/auth";
import { connect } from "react-redux";
import { required, isValidEmail, isEmpty } from "../../../utils/validations";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.username || "",
      errors: {}
    };
    this.validations = {
      email: [required, isValidEmail]
    };
  }

  handleChange = (e, key) => {
    const value = e.target.value;
    this.setState({
      [key]: value
    });

    const errors = { ...this.state.errors };

    const list = this.validations[key];
    if (!isEmpty(list)) {
      const length = list.length;
      for (let i = 0; i < length; i++) {
        const message = list[i](value);
        if (message) {
          errors[key] = message;
          break;
        }
        errors[key] = null;
      }
      this.setState({
        errors
      });
    }
  };

  handleCheck = event => {
    const { checked } = event.target;
    this.setState({
      rememberMe: checked
    });
  };

  handleValidation = () => {
    let isValid = true;
    const errors = { ...this.state.errors };
    const keys = Object.keys(this.validations);
    keys.forEach(key => {
      const list = this.validations[key];
      if (!isEmpty(list)) {
        const length = list.length;
        for (let i = 0; i < length; i++) {
          const message = list[i](this.state[key]);
          if (message) {
            errors[key] = message;
            isValid = false;
            break;
          }
          errors[key] = null;
        }
      }
    });
    this.setState({
      errors
    });
    return isValid;
  };

  handleSubmit = event => {
    event.preventDefault();
    const { email } = this.state;

    let isValid = this.handleValidation();
    if (isValid) {
      const data = { email };
      this.props.userForgotPassword(data, () => {
        this.props.history.push("/login");
      });
    }
  };

  render() {
    const { email, errors } = this.state;
    return (
      <div className="row m-0 login_page">
        <div className="login_bg_head">
          <img src={loginlogo} alt="login-bg" className="login_bg_head_image" />
        </div>
        <div className="flex-grow-1 flex-basis-0 d-flex align-items-center flex-column justify-content-between">
          <section className="pt-5 col-xl-5 col-lg-7 col-md-9 col-sm-12">
            <div className="main_header pb-2">Forgot password</div>
            <b className="d-inline-block" style={{ marginRight: "4px" }}>
              Don't worry. Just relax.
            </b>
            <div className="mb-3 d-inline sub_header">
              We are here to help you to recovery your password. Enter the email
              address you used when you joined. We send your reset password.
            </div>
            <form className="pt-3 pb-4" onSubmit={this.handleSubmit}>
              <div className="pb-3">
                <AppInput
                  type="text"
                  name="email"
                  label="Email Address"
                  required={true}
                  value={email}
                  onValueChange={this.handleChange}
                  errors={errors}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Link to="/login" className="col-sm-auto">
                  <div>
                    <i class="fa fa-angle-left mr-1" aria-hidden="true" />
                    Back
                  </div>
                </Link>
                <button
                  className="btn btn-orange col-sm-auto w-50"
                  onClick={this.handleSubmit}
                >
                  Send
                </button>
              </div>
            </form>
          </section>
          <section className="contact_ways py-3">
            <div className="contact pb-3">
              Contact &nbsp;
              <a
                href="mailto:sales@goeditgraphics.com"
                rel="noopener noreferrer"
                target="_blank"
                className="mail-to"
              >
                sales@goeditgraphics.com
              </a>
            </div>
            <div className="text-center">
              <a
                href="https://www.facebook.com/GoEditGraphics"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={facebookIcon} alt="icon" className="via_icon" />
              </a>
              <a
                href="https://www.instagram.com/goeditgraphics/"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={instagramIcon} alt="icon" className="via_icon" />
              </a>
              <a
                href="https://twitter.com/GoEditGraphics"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={twitterIcon} alt="icon" className="via_icon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCuGUuUXlX7SiYhCYXjcPM5A"
                rel="noopener noreferrer"
                target="_blank"
                className="px-1"
              >
                <img src={youtubeIcon} alt="icon" className="via_icon" />
              </a>
            </div>
          </section>
        </div>
        <div className="ml-0 login_bg_side">
          <Link to={{ pathname: "https://goeditgraphics.com" }} target="_blank">
            <img
              src={loginlogo}
              alt="login-bg"
              className="login_bg_side_image"
            />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  userForgotPassword: userForgotPassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
