import base from "./base.service";

export const signIn = data => {
  return () =>
    base.post({
      url: "/Users/signin",
      body: data
    });
};

export const signUp = data => {
  return () =>
    base.post({
      url: "/Users/register",
      body: data
    });
};

export const getLoggedInUser = () => {
  return () =>
    base.get({
      url: "/Users/me"
    });
};

export const logout = () => {
  return () =>
    base.post({
      url: "/Users/logout"
    });
};

export const forgotPassword = data => {
  return () =>
    base.post({
      url: "/Users/reset/password",
      body: data
    });
};

export const updateUserProfileSettings = data => {
  return () =>
    base.put({
      url: "/Users/profile/update",
      body: data
    });
};

export const passwordChange = data => {
  return () =>
    base.post({
      url: "/Users/change-password",
      body: data
    });
};

export const getCategoryList = () => {
  return () =>
    base.get({
      url: "/sports_types"
    });
};

export const getSubscribedCategoryList = () => {
  return () =>
    base.get({
      url: "/sports_types/user"
    });
};

export const fetchUsersList = () => {
  return () =>
    base.get({
      url: "/users/all/info"
    });
};

export const fetchOnBoardUserList = () => {
  return () =>
    base.get({
      url: "/user_subscriptions/activation/pending"
    });
};

export const OnBoardUserSubscription = id => {
  return () =>
    base.put({
      url: `/user_subscriptions/activate/${id}`
    });
};

export const addTeamInfo = data => {
  return () =>
    base.post({
      url: "/teams/create/team",
      body: data
    });
};

export const updateTeamInfo = data => {
  return () =>
    base.put({
      url: `/teams/update/${data.id}`,
      body: data
    });
};

export const instaSocialProfile = () => {
  return () =>
    base.get({
      url: "socials/my_social_profiles"
    });
};

export const instaSuccessCB = code => {
  return () =>
    base.get({
      url: `socials/success?code=${code}`
    });
};

export const instaSocialShare = data => {
  return () =>
    base.post({
      url: "socials/insta/publish",
      body: data
    });
};

export const removeInstaAccount = data => {
  return () =>
    base.delete({
      url: "/socials/remove",
      body: data
    });
};
