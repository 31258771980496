/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { getAllUserSubscriptions } from "../../../redux/actions/auth";

class UserSubscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: []
    };

    this.filterParams = {
      buttons: ["reset"],
      debounceMs: 500,
      suppressAndOrCondition: true
    };

    this.columnDefs = [
      {
        headerName: "Name",
        field: "name"
      },
      {
        headerName: "Email",
        field: "email"
      },
      {
        headerName: "Plan name",
        field: "plan_name"
      },
      {
        headerName: "Subscribed at",
        field: "subscribed_at",
        valueFormatter: this.dateFormatter,
        filter: "agDateColumnFilter",
        filterParams: {
          ...this.filterParams,
          comparator: this.dateComparator
        }
      },
      {
        headerName: "Renewal on",
        field: "renewal_on",
        valueFormatter: this.dateFormatter,
        filter: "agDateColumnFilter",
        filterParams: {
          ...this.filterParams,
          comparator: this.dateComparator
        }
      },
      {
        headerName: "Templates count",
        field: "my_templates",
        filter: "agNumberColumnFilter"
      }
    ];

    this.defaultColumnDef = {
      sortable: true,
      resizable: true,
      suppressMenu: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      filterParams: { ...this.filterParams }
    };
  }

  componentDidMount() {
    this.props.getAllUserSubscriptions({
      cb: data => {
        this.setState({
          rowData: [...data]
        });
      }
    });
  }

  dateFormatter = params => {
    const { value } = params;
    const date = moment(value).format("DD MMM YYYY");
    return date;
  };

  dateComparator = (filterLocalDateAtMidnight, cellValue) => {
    var cellDate = new Date(cellValue);
    cellDate.setHours(0, 0, 0, 0);
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  };

  render() {
    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: "calc(100vh - 75px)"
        }}
      >
        <AgGridReact
          columnDefs={this.columnDefs}
          defaultColDef={this.defaultColumnDef}
          rowData={this.state.rowData}
          suppressCellSelection={true}
          onGridReady={params => {
            this.gridApi = params.api;
            this.gridApi.sizeColumnsToFit();
          }}
          animateRows={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.auth.users
});

const mapDispatchToProps = {
  getAllUserSubscriptions: getAllUserSubscriptions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSubscriptions);
