/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import Dropdown from "react-dropdown";
import "./userProfile.scss";
import editIcon from "../../../assets/img/edit_black@2x.png";
import defaultUserIcon from "../../../assets/img/default-avatar.png";
import AppInput from "../../components/input/AppInput";
import Crop from "../../components/crop/Crop";
import { convertDataURIToBlob } from "../../../utils/utils";
import { updateUserProfile, changePassword } from "../../../redux/actions/auth";
import { showLoader, hideLoader } from "../../../redux/reducers/loader";
import {
  required,
  minLength,
  isNumber,
  isMobile,
  isValidEmail,
  isEmpty,
  maxLength
} from "../../../utils/validations";
import appConstants from "../../app.properties";
import SocialConnect from "../socialConnect/socialConnect";
import { withRouter } from "react-router-dom";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isOpen: false,
      base64: "",

      member_id: "",

      firstName: "",
      lastName: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: "",
      imageUrl: "",

      oldPassword: "",
      newPassword: "",

      errors: {}
    };
    this.profileValidations = {
      firstName: [required, minLength(3)],
      lastName: [required, minLength(1)],
      zip: [isNumber],
      phone: [required, isMobile],
      email: [required, isValidEmail]
    };

    this.changePasswordValidations = {
      oldPassword: [required, minLength(8), maxLength(15)],
      newPassword: [required, minLength(8), maxLength(15)]
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.setState({
        firstName: user.firstName,
        lastName: user.lastName,
        address_line_1: user.address_line_1 || null,
        address_line_2: user.address_line_2 || null,
        city: user.city,
        state: user.state,
        zip: user.zip,
        phone: user.phone,
        email: user.email,
        imageUrl: user.picturelarge
          ? `${user.picturelarge}?${Math.random()}`
          : ""
      });
    }
  }

  handleUpload = async e => {
    const file = e.target.files[0];
    const { size, type } = file;
    console.log(type, "File Size in kb>>>>>>>>>", size / 1000, "kb");
    const reader = new FileReader();
    reader.onload = e => {
      const base64 = e.target.result;
      this.setState({ base64: base64 });
      this.toggleModal();
    };
    reader.readAsDataURL(file);
    /*
    if (type === "image/svg+xml") {
      const reader = new FileReader();
      reader.onload = e => {
        const base64 = e.target.result;
        this.setState({ base64: base64 });
        this.toggleModal();
      };
      reader.readAsDataURL(file);
    } else {
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 500,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(file, options);
      console.log(`compressedFile size ${compressedFile.size / 1000} kB`);
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({
          file: file,
          base64: e.target.result
        });
        this.toggleModal();
      };
      reader.readAsDataURL(compressedFile);
    }
    */
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSuccessCrop = img => {
    this.toggleModal();
    this.uploadProfileImage(img);
  };

  uploadProfileImage = async img => {
    // const blob = convertDataURIToBlob(img);
    // const formData = new FormData();
    // formData.append("upload", blob);
    /**
     * show/hide loader,while upload profile pic
     */
    /*
    this.props.showLoader();
    const res = await uploadProfilePic({
      formData,
      member_id: this.props.member.member_id
    });

    this.setState(
      {
        imageUrl: `${process.env.REACT_APP_IMAGE_URl}/${
          res.file
        }?${Math.random()}`
      },
      () => {
        this.saveProfile();
        this.props.hideLoader();
      }
     );
     */
  };

  /*
  updateWPProfile = data => {
    const { REACT_APP_WORDPRESS_URL } = process.env;
    const url = new URL(
      `${REACT_APP_WORDPRESS_URL}/wp-content/themes/foton-child/update_user_meta.php`
    );
    url.search = new URLSearchParams(data).toString();
    fetch(url, { credentials: "include" });
  };*/

  toggleEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit
    });
  };

  handleChange = (e, key, validations) => {
    const value = e.target.value;
    this.setState({
      [key]: value
    });

    const errors = { ...this.state.errors };

    const list = validations[key];
    if (!isEmpty(list)) {
      const length = list.length;
      for (let i = 0; i < length; i++) {
        const message = list[i](value);
        if (message) {
          errors[key] = message;
          break;
        }
        errors[key] = null;
      }
      this.setState({
        errors
      });
    }
  };

  handleValidation = validations => {
    let isValid = true;
    const errors = { ...this.state.errors };
    const keys = Object.keys(validations);
    keys.forEach(key => {
      const list = validations[key];
      if (!isEmpty(list)) {
        const length = list.length;
        for (let i = 0; i < length; i++) {
          const message = list[i](this.state[key]);
          if (message) {
            errors[key] = message;
            isValid = false;
            break;
          }
          errors[key] = null;
        }
      }
    });
    this.setState({
      errors
    });
    return isValid;
  };

  handleSubmit = event => {
    event.preventDefault();
    let isValid = this.handleValidation(this.profileValidations);
    if (isValid) {
      const {
        firstName,
        lastName,
        address_line_1,
        address_line_2,
        city,
        state,
        zip,
        phone,
        email,
        imageUrl
      } = this.state;
      const data = {
        firstName: firstName,
        lastName: lastName,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        email: email,
        picturelarge: imageUrl ? imageUrl.split("?")[0] : null,
        picturemedium: imageUrl ? imageUrl.split("?")[0] : null,
        picturethumbnail: imageUrl ? imageUrl.split("?")[0] : null
      };
      this.props.updateUserProfile(data);
    }
  };

  changePassword = event => {
    event.preventDefault();
    let isValid = this.handleValidation(this.changePasswordValidations);
    if (isValid) {
      const { oldPassword, newPassword } = this.state;
      const data = {
        oldPassword: oldPassword,
        newPassword: newPassword
      };
      this.props.changePassword(data);
    }
  };

  render() {
    const {
      isEdit,
      base64,
      firstName,
      lastName,
      address_line_1,
      address_line_2,
      city,
      state,
      zip,
      phone,
      email,
      isOpen,
      imageUrl,
      oldPassword,
      newPassword,
      errors
    } = this.state;

    let profile_field =
      "col-xl-6 col-lg-6 col-md-12 col-sm-12 pl-1 pr-1 pb-3 profile_field";
    let profile_field_sm =
      "col-xl-6 col-lg-6 col-md-6 col-sm-12 pl-1 pr-1 pb-3 profile_field";

    return (
      <div className="user_profile-container">
        <h4>My Account</h4>
        <div className="row m-0 user-form">
          <div className="col-sm-12 profile_pic-container profile_pic-top">
            <div className="position-relative profile_pic">
              <img
                alt="user"
                src={imageUrl}
                onError={e => {
                  e.target.src = defaultUserIcon;
                }}
                className="user-img"
              />
              <input
                type="file"
                className="file-upload"
                onChange={this.handleUpload}
                title=""
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-9 px-0">
            <div className="d-flex justify-content-between pt-4">
              <div className="sub-heading">Account Info</div>
              <span href="" className="edit">
                <span className="pr-3" onClick={this.toggleEdit}>
                  Edit
                </span>
                <img alt="edit_icon" src={editIcon} className="editIcon" />
              </span>
            </div>
            <form className="pt-4">
              <div className="row m-0 justify-content-between pt-2">
                <div className={profile_field_sm}>
                  <AppInput
                    type="text"
                    name="firstName"
                    label="First Name"
                    value={firstName}
                    disabled={!isEdit}
                    onValueChange={(e, key) => {
                      this.handleChange(e, key, this.profileValidations);
                    }}
                    errors={errors}
                  />
                </div>
                <div className={profile_field_sm}>
                  <AppInput
                    type="text"
                    name="lastName"
                    label="Last Name"
                    value={lastName}
                    disabled={!isEdit}
                    onValueChange={(e, key) => {
                      this.handleChange(e, key, this.profileValidations);
                    }}
                    errors={errors}
                  />
                </div>
                <div className={profile_field}>
                  <AppInput
                    type="text"
                    name="address_line_1"
                    label="Address line 1"
                    value={address_line_1}
                    disabled={!isEdit}
                    onValueChange={(e, key) => {
                      this.handleChange(e, key, this.profileValidations);
                    }}
                    errors={errors}
                  />
                </div>
                <div className={profile_field}>
                  <AppInput
                    type="text"
                    name="address_line_2"
                    label="Address line 2"
                    value={address_line_2}
                    disabled={!isEdit}
                    onValueChange={(e, key) => {
                      this.handleChange(e, key, this.profileValidations);
                    }}
                    errors={errors}
                  />
                </div>
                <div className={profile_field}>
                  <AppInput
                    type="text"
                    name="city"
                    label="City"
                    value={city}
                    disabled={!isEdit}
                    onValueChange={(e, key) => {
                      this.handleChange(e, key, this.profileValidations);
                    }}
                    errors={errors}
                  />
                </div>
                <div className={profile_field}>
                  <Dropdown
                    placeholder="Select state"
                    disabled={!isEdit}
                    options={appConstants.stateList}
                    value={state}
                    onChange={option => {
                      console.log(option);
                      const event = { target: { value: option.value } };
                      this.handleChange(
                        event,
                        "state",
                        this.profileValidations
                      );
                    }}
                    placeholderClassName="placeholder"
                  />
                  {errors && errors["state"] && (
                    <div className="error-message invalid pt-1">
                      {errors["state"]}
                    </div>
                  )}
                </div>
                <div className={profile_field}>
                  <AppInput
                    type="number"
                    name="zip"
                    label="Zip"
                    value={zip}
                    disabled={!isEdit}
                    onValueChange={(e, key) => {
                      this.handleChange(e, key, this.profileValidations);
                    }}
                    errors={errors}
                  />
                </div>
                <div className={profile_field}>
                  <AppInput
                    type="text"
                    name="phone"
                    label="Phone"
                    value={phone}
                    disabled={!isEdit}
                    onValueChange={(e, key) => {
                      this.handleChange(e, key, this.profileValidations);
                    }}
                    errors={errors}
                  />
                </div>
                <div className={profile_field}>
                  <AppInput
                    type="text"
                    name="email"
                    label="Email ID"
                    value={email}
                    disabled={!isEdit}
                    onValueChange={(e, key) => {
                      this.handleChange(e, key, this.profileValidations);
                    }}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="py-5">
                <button className="btn cancel-btn mr-4 mb-3 col-sm-auto">
                  Cancel
                </button>
                <button
                  className="btn save-btn mb-3 col-sm-auto"
                  onClick={this.handleSubmit}
                >
                  SAVE
                </button>
              </div>
            </form>
          </div>
          <div className="col-3 profile_pic-container profile_pic-side">
            <div className="position-relative profile_pic">
              <img
                alt="user"
                src={imageUrl}
                onError={e => {
                  e.target.src = defaultUserIcon;
                }}
                className="user-img"
              />
              <input
                type="file"
                className="file-upload"
                onChange={this.handleUpload}
                title=""
              />
            </div>
          </div>
        </div>
        <div className="connected-social-container pt-5 pb-5">
          <h4 className="profile-user-social-accounts">
            Connected Social Accounts
          </h4>
          <SocialConnect />
        </div>
        <div className="pt-5 pb-4">
          <div className="sub-heading">Change Password</div>
          <form className="pt-4" onSubmit={this.changePassword}>
            <div className="pb-3 password_field">
              <AppInput
                type="password"
                name="oldPassword"
                label="Existing password"
                required={true}
                value={oldPassword}
                onValueChange={(e, key) => {
                  this.handleChange(e, key, this.changePasswordValidations);
                }}
                errors={errors}
              />
            </div>
            <div className="pb-3 password_field">
              <AppInput
                type="password"
                name="newPassword"
                label="New password"
                required={true}
                value={newPassword}
                onValueChange={(e, key) => {
                  this.handleChange(e, key, this.changePasswordValidations);
                }}
                errors={errors}
              />
            </div>
            <div className="pt-2">
              <button
                className="btn btn-orange col-sm-auto"
                onClick={this.changePassword}
              >
                Change
              </button>
            </div>
          </form>
        </div>
        {isOpen && (
          <div
            className={`modal ${isOpen ? "d-block" : ""}`}
            tabIndex="-1"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centescsred"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header custom-header">
                  Drag the image to adjust
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.toggleModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Crop imageUrl={base64} onSuccessCrop={this.onSuccessCrop} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  updateUserProfile: updateUserProfile,
  changePassword: changePassword,
  showLoader: showLoader,
  hideLoader: hideLoader
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProfile));
